import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../firebase/config";
import {
  Dialog,
  DialogContent,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ClosableDialogTitle from "../generic/ClosableDialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { handleFirebaseUser } from "../../redux/firebaseUtils";

const SignInDialog = ({ open, onClose }) => {
  const { t } = useTranslation("auth");
  const dispatch = useDispatch();

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        handleFirebaseUser(result.user, dispatch);
        onClose(); // Close dialog after successful sign-in
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <ClosableDialogTitle onClose={onClose}>{t("signin")}</ClosableDialogTitle>
      <DialogContent dividers>
        <List component="nav">
          <ListItemButton onClick={signInWithGoogle}>
            <ListItemIcon>
              <FontAwesomeIcon icon={["fab", "google"]} />
            </ListItemIcon>
            <ListItemText primary={t("with_google")} />
          </ListItemButton>
        </List>
      </DialogContent>
    </Dialog>
  );
};

SignInDialog.propTypes = {
  /** Controls whether the dialog is open or not */
  open: PropTypes.bool.isRequired,
  /** Callback function triggered when the dialog is closed */
  onClose: PropTypes.func.isRequired,
};

export default SignInDialog;
