import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import SignInDialog from "./panels/SignInDialog";
import StartupCard from "./panels/StartupCard";

import { Trans, useTranslation } from "react-i18next";
import MBGLogo from "./misc/MBGLogo";
import { Parallax } from "react-parallax";
import Page from "./Page";
import PageContainer from "./PageContainer";
import DynamicBackground from "./generic/DynamicBackground";
import Neon from "./generic/Neon";
import WebM from "./generic/WebM";

const HomePage = (props) => {
  const { t } = useTranslation(["page_home", "keywords"]);

  return (
    <>
      <Page>
        <Parallax
          blur={7}
          bgImage="/static/images/hero_background.jpg"
          strength={300}
          style={{
            width: "100%",
          }}
        >
          <Container
            sx={{
              height: "50vh",
            }}
          >
            <Stack
              sx={{
                height: "100%",
              }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <MBGLogo height="70%" />
            </Stack>
          </Container>
        </Parallax>

        <DynamicBackground>
          <PageContainer>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                sx={{
                  textAlign: "center",
                }}
              >
                <WebM name="idle" width={116} height={200} />
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  <Trans
                    i18nKey="description"
                    ns="page_home"
                    values={{ game: "<b>Machine Building Game ©</b>" }}
                    components={{ b: <strong /> }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </PageContainer>

          <PageContainer>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <StartupCard
                title={t("title_1")}
                description={t("description_1")}
                image="/static/images/conveyor.png"
                cover={false}
                animateImage={true}
              />
              <StartupCard
                title={t("title_2")}
                description={t("description_2")}
                image="/static/images/rover.png"
                cover={false}
                animateImage={true}
              />
              <StartupCard
                title={t("title_3")}
                description={t("description_3")}
                image="/static/images/engineers.png"
                cover={false}
                animateImage={true}
              />
            </Box>
          </PageContainer>

          <PageContainer>
            <Neon text={t("explore_title")} />
            <Typography>{t("explore_description")}</Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <StartupCard
                title={t("factory", { ns: "keywords" })}
                description={t("factory_description")}
                url="/factory"
                image="/static/images/factory.png"
              />
              <StartupCard
                title={t("menu_contribution") + " - machine"}
                description={t("contribution_description")}
                url="/machines"
                image="/static/images/contributions.png"
              />
              <StartupCard
                title={t("world", { ns: "keywords", count: 2 })}
                description={t("worlds_description")}
                url="/worlds"
                image="/static/images/worlds.png"
              />
            </Box>
          </PageContainer>
        </DynamicBackground>
      </Page>

      <SignInDialog />
    </>
  );
};

export default HomePage;
