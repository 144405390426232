import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
} from "@mui/material";
import { Link } from "react-router-dom";
import { t } from "i18next";

const StartupCard = ({
  title,
  description,
  image,
  url,
  cover = true,
  animateImage = false,
}) => {
  return (
    <Card
      sx={{
        m: 1,
        maxWidth: 345,
        overflow: "hidden",
        borderRadius: 2,
        transition: "all .2s ease-in-out",
        "&:hover": {
          transform: animateImage ? "scale(1.05)" : "none",
        },
      }}
    >
      <CardMedia
        sx={{
          height: 140,
          backgroundSize: cover ? "cover" : "contain",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
        image={image}
        title={title}
      />
      <CardHeader title={title} sx={{ paddingBottom: 1 }} />
      <CardContent>{description}</CardContent>
      {url && (
        <CardActions>
          <Button component={Link} to={url} variant="contained" color="primary">
            {t("load", { ns: "keywords" })}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

StartupCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
  cover: PropTypes.bool,
  animateImage: PropTypes.bool,
};

export default StartupCard;
