import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowLeft,
  faArrowRight,
  faArrowTurnDown,
  faArrowsAlt,
  faBars,
  faBug,
  faCheck,
  faCog,
  faCopy,
  faCut,
  faDice,
  faEllipsis,
  faExclamation,
  faExpand,
  faFile,
  faFolderOpen,
  faLanguage,
  faMicrochip,
  faPause,
  faPaste,
  faPersonWalking,
  faPlay,
  faPlus,
  faRedo,
  faRotate,
  faSave,
  faSpinner,
  faSync,
  faTimes,
  faTrash,
  faUndo,
  faVideo,
  faVolumeHigh,
  faVolumeXmark,
  faWindowMinimize,
  faWindowMaximize,
  faPenToSquare,
  faEye,
  faThumbsUp,
  faThumbsDown,
  faLock,
  faUnlock,
  faFileAlt,
  faInfo,
  faUpRightAndDownLeftFromCenter,
  faGlobe,
  faShareAlt,
  faLink,
} from '@fortawesome/free-solid-svg-icons';

import {
  faDiscord,
  faFacebook,
  faGoogle,
  faInstagram,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faClock, faGem } from '@fortawesome/free-regular-svg-icons';


// references:
// https://stackoverflow.com/questions/48002619/cound-not-find-icon-react-fontawesome
// https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react

const initializeIcons = () => {
  library.add(faArrowLeft);
  library.add(faArrowRight);
  library.add(faArrowTurnDown);
  library.add(faArrowsAlt);
  library.add(faBars);
  library.add(faBug);
  library.add(faCheck);
  library.add(faCog);
  library.add(faCopy);
  library.add(faCut);
  library.add(faDice);
  library.add(faEllipsis);
  library.add(faExclamation);
  library.add(faEye);
  library.add(faExpand);
  library.add(faFile);
  library.add(faFileAlt);
  library.add(faFolderOpen);
  library.add(faGlobe);
  library.add(faInfo);
  library.add(faLanguage);
  library.add(faLink);
  library.add(faLock);
  library.add(faMicrochip);
  library.add(faPaste);
  library.add(faPause);
  library.add(faPenToSquare);
  library.add(faPersonWalking);
  library.add(faPlay);
  library.add(faPlus);
  library.add(faRedo);
  library.add(faRotate);
  library.add(faSave);
  library.add(faShareAlt);
  library.add(faSpinner);
  library.add(faSync);
  library.add(faTimes);
  library.add(faTrash);
  library.add(faThumbsUp);
  library.add(faThumbsDown);
  library.add(faUpRightAndDownLeftFromCenter);
  library.add(faUndo);
  library.add(faUnlock);
  library.add(faVideo);
  library.add(faVolumeHigh);
  library.add(faVolumeXmark);
  library.add(faWindowMinimize);
  library.add(faWindowMaximize);
  
  library.add(faPaste);

  library.add(faClock);
  library.add(faGem);

  library.add(faDiscord);
  library.add(faFacebook);
  library.add(faGoogle);
  library.add(faTiktok);
  library.add(faInstagram);
  library.add(faYoutube);
  library.add(faXTwitter);
};

export default initializeIcons;
