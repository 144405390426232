import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  Card,
  CardHeader,
  CardContent,
  Alert,
  Typography,
  Skeleton,
  Stack,
  Button,
} from "@mui/material";
import { firestore } from "../../firebase/config";
import Avatar from "../../misc/Avatar";
import { useTranslation } from "react-i18next";
import Page from "../Page";
import PageContainer from "../PageContainer";

const User = () => {
  const { userSlug } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation("page_user_and_profile");

  const formattedDate = (timestamp) => {
    const date =
      timestamp && typeof timestamp.toDate === "function"
        ? timestamp.toDate()
        : new Date(timestamp);

    return date.toLocaleDateString(i18n.language, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    const fetchUserBySlug = async () => {
      setLoading(true);
      setError(null);

      try {
        const usersCollection = collection(firestore, "users");
        const userQuery = query(usersCollection, where("slug", "==", userSlug));
        const userSnapshot = await getDocs(userQuery);

        if (!userSnapshot.empty) {
          setUserData(userSnapshot.docs[0].data());
        } else {
          setError("User not found");
        }
      } catch (err) {
        console.error("Error fetching user:", err);
        setError(t("error_fetching_user"));
      } finally {
        setLoading(false);
      }
    };

    fetchUserBySlug();
  }, [userSlug, t]);

  return (
    <Page>
      <PageContainer>
        {error && <Alert severity="error">{error}</Alert>}

        {loading ? (
          <Card sx={{ maxWidth: 345, userSelect: "none" }}>
            <CardHeader
              avatar={<Skeleton variant="circular" width={40} height={40} />}
              title={<Skeleton variant="text" width={"60%"} />}
              subheader={<Skeleton variant="text" width={"40%"} />}
            />
            <CardContent>
              <Skeleton variant="rectangular" width={"100%"} height={20} />
            </CardContent>
          </Card>
        ) : (
          userData && (
            <Stack spacing={2}>
              <Card sx={{ maxWidth: 345, userSelect: "none" }}>
                <CardHeader
                  avatar={<Avatar avatarID={userData.avatarID} size={100} />}
                  title={userData.displayName || t("unknown_user")}
                  subheader={t("created_at", {
                    date: formattedDate(userData.createdAt),
                  })}
                />
                {userData.status && (
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {'"' + userData.status + '"'}
                    </Typography>
                  </CardContent>
                )}
                <CardContent>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/levels?user=${userSlug}`}
                    >
                      {t("show_levels")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/machines?user=${userSlug}`}
                    >
                      {t("show_machines")}
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          )
        )}
      </PageContainer>
    </Page>
  );
};

export default User;
