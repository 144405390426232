import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import Lottie from "react-lottie-player";
import logoAnimation from "../../animations/logo";

// Animation frame settings
const INTRO_END_FRAME = 34; // Last frame of the intro animation
const LOOP_START_FRAME = INTRO_END_FRAME;
const LOOP_END_FRAME = logoAnimation.op - 1; // Last frame of the full animation

// Scroll-to-speed ratio
const SCROLL_SENSITIVITY = 0.1; // Converts scroll delta to animation speed

// Inertia settings
const INERTIA_DECAY = 0.98; // Factor applied to reduce velocity over time
const MIN_SPEED_THRESHOLD = 0.001; // Stops animation when speed is below this value
const INITIAL_SPEED = 1; // Default speed for the intro animation
const INERTIA_THRESHOLD = 0.01; // Stops intro inertia when speed is too low

const MBGLogo = ({ height }) => {
  const [isIntroComplete, setIsIntroComplete] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(INITIAL_SPEED);
  const lastScrollY = useRef(0);
  const scrollVelocity = useRef(0);
  const rafId = useRef(null);

  useEffect(() => {
    const updateAnimationSpeed = () => {
      if (Math.abs(scrollVelocity.current) > MIN_SPEED_THRESHOLD) {
        setAnimationSpeed(Math.abs(scrollVelocity.current));
        scrollVelocity.current *= INERTIA_DECAY;
        rafId.current = requestAnimationFrame(updateAnimationSpeed);
      } else {
        setAnimationSpeed(0);
        cancelAnimationFrame(rafId.current);
        rafId.current = null;
      }
    };

    const handleScroll = () => {
      if (!isIntroComplete) return;

      const scrollDelta = window.scrollY - lastScrollY.current;
      lastScrollY.current = window.scrollY;
      scrollVelocity.current = scrollDelta * SCROLL_SENSITIVITY;

      if (!rafId.current) {
        rafId.current = requestAnimationFrame(updateAnimationSpeed);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(rafId.current);
    };
  }, [isIntroComplete]);

  const handleIntroComplete = () => {
    setIsIntroComplete(true);

    let speed = INITIAL_SPEED;
    const applyInertia = () => {
      if (speed > INERTIA_THRESHOLD) {
        speed *= INERTIA_DECAY;
        setAnimationSpeed(speed);
        requestAnimationFrame(applyInertia);
      } else {
        setAnimationSpeed(0);
      }
    };
    applyInertia();
  };

  const animationSegments = isIntroComplete
    ? [LOOP_START_FRAME, LOOP_END_FRAME]
    : [0, INTRO_END_FRAME];

  return (
    <Lottie
      loop={isIntroComplete}
      play={true}
      speed={animationSpeed}
      style={{
        height: height,
        filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
      }}
      animationData={logoAnimation}
      onComplete={handleIntroComplete}
      segments={animationSegments}
    />
  );
};

MBGLogo.propTypes = {
  height: PropTypes.string.isRequired,
};

export default MBGLogo;
