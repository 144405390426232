import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

// Config constants
const SCROLL_SENSITIVITY = 0.1; // How much the gradient shifts on scroll
const PARTICLE_SCROLL_SPEED = 0.2; // Particle movement sensitivity to scroll
const RANDOM_TRAJECTORY_FACTOR = 0.3; // Adds slight randomness to movement
const INERTIA_DECAY = 0.92; // How fast particles slow down after scrolling stops
const MIN_SPEED_THRESHOLD = 0.002; // Stop threshold
const PARTICLE_COUNT_RANGE = [3, 6]; // Min/Max number of particles
const PARTICLE_SIZE_RANGE = [200, 500]; // Min/Max size of particles
const PARTICLE_BLUR_RANGE = [20, 60]; // Min/Max blur effect
const LERP_FACTOR = 0.1; // Controls smooth movement

const DynamicBackground = ({ children, particleColor = "#58ff8b" }) => {
  const [gradientOffset, setGradientOffset] = useState(50);
  const lastScrollY = useRef(0);
  const scrollVelocity = useRef(0);
  const rafId = useRef(null);
  const [particles, setParticles] = useState([]);

  // Generate particles with random but controlled movement
  useEffect(() => {
    const numParticles = Math.floor(
      Math.random() * (PARTICLE_COUNT_RANGE[1] - PARTICLE_COUNT_RANGE[0] + 1) +
        PARTICLE_COUNT_RANGE[0]
    );

    const newParticles = Array.from({ length: numParticles }, () => ({
      id: Math.random(),
      x: Math.random() * 80 + 10, // Between 10% and 90%
      y: Math.random() * 80 + 10,
      size:
        Math.random() * (PARTICLE_SIZE_RANGE[1] - PARTICLE_SIZE_RANGE[0]) +
        PARTICLE_SIZE_RANGE[0],
      blur:
        Math.random() * (PARTICLE_BLUR_RANGE[1] - PARTICLE_BLUR_RANGE[0]) +
        PARTICLE_BLUR_RANGE[0],
      speedX: (Math.random() - 0.5) * RANDOM_TRAJECTORY_FACTOR,
      speedY: (Math.random() - 0.5) * RANDOM_TRAJECTORY_FACTOR,
    }));

    setParticles(newParticles);
  }, []);

  // Smooth animation update
  useEffect(() => {
    const updateAnimation = () => {
      if (Math.abs(scrollVelocity.current) > MIN_SPEED_THRESHOLD) {
        setGradientOffset((prev) =>
          Math.max(
            0,
            Math.min(100, prev + scrollVelocity.current * SCROLL_SENSITIVITY)
          )
        );

        setParticles((prev) =>
          prev.map((p) => {
            const newX =
              p.x + scrollVelocity.current * PARTICLE_SCROLL_SPEED + p.speedX;
            const newY =
              p.y - scrollVelocity.current * PARTICLE_SCROLL_SPEED + p.speedY;

            return {
              ...p,
              x: lerp(p.x, clamp(newX, 5, 95), LERP_FACTOR),
              y: lerp(p.y, clamp(newY, 5, 95), LERP_FACTOR),
            };
          })
        );

        scrollVelocity.current *= INERTIA_DECAY;
        rafId.current = requestAnimationFrame(updateAnimation);
      } else {
        cancelAnimationFrame(rafId.current);
        rafId.current = null;
      }
    };

    const handleScroll = () => {
      const scrollDelta = window.scrollY - lastScrollY.current;
      lastScrollY.current = window.scrollY;
      scrollVelocity.current = scrollDelta;

      if (!rafId.current) {
        rafId.current = requestAnimationFrame(updateAnimation);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(rafId.current);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        overflow: "hidden",
        background: `linear-gradient(120deg, #1b1b1b ${gradientOffset}%, #242424)`,
        transition: "background-position 0.3s ease-out",
      }}
    >
      {particles.map((p) => (
        <Box
          key={p.id}
          sx={{
            position: "absolute",
            top: `${p.y}%`,
            left: `${p.x}%`,
            width: p.size,
            height: p.size,
            background: `radial-gradient(circle, ${particleColor}20 0%, rgba(0, 0, 0, 0) 80%)`,
            filter: `blur(${p.blur}px)`,
            borderRadius: "50%",
            pointerEvents: "none",
          }}
        />
      ))}
      <Box sx={{ position: "relative", zIndex: 1 }}>{children}</Box>
    </Box>
  );
};

// Linear interpolation function for smooth movements
const lerp = (start, end, factor) => start + (end - start) * factor;

// Clamp function to keep values in a range
const clamp = (value, min, max) => Math.max(min, Math.min(max, value));

DynamicBackground.propTypes = {
  particleColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default DynamicBackground;
