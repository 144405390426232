import React from "react";
import Select from "react-select";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import Tags from "../../firebase/tags";
import { useTranslation } from "react-i18next";

const TagFilter = ({ type, selectedTags, onTagChange }) => {
  const { t } = useTranslation();
  const tagOptions = Tags.getSuggestionsByType(type, t);

  const handleTagChange = (selectedOptions) => {
    const tags = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    onTagChange(tags);
  };

  // Convert selectedTags to the format expected by react-select
  const selectedTagOptions = selectedTags.map((tag) => ({
    value: tag,
    label: t(`tags:${tag}`),
  }));

  return (
    <Box sx={{ m: 1, minWidth: 240 }}>
      <Select
        isMulti
        value={selectedTagOptions}
        onChange={handleTagChange}
        options={tagOptions}
        placeholder={t("filter_tags", { ns: "tags" })}
        styles={Tags.getSelectStyles()}
      />
    </Box>
  );
};

TagFilter.propTypes = {
  type: PropTypes.string.isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTagChange: PropTypes.func.isRequired,
};

export default TagFilter;
