import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  CircularProgress,
  CardMedia,
} from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AutomaticTranslation from "../misc/AutomaticTranslation";
import WorldImage from "../misc/WorldImage";

const WorldsPage = () => {
  const { t } = useTranslation("page_worlds");
  const navigate = useNavigate();
  const [worlds, setWorlds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorlds = async () => {
      try {
        const snapshot = await getDocs(collection(firestore, "worlds"));
        const fetchedWorlds = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data
          };
        });
        setWorlds(fetchedWorlds);
      } catch (error) {
        console.error("Error fetching worlds:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorlds();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
      <Typography variant="h4" gutterBottom>
        {t("worlds_title")}
      </Typography>
      {worlds.length === 0 ? (
        <Typography variant="body1">{t("no_worlds")}</Typography>
      ) : (
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          spacing={2}
          sx={{ width: "100%", maxWidth: 1200, m: 2 }}
        >
          {worlds.map((world) => (
            <Card
              key={world.id}
              sx={{
                cursor: "pointer",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => navigate(`/world/${world.slug}`)}
            >
              {world.screenshot && (
                <CardMedia>
                  <WorldImage path={world.screenshot} />
                </CardMedia>
              )}
              <CardContent>
                <AutomaticTranslation
                  originalText={world.name}
                  translation={world.translation?.name}
                  typographyProps={{
                    variant: "h5",
                    color: "primary",
                    gutterBottom: true,
                  }}
                />
                <AutomaticTranslation
                  originalText={world.description}
                  translation={world.translation?.description}
                  typographyProps={{
                    variant: "body2",
                    color: "text.secondary",
                    gutterBottom: true,
                  }}
                />
              </CardContent>
              <CardActions>
                <Typography variant="caption">
                  {t("levels_count", { count: world.levels?.length || 0 })}
                </Typography>
              </CardActions>
            </Card>
          ))}
        </Masonry>
      )}
    </Box>
  );
};

export default WorldsPage;
