export default class Observable {
  constructor() {
    this.observers = new Map();
  }

  addObserver(label, callback) {
    // console.log('add', label, this.observers.get(label)?.length);
    this.observers.has(label) || this.observers.set(label, []);
    this.observers.get(label).push(callback);
  }

  removeObserver(label, callback) {
    // console.log('remove', label, this.observers.get(label)?.length);
    this.observers.has(label) || this.observers.set(label, []);
    this.observers.set(label, this.observers.get(label).filter((item) => {
      return item !== callback;
    }));
  }

  notify(label, e) {
    // console.log('notify', label, this.observers.get(label)?.length);

    const observers = this.observers.get(label);
    if (observers && observers.length) {
      observers.forEach((callback) => {
        callback(e);
      });
    }
  }

  clearAllObservers() {
    this.observers = new Map();
  }
}
