import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const PublicationStatus = ({ published }) => {
  const { t } = useTranslation("document_card");

  if (published === undefined) {
    return <div></div>;
  }

  return (
    <Tooltip title={published ? t("published") : t("private")} arrow>
      <Box
        sx={{
          color: published ? "success.main" : "text.secondary",
          display: "inline-block",
          m: 0.5
        }}
      >
        <FontAwesomeIcon icon={["fas", published ? "unlock" : "lock"]} />
      </Box>
    </Tooltip>
  );
};

PublicationStatus.propTypes = {
  published: PropTypes.bool,
};

export default PublicationStatus;
