import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { BabylonRenderer } from "./lib/babylon_renderer";

const BabylonPreviewRenderer = memo(({ data, type }) => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const rendererRef = useRef(null);

  // Resize observer for dynamic sizing
  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      rendererRef.current.resize();
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!data || !type || !canvasRef.current) return;

    // Initialize the renderer
    rendererRef.current = new BabylonRenderer(canvasRef.current, type, data);
    rendererRef.current.initialize();

    return () => {
      if (rendererRef.current) {
        rendererRef.current.destroy();
        rendererRef.current = null;
      }
    };
  }, [data, type]);

  return (
    <Box
      component="div"
      ref={containerRef}
      sx={{
        width: "100%",
        height: "100%",
        // margin: "auto",
        position: "relative",
      }}
    >
      <canvas
        style={{
          display: "block",
          transform: "scaleY(-1)", // Inverse verticalement
          width: "100%",
          height: "100%",
        }}
        ref={canvasRef}
      />
    </Box>
  );
});

BabylonPreviewRenderer.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["machines", "levels"]).isRequired,
};

export default BabylonPreviewRenderer;
