import { Color3, Mesh, MeshBuilder, PhysicsAggregate, PhysicsShapeType, Plane, Ray, Vector3, VertexBuffer, VertexData } from "@babylonjs/core";
import Materials from "../materials";

import alea from 'alea';
import { createNoise2D } from 'simplex-noise';
import { clamp, closestEvenNumber, equalsWithEpsilon, gaussianRandom, round, smoothstep } from "../lib/math";
import Observable from "../lib/observable";
import { CustomMaterial } from "@babylonjs/materials";


export default class Terrain extends Observable {
  constructor(scene, level, preview=false) {
    super();

    this.scene = scene;
    this.level = level;
    this.preview = preview;

    this._createTerrain();

    if (preview)
      return;

    this.level.addObserver('set-parameter', ({name}) => {
      const shouldGround = 
        (name === 'seed' && this.level.parameters['groundType'] === 'uneven') ||
        name === 'groundType' ||
        name === 'groundSize';

      if (shouldGround)
        this._storeGroundAltitude();

      this._createTerrain();

      if (shouldGround)
        this._groundEveryRootBlock();
    });
    this.level.addObserver('imported', ({reset}) => {
      if (reset === false)
        this._createTerrain();
    });
  }

  elevationAt(x, z) {
    if (Math.abs(x) > this.size / 2)
      return 0; // -Infinity;
    if (Math.abs(z) > this.size / 2)
      return 0; // -Infinity;

    switch(this.type) {
      case 'uneven':
        return this._elevationGridAtPrecise(x, z);
      default:
        return -0.5;
    }
  }

  getMinimumElevation() {
    return this.minElevation;
  }

  getMaximumElevation() {
    return this.maxElevation;
  }

  _getParameters() {
    this.size = closestEvenNumber(this.level.parameters['groundSize']) - 1;
    this.type = this.level.parameters['groundType'];
    this.groundMaterial = this.level.parameters['groundMaterial'];
    this.seed = this.level.parameters['seed'];
  }

  _createTerrain() {
    this._dispose();
    this._getParameters();
    this.minElevation = -0.5;
    this.maxElevation = -0.5;
    switch(this.type) {
      case 'uneven':
        this.mesh = this._createElevationGridMesh();
        break;
      default:
        this.mesh = this._createFlatMesh();
    }
    if (this.preview)
      this._setPreviewMaterial();
    else
      this._setTerrainMaterial();

    this.notify('changed');
  }

  _setPreviewMaterial() {
    const groundColor =
      this.level?.parameters?.groundMaterial === "dangerous"
        ? new Color3(0.83, 0.33, 0.37)
        : new Color3(0.0, 0.58, 0.56);

        // Create a CustomMaterial instance
    const customMaterial = new CustomMaterial("gridMaterial", this.scene);

    // Set base properties for the StandardMaterial
    customMaterial.diffuseColor = groundColor // Background color
    customMaterial.specularColor = new Color3(0.2, 0.2, 0.2); // Minimal specular
    customMaterial.alpha = 1.0; // No transparency

    // Custom fragment shader for the grid
    customMaterial.Fragment_Custom_Diffuse(`
      float gridFrequency = 1.0; // Number of cells per unit
      float lineWidth = 0.02; // Line thickness
      float gridOffset = 0.5; // Offset for grid lines

      // Calculate grid lines
      vec2 grid = fract((vPositionW.xz + gridOffset) * gridFrequency);
      float lineX = step(grid.x, lineWidth) + step(1.0 - grid.x, lineWidth);
      float lineY = step(grid.y, lineWidth) + step(1.0 - grid.y, lineWidth);
      float line = max(lineX, lineY);

      // Modify the diffuse color based on the grid
      vec3 gridColor = diffuseColor - vec3(0.05);
      result = mix(diffuseColor, gridColor, line);
    `);

    customMaterial.freeze();

    this.mesh.material = customMaterial;
  }

  _setTerrainMaterial() {
    this.mesh.material = Materials.getInstance().createOrRetrieveMaterial({
      'color': { 'palette': 'ground.' + this.groundMaterial },
      'baseTexture': 'tiles/color',
      'normalTexture': 'tiles/normal',
      'metallicRoughnessTexture': 'tiles/metallicRoughness',
      'occlusionTexture': 'tiles/occlusion',
      'uScale': this.size / 10.0,
      'vScale': this.size / 10.0,
      'uOffset': 1.0 / 10.0,
      'vOffset': 1.0 / 10.0,
    }).clone();
  }

  _createFlatMesh() {
    const ground = MeshBuilder.CreateBox('ground', {
      width: this.size,
      height: this.size,
      depth: this.size
    }, this.scene);
    ground.position.y = -0.5 - this.size / 2 + 0.01;

    if (this.preview === false) {
      ground.isPickable = true;
      ground.checkCollisions = true;
      ground.aggregate = new PhysicsAggregate(ground, PhysicsShapeType.BOX, { mass: 0, friction: 1 }, this.scene);
      ground.aggregate.body.contactData = { type: 'ground' };
    }

    return ground;
  }

  _createElevationGridMesh() {
    // cf: https://www.redblobgames.com/maps/terrain-from-noise/
    const prng = alea(this.seed);

    this.randomParameters = {
      frequency: 1 + gaussianRandom(0, 0.3, prng),
      meanAltitude: 120 + gaussianRandom(0, 50, prng),
      redistribution: clamp(1 + gaussianRandom(0, 0.5, prng), 0.1, 10),
      hasTerraces: prng() > 0.9,
      nTerraces: Math.ceil(10 + gaussianRandom(0, 3, prng)),
      hasRim: prng() > 0.9,
      rimMean: 0.4 * 2 * (prng() - 0.5),
      rimStdDev: 0.025,
      rimAlpha: 2 * Math.PI * (prng() - 0.5),
      rimAmplitude: gaussianRandom(0, 2.5, prng)
    };

    const genE = createNoise2D(prng);
    this._simplexNoise = (nx, ny) => { return genE(nx, ny) / 2 + 0.5; }

    this.subdivisions = Math.floor(this.size / 15);
    this.elevationGridAtCenter = this._noise(0, 0);

    let ground = MeshBuilder.CreateGround(
      "ground",
      {
        width: this.size,
        height: this.size,
        updatable: true,
        subdivisions: this.subdivisions,
      },
      this.scene
    );

    this._randomizeVertices(ground);

    let shell = this._createShell();
    ground = Mesh.MergeMeshes([ground, shell]);

    ground.createNormals();
    ground.freezeNormals();
    ground.freezeWorldMatrix();

    if (this.preview === false) {
      ground.isPickable = true;
      ground.checkCollisions = true;
      ground.aggregate = new PhysicsAggregate(ground, PhysicsShapeType.MESH, { mass: 0, friction: 1 }, this.scene);
      ground.aggregate.body.contactData = { type: 'ground' };
    }

    return ground;
  }

  _createShell() {
    const s = this.size/2;
    const yO = -0.5;
    let shell = new Mesh('shell', this.scene);
    let vertexData = new VertexData();
    vertexData.positions = [
      s, yO, s,
      s, -2 * s + yO, s,
      -s, -2 * s + yO, s,
      -s, yO, s,
      -s, -2 * s + yO, s,
      -s, -2 * s + yO, -s,
      s, -2 * s + yO, -s,
      -s, -2 * s + yO, -s,
      -s, -2 * s + yO, s,
      s, yO, -s,
      s, -2 * s + yO, -s,
      s, -2 * s + yO, s,
      -s, yO, -s,
      -s, -2 * s + yO, -s,
      s, -2 * s + yO, -s,
      -s, yO, s,
      -s, yO, -s,
      s, -2 * s + yO, s,
      s, yO, s,
      s, yO, -s
    ];
    vertexData.uvs = [1.0, 1.0, 1.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 1.0, 0.0, 1.0, 0.0, 1.0];
    vertexData.normals = [-0.0, -0.0, 1.0, -0.0, -0.0, 1.0, -0.0, -0.0, 1.0, -1.0, -0.0, -0.0, -1.0, -0.0, -0.0, -1.0, -0.0, -0.0, -0.0, -1.0, -0.0, -0.0, -1.0, -0.0, -0.0, -1.0, -0.0, 1.0, -0.0, -0.0, 1.0, -0.0, -0.0, 1.0, -0.0, -0.0, -0.0, -0.0, -1.0, -0.0, -0.0, -1.0, -0.0, -0.0, -1.0, -0.0, -0.0, 1.0, -1.0, -0.0, -0.0, -0.0, -1.0, -0.0, 1.0, -0.0, -0.0, -0.0, -0.0, -1.0];
    vertexData.indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 0, 2, 15, 3, 5, 16, 6, 8, 17, 9, 11, 18, 12, 14, 19];

    vertexData.applyToMesh(shell);
    return shell;
  }

  _randomizeVertices(mesh) {
    let positions = mesh.getVerticesData(VertexBuffer.PositionKind);

    for (let i = 0; i < positions.length; i += 3) {
      const x = positions[i];
      const z = positions[i + 2];

      positions[i + 1] = this._elevationGridAt(x, z);

      this.minElevation = Math.min(positions[i + 1], this.minElevation);
      this.maxElevation = Math.max(positions[i + 1], this.maxElevation);
    }

    mesh.updateVerticesData(VertexBuffer.PositionKind, positions);
  }

  // take care of the grid polygons.
  _elevationGridAtPrecise(x, z) {
    const tileLength = this.size / this.subdivisions;

    // compute the position of the quad corners
    let pos1 = new Vector3();
    pos1.x = round(x, tileLength) - tileLength / 2;
    pos1.z = round(z, tileLength) - tileLength / 2;
    pos1.y = this._elevationGridAt(pos1.x, pos1.z);
    let pos2 = new Vector3();
    pos2.x = round(x, tileLength) - tileLength / 2;
    pos2.z = round(z, tileLength) + tileLength / 2;
    pos2.y = this._elevationGridAt(pos2.x, pos2.z);
    let pos3 = new Vector3();
    pos3.x = round(x, tileLength) + tileLength / 2;
    pos3.z = round(z, tileLength) + tileLength / 2;
    pos3.y = this._elevationGridAt(pos3.x, pos3.z);
    let pos4 = new Vector3();
    pos4.x = round(x, tileLength) + tileLength / 2;
    pos4.z = round(z, tileLength) - tileLength / 2;
    pos4.y = this._elevationGridAt(pos4.x, pos4.z);

    // triangle 1: 1 2 4
    // triangle 2: 2 3 4

    // Determine on which triangle the point (x, z) lies using barycentric coordinates
    let hitTriangle;
    if (x - pos2.x + z - pos2.z > pos4.x - pos2.x + pos4.z - pos2.z) {
      // Point is in triangle 2
      hitTriangle = [pos2, pos3, pos4];
    } else {
      // Point is in triangle 1
      hitTriangle = [pos1, pos2, pos4];
    }

    let trianglePlane = Plane.FromPoints(hitTriangle[0], hitTriangle[1], hitTriangle[2]);
    let rayStartAltitude = Math.max(hitTriangle[0].y, hitTriangle[1].y, hitTriangle[2].y) + 1;
    let ray = new Ray(
      new Vector3(x, rayStartAltitude, z),
      new Vector3(0, -1, 0)
    );
    let hitDistance = ray.intersectsPlane(trianglePlane);
    let elevation = rayStartAltitude - hitDistance;

    /* // Debug code
    let sphere1 = MeshBuilder.CreateIcoSphere('tmp', {radius: 0.5, subdivisions: 3}, this.scene)
    sphere1.position = hitTriangle[0].clone();
    let sphere2 = MeshBuilder.CreateIcoSphere('tmp', {radius: 0.5, subdivisions: 3}, this.scene)
    sphere2.position = hitTriangle[1].clone();
    let sphere3 = MeshBuilder.CreateIcoSphere('tmp', {radius: 0.5, subdivisions: 3}, this.scene)
    sphere3.position = hitTriangle[2].clone();

    let sphere = MeshBuilder.CreateIcoSphere('tmp', {radius: 0.5, subdivisions: 3}, this.scene)
    sphere.position = new Vector3(x, elevation, z);

    setTimeout(() => {
      sphere.dispose();
      sphere1.dispose();
      sphere2.dispose();
      sphere3.dispose();
    }, 3000);
    */

    return elevation;
  }

  _elevationGridAt(x, z) {
    if (
      equalsWithEpsilon(Math.abs(x), this.size / 2, 0.1) ||
      equalsWithEpsilon(Math.abs(z), this.size / 2, 0.1)
    )
      return -0.5;

    const planarDistanceFromCenter = Math.sqrt(x*x + z*z);
    return smoothstep(planarDistanceFromCenter, 20, 120) * (this._noise(x, z) - this.elevationGridAtCenter) - 0.5;
  }

  _noise(u, v) {
    const nx = u / 1000 * this.randomParameters.frequency;
    const ny = v / 1000 * this.randomParameters.frequency;

    let e = (
        1.00 * this._simplexNoise( 1 * nx,  1 * ny)
        + 0.50 * this._simplexNoise( 2 * nx,  2 * ny)
        + 0.25 * this._simplexNoise( 4 * nx,  4 * ny)
        + 0.13 * this._simplexNoise( 8 * nx,  8 * ny)
        + 0.06 * this._simplexNoise(16 * nx, 16 * ny)
        + 0.03 * this._simplexNoise(32 * nx, 32 * ny)
    );
    e = e / (1.00 + 0.50 + 0.25 + 0.13 + 0.06 + 0.03);

    e = Math.pow(e, this.randomParameters.redistribution);

    if (this.randomParameters.hasTerraces)
      e = Math.round(e * this.randomParameters.nTerraces) / this.randomParameters.nTerraces;

    e *= this.randomParameters.meanAltitude;

    if (this.randomParameters.hasRim) {
      e += this.randomParameters.rimAmplitude *
        (1 / (this.randomParameters.rimStdDev * Math.sqrt(2 * Math.PI))) *
        Math.exp(-0.5 * (
          Math.pow(
            (
              nx * Math.sin(this.randomParameters.rimAlpha) +
              ny * Math.cos(this.randomParameters.rimAlpha) -
              this.randomParameters.rimMean
            ) / this.randomParameters.rimStdDev
          , 2)
        ))      
    }

    return e;
  }

  
  _dispose() {
    if (this.mesh) {
      this.mesh.material.dispose();
      this.mesh.dispose();
      this.mesh = undefined;
      this.notify('changed');
    }
  }

  _storeGroundAltitude() {
    this.level.blocks.forEach(block => {
      block.altitude = block.y - this.elevationAt(block.x, block.z);
    });
  }

  _groundEveryRootBlock() {
    this.level.blocks.forEach(block => {
      const altitude = 'altitude' in block ? block.altitude : 0.5;
      block.translate(
        clamp(block.x, -this.size / 2, this.size / 2),
        this.elevationAt(block.x, block.z) + altitude,
        clamp(block.z, -this.size / 2, this.size / 2),
      );
    });
  }
}
