import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { useTranslation } from "react-i18next";

import { SnackbarProvider } from "notistack";

import About from "./components/pages/About";
import GameContainer from "./GameContainer";
import HomePage from "./components/Homepage";
import TopBar from "./components/TopBar";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import initializeIcons from "./icons";
import { CssBaseline } from "@mui/material";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import TmpHomePage from "./components/TmpHomePage";

import { Helmet } from "react-helmet";

import ReactGA from "react-ga4";
import NotFoundPage from "./components/pages/NotFoundPage";
import User from "./components/pages/User";
import Profile from "./components/pages/Profile";
import DocumentPage from "./components/pages/DocumentPage";
import Storage from "./firebase/Storage";
import WorldsPage from "./components/pages/WorldsPage";
import WorldDetailPage from "./components/pages/WorldDetailPage";
import Admin from "./components/Admin";
import AdminJsonViewer from "./components/AdminJsonViewer";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/config";
import { handleFirebaseUser } from "./redux/firebaseUtils";
import DocumentsPage from "./components/DocumentsPage";

initializeIcons();

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    ns: [
      "titles",

      "keywords",
      "tags",
      "menu",
      "auth",
      "footer",

      "page_about",
      "page_home",
      "page_documents",
      "page_user_and_profile",
      "page_not_found",
      "page_world_detail",
      "page_worlds",
      "status",

      "snackbar",
      "translation",

      "enums",
      "blocks",
      "block_tooltips",
      "parameters",
      "parameter_tooltips",

      "game_messages",
      "game_menus",
      "game_tooltips",

      "blockly_categories",
      "blockly_enums",
      "blockly_messages",
      "blockly_tooltips",

      "component_level_completed_overlay",
      "component_world_summary",

      "document_card",
      "dialog_save",

      "results"
    ],
    fallbackLng: "en",
    initImmediate: false,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      ReactGA.initialize("G-ZZLMLSXH05", {
        gtagOptions: {
          anonymize_ip: true,
          allow_google_signals: false,
          allow_ad_personalization_signals: false,
          cookie_flags: "SameSite=None; Secure",
        },
      });

      ReactGA.send("pageview");
    }
  }, []);
  const { t } = useTranslation("titles");

  const theme = createTheme({
    palette: {
      // https://mui.com/customization/color/#color-palette
      // https://mui.com/material-ui/customization/default-theme/
      primary: {
        main: "#6EC2BB",
      },
      // background: {
      //   paper: "#0e052b",
      //   default: "#0e052b"
      // },
      mode: "dark",
      yellow: "#E5D712",
    },
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            overflowY: "initial", // to fix visibility of sliders value tooltip if this is the first item of the fields.
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            overflow: "visible", // to fix visibility of sliders value tooltip if this is the first item of the fields.
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backdropFilter: "blur(3px)",
            zIndex: 3,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            backdropFilter: "blur(3px)",
            zIndex: 2,
          },
        },
      },
    },
  });

  // restore the user session when reloading a page.
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      handleFirebaseUser(user, dispatch);
    });

    return () => unsubscribe();
  }, [dispatch]);

  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
          >
            <Routes>
              <Route
                path="/user/:userSlug"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("user")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <User />
                  </>
                }
              />

              <Route
                path="/profile"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("my_profile")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <Profile />
                  </>
                }
              />
              <Route
                path="/level/:slug"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("level")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <DocumentPage type={Storage.TYPE.LEVEL} />
                  </>
                }
              />
              <Route
                path="/machine/:slug"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("machine")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <DocumentPage type={Storage.TYPE.MACHINE} />
                  </>
                }
              />
              <Route
                path="/factory"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("factory")}
                      </title>
                    </Helmet>
                    <GameContainer isLevelEditor={false} />
                  </>
                }
              />
              <Route
                path="/editor"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("editor")}
                      </title>
                    </Helmet>
                    <GameContainer isLevelEditor={true} />
                  </>
                }
              />
              <Route
                path="/levels/:userSlug?"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("load_level")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <DocumentsPage type={Storage.TYPE.LEVEL} />
                  </>
                }
              />
              <Route
                path="/machines/:userSlug?"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("load_machine")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <DocumentsPage type={Storage.TYPE.MACHINE} />
                  </>
                }
              />
              <Route
                path="/worlds"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("worlds_list")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <WorldsPage />
                  </>
                }
              />
              <Route
                path="/world/:worldSlug"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("world_detail")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <WorldDetailPage />
                  </>
                }
              />
              <Route
                path="/about"
                element={
                  <>
                    <Helmet>
                      <title>
                        {t("game_short")} - {t("about")}
                      </title>
                    </Helmet>
                    <TopBar />
                    <About />
                  </>
                }
              />
              <Route
                path="/home"
                element={
                  <>
                    <Helmet>
                      <title>{t("game_short")}</title>
                    </Helmet>
                    <TopBar />
                    <HomePage />
                  </>
                }
              />
              <Route
                path="/"
                element={
                  <>
                    <Helmet>
                      <title>{t("game_short")}</title>
                    </Helmet>
                    <TmpHomePage />
                  </>
                }
              />

              <Route
                path="/admin"
                element={
                  <>
                    <Helmet>
                      <title>Admin</title>
                    </Helmet>
                    <TopBar />
                    <Admin />
                  </>
                }
              />
              <Route
                path="/json/:type/:documentSlug"
                element={
                  <>
                    <Helmet>
                      <title>Admin JSON Viewer</title>
                    </Helmet>
                    <TopBar />
                    <AdminJsonViewer />
                  </>
                }
              />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </SnackbarProvider>
        </QueryParamProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
