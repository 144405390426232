import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFunctions, httpsCallable } from "firebase/functions";

const Admin = () => {
  const claims = useSelector((state) => state.user.claims);
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updates, setUpdates] = useState(null);

  useEffect(() => {
    if (!claims) {
      return; // Do nothing while claims are not yet loaded
    }

    // Check admin privileges using Redux claims
    if (claims?.god === true) {
      setIsAuthorized(true);
    } else {
      navigate("/404"); // Redirect unauthorized users
    }
  }, [claims, navigate]);

  const handlePopulateTranslations = async () => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const populateTranslations = httpsCallable(
        functions,
        "populateTranslations"
      );
      const result = await populateTranslations();
      setUpdates(result.data.updates);
    } catch (error) {
      console.error("Error populating translations:", error);
      alert("Failed to populate translations.");
    } finally {
      setLoading(false);
    }
  };

  if (!isAuthorized) {
    return null; // Show nothing while checking authorization
  }

  return (
    <div>
      <h1>Admin Page</h1>
      <p>
        Welcome to the admin panel. This section is restricted to authorized
        users only.
      </p>

      <section>
        <h2>Populate Translations</h2>
        <button onClick={handlePopulateTranslations} disabled={loading}>
          {loading ? "Processing..." : "Populate Translations"}
        </button>
        {updates && (
          <div>
            <h3>Updates Summary:</h3>
            <pre>{JSON.stringify(updates, null, 2)}</pre>
          </div>
        )}
      </section>

      <section>
        <h2>JSON Viewer</h2>
        <p>Use the following paths to view the JSON content of documents:</p>
        <ul>
          <li>
            Levels: <code>/admin/json/levels/LEVEL_SLUG.json</code>
          </li>
          <li>
            Machines: <code>/admin/json/machines/MACHINE_SLUG.json</code>
          </li>
        </ul>
        <Link to="/json/levels/sampleLevelSlug">View Sample Level</Link>
        <br />
        <Link to="/json/machines/sampleMachineSlug">View Sample Machine</Link>
      </section>
    </div>
  );
};

export default Admin;
