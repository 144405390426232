import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import Storage from "../../firebase/Storage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import CompressedPreview from "./CompressedPreview";
import { inflate } from "pako";
import LikeButton from "../document_parts/LikeButton";
import Results from "../document_parts/Results";
import AttemptLabel from "../document_parts/AttemptLabel";
import DocumentSummary from "../misc/DocumentSummary";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

const DocumentCard = ({
  doc,
  type,

  automaticTranslation = true,
  width = 300,
  height = 200,

  showInfoButton = false,
  showPlayButton = false,
  showEditButton = false,
  showLoadButton = false,
  onLoad = undefined,
}) => {
  const {
    id,
    slug,
    uid,
    userAvatarID,
    userDisplayName,
    userSlug,
    name,
    translation,
    published = false,
    likeCount = 0,
    attemptCount = 0,
    data,
  } = doc; // match with the firebase document

  const { t } = useTranslation("document_card");
  const location = useLocation();
  const navigate = useNavigate();
  const isGameLoaded = ["/factory", "/editor"].includes(location.pathname);

  // Redux selectors
  const user = useSelector((state) => state.user.user);
  const claims = useSelector((state) => state.user.claims);

  const handleInfo = () => {
    navigate(`/${Storage.typeString(type)}/${slug}`);
  };
  const handlePlay = () => {
    if (user)
      navigate(
        `/factory?${Storage.typeToGETParameterName(type)}=${slug}`
      );
    else
      enqueueSnackbar(t("must_be_signedin", { ns: "auth" }), {
        variant: "info",
      });
  };
  const handleEdit = () => {
    if (user && claims?.editor && uid === user.uid)
      navigate(`/editor?${Storage.typeToGETParameterName(type)}=${slug}`);
    else
      enqueueSnackbar(t("must_be_signedin", { ns: "auth" }), {
        variant: "info",
      });
  };
  const handleLoad = () => {
    if (!isGameLoaded) return;
    const compressedBuffer = Uint8Array.from(atob(data), (c) =>
      c.charCodeAt(0)
    );
    const decompressedBuffer = inflate(compressedBuffer);
    let jsonData = JSON.parse(new TextDecoder().decode(decompressedBuffer));
    jsonData = Object.assign({}, doc, jsonData);
    onLoad(jsonData, slug);
  };

  return (
    <Card
      sx={{
        m: 0.5,
        width: { width },
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      <CardMedia>
        <CompressedPreview
          data={data}
          type={type}
          width={width}
          height={height}
          centerOverlay={
            showPlayButton && (
              <Tooltip title={t("play")}>
                <IconButton
                  disableRipple
                  onClick={handlePlay}
                  sx={{
                    opacity: 0.6,
                    transition: "opacity 0.3s",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  {" "}
                  <FontAwesomeIcon icon={["fas", "play"]} size="2x" />
                </IconButton>
              </Tooltip>
            )
          }
          bottomRightOverlay={
            <>
              {showLoadButton && (
                <Tooltip title={t("load")}>
                  <IconButton
                    disableRipple
                    color="primary"
                    onClick={handleLoad}
                  >
                    <FontAwesomeIcon icon={["fas", "play"]} />
                  </IconButton>
                </Tooltip>
              )}
              {showEditButton && (
                <Tooltip disableRipple title={t("edit")}>
                  <IconButton color="primary" onClick={handleEdit}>
                    <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
                  </IconButton>
                </Tooltip>
              )}
              {showInfoButton && (
                <Tooltip disableRipple title={t("info")}>
                  <IconButton color="primary" onClick={handleInfo}>
                    <FontAwesomeIcon icon={["fas", "info"]} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
      </CardMedia>
      <CardContent sx={{ p: "0.5em", "&:last-child": { pb: "0.5em" } }}>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DocumentSummary
            type={type}
            slug={slug}
            userSlug={userSlug}
            userAvatarID={userAvatarID}
            userDisplayName={userDisplayName}
            name={name}
            published={published}
            translation={automaticTranslation ? translation : undefined}
            small={true}
          />
          <Stack
            sx={{
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Results levelID={id} />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LikeButton likeCount={likeCount} type={type} documentID={id} />
              <AttemptLabel attemptCount={attemptCount} />
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

DocumentCard.propTypes = {
  doc: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  automaticTranslation: PropTypes.bool,

  showInfoButton: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  showLoadButton: PropTypes.bool,
  onLoad: PropTypes.func,
};

export default DocumentCard;
