import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";

import { Parallax } from "react-parallax";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageButton from "./misc/LanguageButton";
import MBGLogo from "./misc/MBGLogo";
import Page from "./Page";
import DynamicBackground from "./generic/DynamicBackground";
import PageContainer from "./PageContainer";
import Neon from "./generic/Neon";
import WebM from "./generic/WebM";
import StartupCard from "./panels/StartupCard";
import SocialButton from "../misc/SocialButton";
import YoutubeEmbed from "./misc/YoutubeEmbed";

const TmpHomePage = (props) => {
  const { t, i18n } = useTranslation(["page_home", "status"]);
  const formattedDate = (year, month, day) => {
    return new Date(year, month - 1, day).toLocaleDateString(i18n.language, {
      // weekday: 'long',
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            width: "100%",
            // bgcolor: props.simulationTheme ? 'primary.main' : 'background.paper'
          }}
        >
          <Button
            color="inherit"
            component={Link}
            to="/"
            sx={{ fontSize: "125%" }}
          >
            <MBGLogo height="2em" />
          </Button>
          <LanguageButton />
        </Toolbar>
      </AppBar>

      <Page>
        <Parallax
          blur={7}
          bgImage="/static/images/hero_background.jpg"
          strength={300}
          style={{
            width: "100%",
          }}
        >
          <Container
            sx={{
              height: "50vh",
            }}
          >
            <Stack
              sx={{
                height: "100%",
              }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <MBGLogo height="70%" />
            </Stack>
          </Container>
        </Parallax>

        <DynamicBackground
        // colors={["#2E2E2E", "#1F1D1D", "#242424"]}
        >
          <PageContainer>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                sx={{
                  textAlign: "center",
                }}
              >
                <WebM name="idle" width={116} height={200} />
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  <Trans
                    i18nKey="description"
                    ns="page_home"
                    values={{ game: "<b>Machine Building Game ©</b>" }}
                    components={{ b: <strong /> }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </PageContainer>

          <PageContainer>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <YoutubeEmbed embedId="-kF7_-1cVMQ" title="Annoucement teaser" />
            </Stack>
          </PageContainer>

          <PageContainer>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <StartupCard
                title={t("title_1")}
                description={t("description_1")}
                image="/static/images/conveyor.png"
                cover={false}
                animateImage={true}
              />
              <StartupCard
                title={t("title_2")}
                description={t("description_2")}
                image="/static/images/rover.png"
                cover={false}
                animateImage={true}
              />
              <StartupCard
                title={t("title_3")}
                description={t("description_3")}
                image="/static/images/engineers.png"
                cover={false}
                animateImage={true}
              />
            </Box>
          </PageContainer>

          <PageContainer>
            <Neon color="#6EFF99" text={t("status_title", { ns: "status" })} />
            <Typography>{t("status_description", { ns: "status" })}</Typography>
            <Typography>
              {t("status_social_networks", { ns: "status" })}
            </Typography>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
              spacing={2}
            >
              <SocialButton
                url="https://discord.gg/sYVnUDU8TP"
                iconName="discord"
                large={true}
              />
              <SocialButton
                url="https://www.youtube.com/channel/UCuvoRPioD9uO0QaqQqEmZnw"
                iconName="youtube"
                large={true}
              />
              <SocialButton
                url="https://www.instagram.com/machine.building.game"
                iconName="instagram"
                large={true}
              />
              <SocialButton
                url="https://www.tiktok.com/@machine.building.game"
                iconName="tiktok"
                large={true}
              />
              <SocialButton
                url="https://www.facebook.com/people/Machine-Building-Game/61558840641221/"
                iconName="facebook"
                large={true}
              />
              <SocialButton
                url="https://twitter.com/_m_b_g"
                iconName="x-twitter"
                large={true}
              />
            </Stack>

            <TableContainer component={Paper} sx={{ margin: 4, width: "auto" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell width="20%">
                      {t("status_soon", { ns: "status" })}
                    </TableCell>
                    <TableCell size="string">
                      {t("status_private_beta", { ns: "status" })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="20%">
                      {formattedDate(2024, 4, 18)}
                    </TableCell>
                    <TableCell size="string">
                      {t("status_announcement", { ns: "status" })}
                      <SocialButton
                        url="https://www.youtube.com/watch?v=-kF7_-1cVMQ"
                        iconName="youtube"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width="20%">
                      {formattedDate(2024, 1, 19)}
                    </TableCell>
                    <TableCell size="string">
                      {t("status_website_online", { ns: "status" })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </PageContainer>
        </DynamicBackground>
      </Page>
    </>
  );
};

export default TmpHomePage;
