import { setUser, fetchUserData, clearUser, fetchUserLikes, fetchUserResults } from "./userSlice";

/**
 * Gère l'utilisateur Firebase en dispatchant les informations sérialisables.
 *
 * @param {Object} user - L'utilisateur Firebase.
 * @param {Function} dispatch - La fonction dispatch de Redux.
 */
export const handleFirebaseUser = async (user, dispatch) => {
  if (user) {
    try {
      // Extraire les propriétés sérialisables
      const { uid, email, displayName, photoURL } = user;
      const idTokenResult = await user.getIdTokenResult();

      // Dispatch des données sérialisables et des claims
      dispatch(
        setUser({
          user: { uid, email, displayName, photoURL }, // Sérialisable
          claims: idTokenResult.claims, // Sérialisable
        })
      );

      // Charger les données utilisateur Firestore
      dispatch(fetchUserData(uid));
      dispatch(fetchUserLikes(uid));
      dispatch(fetchUserResults(uid));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  } else {
    // Si aucun utilisateur n'est connecté
    dispatch(clearUser());
  }
};
