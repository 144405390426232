import React, { useState } from "react";
import { Button, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

// Function to generate a random avatarID
function generateAvatarID(length = 6) {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";
  for (let i = 0; i < length; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
}

const AvatarIDChooser = ({ onChange, initialAvatarID }) => {
  const [avatarHistory, setAvatarHistory] = useState([initialAvatarID]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { t } = useTranslation("page_user_and_profile");

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
      onChange(avatarHistory[currentIndex - 1]); // Send previous ID to parent
    }
  };

  const handleNext = () => {
    const newAvatarID = generateAvatarID();
    const updatedHistory = [
      ...avatarHistory.slice(0, currentIndex + 1),
      newAvatarID,
    ];
    setAvatarHistory(updatedHistory);
    setCurrentIndex(updatedHistory.length - 1);
    onChange(newAvatarID); // Send new ID to parent
  };

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="outlined"
        onClick={handlePrevious}
        disabled={currentIndex === 0} // Disable if at the beginning of history
      >
        {t("previous_avatar_button")}
      </Button>
      <Button variant="contained" onClick={handleNext}>
        {t("new_avatar_button")}
      </Button>
    </Stack>
  );
};

AvatarIDChooser.propTypes = {
  initialAvatarID: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired, // Callback to send avatarID to parent
};

export default AvatarIDChooser;
