import { Box, Skeleton } from "@mui/material";
import { styled } from "@mui/system";

const Image = ({ src, loading, error, title = "image", sx, skeletonWidth="200px", skeletonHeight="150px" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "inherit",
        ...sx,
      }}
    >
      {(loading || error) && (
        <Skeleton
          variant="rectangular"
          width={skeletonWidth}
          height={skeletonHeight}
          animation={loading ? "wave" : false}
        />
      )}
      {!loading && !error && src && (
        <Img draggable="false" src={src} alt={title} />
      )}
    </Box>
  );
};

const Img = styled("img")({
  maxHeight: "100%",
  maxWidth: "100%",
  display: "block",
  width: "auto",
  height: "auto",
  borderRadius: "inherit",
  WebkitTouchCallout: "none",
});

export default Image;
