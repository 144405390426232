import { Color3 } from '@babylonjs/core';

const palette = {
  white: Color3.FromHexString('#ffffff'),
  grey: Color3.FromHexString('#bbbbbb'),
  black: Color3.FromHexString('#000000'),
  magenta: Color3.FromHexString('#FF00FF'),

  environment: Color3.FromHexString('#0D988E'),
  'ground.regular': Color3.FromHexString('#0D988E'),
  'ground.dangerous': Color3.FromHexString('#E53C42'),

  // BlockView.state
  'selected': Color3.FromHexString('#00FF00'),
  'subselected': Color3.FromHexString('#008800'),
  'error': Color3.FromHexString('#FF0000'),
  'activated': Color3.FromHexString('#0000FF'),
  'activated-disabled': Color3.FromHexString('#D3D3D3'),

  'ghost.success': Color3.FromHexString('#00FF00'),
  'ghost.error': Color3.FromHexString('#FF0000'),

  'crystal': Color3.FromHexString('#FF4400'),

  'primitive.white': Color3.FromHexString('#FFFFFF'),
  'primitive.yellow': Color3.FromHexString('#F4C30F'),
  'primitive.orange': Color3.FromHexString('#FB6E03'),
  'primitive.red': Color3.FromHexString('#F61D11'),
  'primitive.pink': Color3.FromHexString('#F74EEC'),
  'primitive.lightGreen': Color3.FromHexString('#3CEA19'),
  'primitive.darkGreen': Color3.FromHexString('#1F5608'),
  'primitive.turquois': Color3.FromHexString('#00FFE5'),
  'primitive.lightBlue': Color3.FromHexString('#4088E6'),
  'primitive.darkBlue': Color3.FromHexString('#0E0E96'),
  'primitive.brown': Color3.FromHexString('#722F08'),
  'primitive.black': Color3.FromHexString('#333333'),
  'primitive.grey': Color3.FromHexString('#7B7B7B'), // not visibile from the color chooser, used for the disabled blocks.
};

export default class Palette {
  static getColor(colorID, linearSpace) {
    if (!(colorID in palette)) {
      console.assert(false, 'Color ' + colorID + ' does not exist.')
      return palette['white'];
    }
    if (linearSpace)
      return palette[colorID].toLinearSpace();
    else
      return palette[colorID];
  }

  static getColorLabelsByCategory(category) {
    let labels = [];
    Object.keys(palette).forEach(key => {
      if (key.startsWith(category + '.'))
        labels.push(key.slice(category.length + 1));
    });
    return labels;
  }
}
