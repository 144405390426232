import React from "react";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../Page";
import DynamicBackground from "../generic/DynamicBackground";
import PageContainer from "../PageContainer";
import Neon from "../generic/Neon";

const About = (props) => {
  const { t } = useTranslation(["page_about", "menu"]);

  return (
    <Page>
      <DynamicBackground>
        <PageContainer>
          <Neon text={t("about", { ns: "menu" })} />
          <Typography>{t("content")}</Typography>
        </PageContainer>
      </DynamicBackground>
    </Page>
  );
};

export default About;
