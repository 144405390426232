import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useQueryParam, StringParam } from "use-query-params";
import { Backdrop, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

import { isMobile } from "react-device-detect";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { firestore } from "./firebase/config";

import Restricted from "./components/pages/Restricted";
import { Helmet } from "react-helmet";
import { collection, getDocs, query, where } from "firebase/firestore";
import { inflate } from "pako";
import { useSelector } from "react-redux";

const Game = React.lazy(() => import("./components/Game"));

const GameContainer = (props) => {
  const user = useSelector((state) => state.user.user);
  const claims = useSelector((state) => state.user.claims);

  const [levelSlug, setLevelSlug] = useQueryParam("level", StringParam);
  const [startupMachineSlug, setStartupMachineSlug] = useQueryParam(
    "startupMachine",
    StringParam
  );
  const [generateThumbnails] = useQueryParam("generateThumbnails", StringParam);

  const [isLoadingLevel, setIsLoadingLevel] = useState(false);
  const [isLoadingMachine, setIsLoadingMachine] = useState(false);

  const [levelData, setLevelData] = useState(null);
  const [startupMachineData, setStartupMachineData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation("titles");

  useEffect(() => {
    let isCancelled = false; // Permet d'annuler le fetch si le composant est démonté ou l'effet redémarré

    const loadLevelData = async () => {
      if (levelSlug) {
        setIsLoadingLevel(true);
        const data = await fetchDataBySlug(levelSlug, "levels");
        if (!isCancelled) setLevelData(data);
        setIsLoadingLevel(false);
      }
    };

    const loadMachineData = async () => {
      if (startupMachineSlug) {
        setIsLoadingMachine(true);
        const data = await fetchDataBySlug(startupMachineSlug, "machines");
        if (!isCancelled) setStartupMachineData(data);
        setIsLoadingMachine(false);
      }
    };

    loadLevelData();
    loadMachineData();

    return () => {
      isCancelled = true; // Annule les opérations si le composant est démonté
    };
  }, [levelSlug, startupMachineSlug]);

  if (!user) return <Restricted />;
  if (props.isLevelEditor && claims?.editor !== true) return <Restricted />;

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        width: "100vw",
        height: "100dvh",
        maxHeight: "100vh",
        p: 0,
        userSelect: "none",
      }}
    >
      <Helmet>
        <title>
          {t("game_short")} - {props.isLevelEditor ? t("editor") : t("factory")}
          {levelSlug ? " - " + levelSlug : ""}
        </title>
      </Helmet>
      <Suspense
        fallback={
          <Backdrop open={true}>
            <CircularProgress />
          </Backdrop>
        }
      >
        {isLoadingLevel && (
          <Backdrop open={true}>
            <CircularProgress />
          </Backdrop>
        )}
        {isLoadingMachine && (
          <Backdrop open={true}>
            <CircularProgress />
          </Backdrop>
        )}
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <Game
            config={{
              isLevelEditor: props.isLevelEditor,
              isMobile: isMobile,
              locale: i18n.resolvedLanguage || "en",
            }}
            user={user}
            levelData={levelData}
            startupMachineData={startupMachineData}
            setLevelSlug={setLevelSlug}
            setStartupMachineSlug={setStartupMachineSlug}
            generateThumbnails={generateThumbnails}
            enqueueSnackbar={enqueueSnackbar}
          />
        </DndProvider>
      </Suspense>
    </Box>
  );
};

GameContainer.propTypes = {
  isLevelEditor: PropTypes.bool.isRequired,
};

export default GameContainer;

const fetchDataBySlug = async (slug, type) => {
  if (!slug) return null;

  try {
    // Query Firestore for the document by slug
    const docRef = query(
      collection(firestore, type),
      where("slug", "==", slug)
    );
    const snapshot = await getDocs(docRef);

    if (snapshot.empty) {
      console.warn(`No document found for slug "${slug}" in "${type}"`);
      return null;
    }

    const doc = snapshot.docs[0];
    const data = doc.data();

    if (!data || !data.data) {
      console.warn(
        `Document "${doc.id}" in "${type}" is missing "data" field.`
      );
      return null;
    }

    try {
      // Decompress the `data` field
      const compressedBuffer = Uint8Array.from(atob(data.data), (c) =>
        c.charCodeAt(0)
      );
      const decompressedBuffer = inflate(compressedBuffer);
      const jsonData = JSON.parse(new TextDecoder().decode(decompressedBuffer));

      return { ...jsonData, id: doc.id, ...data };
    } catch (error) {
      console.error(`Error decompressing "data" for slug "${slug}":`, error);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching ${type} by slug "${slug}":`, error);
    return null;
  }
};
