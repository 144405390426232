import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import pako from "pako";
import { firestore } from "../firebase/config";
import { Button, Box, Paper, TextField } from "@mui/material";

const AdminJsonViewer = () => {
  const { type, documentSlug } = useParams();
  const [jsonContent, setJsonContent] = useState(null);
  const [compressedContent, setCompressedContent] = useState(null);
  const [error, setError] = useState(null);
  const [isCopiedJson, setIsCopiedJson] = useState(false);
  const [isCopiedCompressed, setIsCopiedCompressed] = useState(false);
  const [docRef, setDocRef] = useState(null);

  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        const collectionRef = collection(firestore, type);
        const docQuery = query(
          collectionRef,
          where("slug", "==", documentSlug)
        );
        const querySnapshot = await getDocs(docQuery);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          const data = docSnap.data().data;

          // Decompress the JSON data
          const compressedBuffer = Uint8Array.from(atob(data), (c) =>
            c.charCodeAt(0)
          );
          const decompressedBuffer = pako.inflate(compressedBuffer);
          const jsonData = JSON.parse(
            new TextDecoder().decode(decompressedBuffer)
          );

          setJsonContent(jsonData);
          setCompressedContent(data);
          setDocRef(docSnap.ref);
        } else {
          setError(`Document with slug "${documentSlug}" not found in ${type}`);
        }
      } catch (err) {
        console.error("Error fetching JSON data:", err);
        setError("Failed to fetch or parse the document data.");
      }
    };

    fetchJsonData();
  }, [type, documentSlug]);

  const handleCopyToClipboard = (content, setCopiedState) => {
    navigator.clipboard.writeText(content);
    setCopiedState(true);
    setTimeout(() => setCopiedState(false), 2000);
  };

  const handleJsonEdit = (event) => {
    try {
      const updatedJson = JSON.parse(event.target.value);
      setJsonContent(updatedJson);

      const compressedBuffer = pako.deflate(JSON.stringify(updatedJson));
      const base64Compressed = btoa(
        String.fromCharCode.apply(null, new Uint8Array(compressedBuffer))
      );
      setCompressedContent(base64Compressed);
    } catch (error) {
      console.error("Invalid JSON format:", error);
    }
  };

  const handleCompressedEdit = (event) => {
    try {
      const compressedBuffer = Uint8Array.from(atob(event.target.value), (c) =>
        c.charCodeAt(0)
      );
      const decompressedBuffer = pako.inflate(compressedBuffer);
      const jsonData = JSON.parse(new TextDecoder().decode(decompressedBuffer));

      setCompressedContent(event.target.value);
      setJsonContent(jsonData);
    } catch (error) {
      console.error("Invalid compressed data:", error);
    }
  };

  const handleSave = async () => {
    if (docRef && compressedContent) {
      try {
        await updateDoc(docRef, { data: compressedContent });
        alert("Document updated successfully!");
      } catch (err) {
        console.error("Error saving document:", err);
        alert("Failed to save the document.");
      }
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <h1>JSON Viewer</h1>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!compressedContent}
        >
          Save Changes
        </Button>
      </Box>
      {compressedContent && (
        <Paper
          component="div"
          sx={{
            position: "relative",
            padding: 2,
            borderRadius: 2,
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            overflow: "auto",
            mb: 2,
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              handleCopyToClipboard(compressedContent, setIsCopiedCompressed)
            }
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            {isCopiedCompressed ? "Copied!" : "Copy Compressed"}
          </Button>
          <TextField
            fullWidth
            multiline
            value={compressedContent}
            onChange={handleCompressedEdit}
            sx={{ fontFamily: "monospace" }}
          />
        </Paper>
      )}
      {jsonContent && (
        <Paper
          component="div"
          sx={{
            position: "relative",
            padding: 2,
            borderRadius: 2,
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            overflow: "auto",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() =>
              handleCopyToClipboard(
                JSON.stringify(jsonContent, null, 2),
                setIsCopiedJson
              )
            }
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            {isCopiedJson ? "Copied!" : "Copy JSON"}
          </Button>
          <TextField
            fullWidth
            multiline
            value={JSON.stringify(jsonContent, null, 2)}
            onChange={handleJsonEdit}
            sx={{ fontFamily: "monospace" }}
          />
        </Paper>
      )}
    </Box>
  );
};

export default AdminJsonViewer;
