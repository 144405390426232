import {
  Color3,
  MeshBuilder,
  StandardMaterial,
  TransformNode,
  Vector3,
  ArcRotateCamera,
  Quaternion,
} from "@babylonjs/core";
import { Color, Material, PI, preview_constants } from "./preview_constants";
import {
  createTriangle,
  createPrism,
  createCrystal,
  createBentPipe,
} from "./babylon_shapes";
import Terrain from "../../../engine/core/view/terrain";
import BabylonManagerSingleton from "./babylon_manager";
import Storage from "../../../firebase/Storage";

const MIN_CAMERA_RADIUS = 10; // Minimum zoom level
const MAX_CAMERA_RADIUS = 50; // Maximum zoom level

const N_RENDERINGS = 3;

export class BabylonRenderer {
  constructor(canvasElement, type, data) {
    this.canvasElement = canvasElement;
    this.type = type;
    this.data = data;
    this.engine = null;
    this.scene = null;
    this.camera = null;
    this.light = null;
    this.mousePos = { x: 0, y: 0 };
    this.firstMove = true;
    this.renderings = 0;
  }

  resize() {
    if (this.engine) {
      this.engine.resize();
      this.renderings = N_RENDERINGS;
    }
  }

  initialize() {
    this.babylonManager = BabylonManagerSingleton;

    this.engine = this.babylonManager.engine;
    this.scene = this.babylonManager.scene;
    this.materialCache = this.babylonManager.materialCache;
    this.meshCache = this.babylonManager.meshCache;

    this.nodeContainer = [];

    // Add ground
    if (this.type === Storage.TYPE.LEVEL) {
      const levelHack = {
        parameters: {
          groundSize: this.data?.parameters?.groundSize || 199,
          groundType: this.data?.parameters?.groundType || "flat",
          groundMaterial: this.data?.parameters?.groundMaterial || "regular",
          seed: this.data?.parameters?.seed || 0,
        },
      };
      this.terrain = new Terrain(this.scene, levelHack, true);
      this.nodeContainer.push(this.terrain.mesh);

      this.addWater();
    }

    // Parse and render blocks
    this.parseBlocks();

    // console.log(this.nodeContainer);

    const { center, maxDistance } = this.calculateSceneBounds();
    const radius =
      this.type === Storage.TYPE.LEVEL ? maxDistance : 3 * maxDistance;
    // Create camera
    this.camera = new ArcRotateCamera(
      "camera",
      Math.PI / 3, // Initial alpha
      Math.PI / 3, // Initial beta
      Math.min(Math.max(radius, MIN_CAMERA_RADIUS), MAX_CAMERA_RADIUS),
      center, // Target the center of the scene
      this.scene
    );

    this.camera.upperRadiusLimit = MAX_CAMERA_RADIUS;
    this.camera.lowerRadiusLimit = MIN_CAMERA_RADIUS;

    // Add mouse interaction
    this.addMouseInteraction();

    // Perform an initial render
    this.renderIfRequired();

    // Start the render loop conditionally
    this.renderIfRequired = this.renderIfRequired.bind(this);
    this.engine.runRenderLoop(this.renderIfRequired);

    // Handle window resize
    window.addEventListener("resize", () => {
      this.resize();
    });
  }

  renderIfRequired() {
    if (this.renderings <= 0 || this.camera === null) return;

    this.showThisSceneOnly();
    this.babylonManager.render(this.canvasElement, this.camera);
    this.renderings--;
  }

  showThisSceneOnly() {
    this.hideAll();
    this.babylonManager.show(this.nodeContainer);
  }

  hideAll() {
    this.babylonManager.show([]);
  }

  addWater() {
    const water = this.data?.parameters?.water || false;
    const waterLevel = this.data?.parameters?.waterLevel || -1;
    const groundSize = this.data?.parameters?.groundSize || 199;

    if (!water) return; // If water is not enabled, do nothing

    // Create the water plane
    const waterPlane = MeshBuilder.CreateGround(
      "water",
      { width: groundSize, height: groundSize },
      this.scene
    );

    // Position the water plane at the water level
    waterPlane.position.y = waterLevel;

    // Create a material for the water
    const waterMaterial = new StandardMaterial("waterMaterial", this.scene);

    // Set the water's color and transparency
    waterMaterial.diffuseColor = new Color3(0.0, 0.3, 0.8); // Blue color
    waterMaterial.specularColor = new Color3(0.7, 0.7, 0.7); // Reflective highlights
    waterMaterial.alpha = 0.5; // Transparency

    // Assign the material to the water plane
    waterPlane.material = waterMaterial;

    this.nodeContainer.push(waterPlane);

    // Optimize by freezing material and world matrix
    waterMaterial.freeze();
    waterPlane.freezeWorldMatrix();
  }

  parseBlocks() {
    if (!this.data || !this.type) return;

    // console.log(this.data);

    if (this.type === "levels" && this.data.blocks) {
      this.data.blocks.forEach((block) => {
        const transformNode = this.createRootTransform(block);
        this.renderBlock(block, transformNode);
      });
    } else if (this.type === "machines") {
      this.data.asset = "machine";
      const transformNode = this.createRootTransform(this.data);
      this.renderBlock(this.data, transformNode);
    }
  }

  createRootTransform(blockData) {
    const position = new Vector3(
      blockData.position?.[0] || 0,
      blockData.position?.[1] || 0,
      blockData.position?.[2] || 0
    );

    const rotation = new Vector3(
      blockData.rotation?.[0] || 0,
      blockData.rotation?.[1] || 0,
      blockData.rotation?.[2] || 0
    );
    const transformNode = new TransformNode(
      blockData?.parameters?.label
        ? blockData?.parameters?.label
        : blockData.asset,
      this.scene
    );
    transformNode.position = position;
    transformNode.rotation = rotation;
    transformNode.freezeWorldMatrix();

    // this.nodeContainer.push(transformNode);

    return transformNode;
  }

  renderBlock(blockData, parentNode) {
    const asset = preview_constants[blockData.asset];
    if (!asset) {
      console.warn(`Unknown asset type: ${blockData.asset}`);
      return;
    }

    asset.shapes.forEach((shape) =>
      this.addShape(blockData, shape, parentNode)
    );

    if (blockData.slots && asset.slots) {
      Object.keys(blockData.slots).forEach((slotName) => {
        const slot = asset.slots[slotName];
        const childBlock = blockData.slots[slotName];

        if (!slot || !childBlock) return;

        const slotPosition = new Vector3(
          slot.translation?.[0] || 0,
          slot.translation?.[1] || 0,
          slot.translation?.[2] || 0
        );

        let slotRotation = new Vector3(
          slot.rotation?.[0] || 0,
          slot.rotation?.[1] || 0,
          slot.rotation?.[2] || 0
        );

        if (childBlock?.pivot) {
          const pivotRotation = (PI / 2) * childBlock.pivot;
          slotRotation = computePivotedRotation(slotRotation, pivotRotation);
        }

        const slotNode = new TransformNode("slotNode", this.scene);
        slotNode.parent = parentNode;
        slotNode.position = slotPosition;
        slotNode.rotation = slotRotation;
        slotNode.freezeWorldMatrix();

        this.renderBlock(childBlock, slotNode);
      });
    }
  }

  addShape(blockData, shape, parentNode) {
    const hexColor = blockData?.parameters?.color
      ? Color[blockData?.parameters?.color]
      : shape.color;

    // Générer une clé unique pour le cache en excluant rotation et translation
    const hashableShape = {
      type: shape.type,
      dimension: shape.dimension,
      radius: shape.radius,
      width: shape.width,
      height: shape.height,
      depth: shape.depth,
      material: shape.material,
      innerRadius: shape.innerRadius,
      arcRadius: shape.arcRadius,
      arcLength: shape.arcLength,
      bottomRadius: shape.bottomRadius,
      topRadius: shape.topRadius,
      color: hexColor,
    };

    // Convertir en JSON et hasher
    const hashKey = this.generateHash(JSON.stringify(hashableShape));

    let mesh = this.meshCache[hashKey];

    if (!mesh) {
      // Créer le mesh et le cacher
      mesh = this.createMesh(shape);
      mesh.material = this.getMaterial(blockData, shape);
      mesh.isVisible = false;
      this.meshCache[hashKey] = mesh;
    }

    // Create mesh instances
    const instance = mesh.createInstance(
      `instance_${hashKey}_${Math.random()}`
    );
    // const instance = mesh.clone(`instance_${hashKey}_${Math.random()}`);
    instance.isVisible = true;

    instance.position = new Vector3(
      shape.translation?.[0] || 0,
      shape.translation?.[1] || 0,
      shape.translation?.[2] || 0
    );
    instance.rotation = new Vector3(
      shape.rotation?.[0] || 0,
      shape.rotation?.[1] || 0,
      shape.rotation?.[2] || 0
    );

    if (parentNode) {
      instance.parent = parentNode;
    }

    instance.freezeWorldMatrix(); // Optimisation : geler la matrice du monde
    this.nodeContainer.push(instance);
  }

  // Méthode pour générer un hash simple
  generateHash(input) {
    // Si vous êtes dans un environnement Node.js
    // return createHash("sha256").update(input).digest("hex");

    // Implémentation simple pour un environnement navigateur
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convertir en entier 32 bits
    }
    return hash.toString();
  }

  createMesh(shape) {
    switch (shape.type) {
      case "Box":
        return MeshBuilder.CreateBox(
          "box",
          {
            width: shape.dimension?.[0] || 1,
            height: shape.dimension?.[1] || 1,
            depth: shape.dimension?.[2] || 1,
          },
          this.scene
        );
      case "Cylinder":
        return MeshBuilder.CreateCylinder(
          "cylinder",
          {
            diameter: (shape.radius || 0.5) * 2,
            height: shape.height || 1,
            tessellation: 12,
          },
          this.scene
        );
      case "Cone":
        return MeshBuilder.CreateCylinder(
          "cone",
          {
            diameterTop: (shape.topRadius || 0.5) * 2,
            diameterBottom: (shape.bottomRadius || 0.5) * 2,
            height: shape.height || 1,
            tessellation: 12,
          },
          this.scene
        );
      case "Sphere":
        return MeshBuilder.CreateSphere(
          "sphere",
          {
            diameter: (shape.radius || 0.5) * 2,
            segments: 12,
          },
          this.scene
        );
      case "Capsule":
        return MeshBuilder.CreateCapsule(
          "capsule",
          {
            radius: shape.radius || 0.5,
            height: shape.height || 1,
            tessellation: 12,
          },
          this.scene
        );
      case "Triangle":
        return createTriangle(
          shape.width || 1,
          shape.height || 1,
          shape.depth || 1,
          this.scene
        );
      case "Prism":
        return createPrism(
          shape.width || 1,
          shape.height || 1,
          shape.depth || 1,
          this.scene
        );
      case "Crystal":
        return createCrystal(shape.width || 1, shape.height || 1, this.scene);
      case "BentPipe":
        return createBentPipe(
          shape.innerRadius || 0.5,
          shape.arcRadius || 2,
          shape.arcLength || 1.57,
          12,
          6,
          this.scene
        );
      default:
        console.warn(`Unknown shape type: ${shape.type}`);
        return null;
    }
  }

  getMaterial(blockData, shape) {
    const hexColor = blockData?.parameters?.color
      ? Color[blockData?.parameters?.color]
      : shape.color;
    const materialKey = `${shape.material}_${hexColor}`;

    if (!this.materialCache[materialKey]) {
      const color = Color3.FromHexString(hexColor);
      const material = new StandardMaterial("material", this.scene);

      material.diffuseColor = color;

      switch (shape.material) {
        case Material.plastic:
          material.specularColor = new Color3(0.1, 0.1, 0.1); // Subtle shine
          material.roughness = 0.9;
          break;
        case Material.metal:
          material.diffuseColor = material.diffuseColor.multiply(new Color3(0.75, 0.75, 0.75));
          material.specularColor = new Color3(0.8, 0.8, 0.8); // Strong shine
          material.roughness = 0.2;
          break;
        case Material.carbon:
          material.diffuseColor = new Color3(0.1, 0.1, 0.1); // Darker diffuse
          material.roughness = 0.6;
          break;
        case Material.rubber:
          material.specularColor = new Color3(0.05, 0.05, 0.05); // Almost no shine
          material.roughness = 1.0;
          break;
        case Material.tile:
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          material.roughness = 0.5;
          break;
        case Material.shiny:
          material.specularColor = new Color3(0.8, 0.8, 0.8);
          material.emissiveColor = color;
          break;
        default:
          // Default material settings
          material.specularColor = new Color3(0.2, 0.2, 0.2);
          material.roughness = 0.8;
      }
      material.freeze();

      this.materialCache[materialKey] = material;
    }

    return this.materialCache[materialKey];
  }

  calculateSceneBounds() {
    let totalPosition = new Vector3(0, 0, 0);
    let objectCount = 0;

    // Premier pass : Calculer le centre de gravité (centre des objets)
    this.nodeContainer.forEach((node) => {
      if (["ground", "water"].includes(node.name)) return;

      totalPosition.addInPlace(node.absolutePosition);
      objectCount++;
    });

    const center =
      objectCount > 0
        ? totalPosition.scale(1 / objectCount)
        : new Vector3(0, 0, 0);

    // Second pass : Calculer la distance moyenne à partir du centre
    // let totalDistance = 0;
    let maxDistance = 0;

    this.nodeContainer.forEach((node) => {
      if (["ground", "water"].includes(node.name)) return;

      const distance = Vector3.Distance(node.absolutePosition, center);
      // totalDistance += distance;

      // Suivre la plus grande distance
      if (distance > maxDistance) {
        maxDistance = distance;
      }
    });

    // Distance moyenne
    // const averageDistance = objectCount > 0 ? totalDistance / objectCount : 0;

    // Ajouter un buffer pour le radius de la caméra
    // const radius = maxDistance + averageDistance;

    // console.log({ center, maxDistance, averageDistance, radius });

    return { center, maxDistance };
  }

  addMouseInteraction() {
    const handleMouseMove = (event) => {
      if (this.camera === null) return;

      const rect = this.canvasElement.getBoundingClientRect();
      const canvasX = event.clientX - rect.left - rect.width / 2;
      const canvasY = event.clientY - rect.top;

      // Normalize mouse position
      const normalizedX = canvasX / rect.width;
      const normalizedY = canvasY / rect.height;

      this.updateCameraFromInteraction(normalizedX, normalizedY);
    };

    const handleTouchMove = (event) => {
      if (this.camera === null || event.touches.length === 0) return;

      const touch = event.touches[0]; // Get the first touch
      const rect = this.canvasElement.getBoundingClientRect();
      const canvasX = touch.clientX - rect.left - rect.width / 2;
      const canvasY = touch.clientY - rect.top;

      // Normalize touch position
      const normalizedX = canvasX / rect.width;
      const normalizedY = canvasY / rect.height;

      this.updateCameraFromInteraction(normalizedX, normalizedY);
    };

    const preventDefaultTouchMove = (event) => {
      if (event.target === this.canvasElement) {
        event.preventDefault();
      }
    };

    this.canvasElement.addEventListener("mouseenter", () => {
      this.renderings = N_RENDERINGS; // Start rendering when mouse enters
    });

    this.canvasElement.addEventListener("mousemove", handleMouseMove);

    this.canvasElement.addEventListener("touchstart", () => {
      this.renderings = N_RENDERINGS; // Start rendering on touch start
    });

    this.canvasElement.addEventListener("touchmove", handleTouchMove);

    this.canvasElement.addEventListener("mouseleave", () => {
    });

    this.canvasElement.addEventListener("touchend", () => {
    });

    window.addEventListener("touchmove", preventDefaultTouchMove, {
      passive: false,
    });

    // Store the handler to remove it later
    this.preventDefaultTouchMove = preventDefaultTouchMove;
  }

  // Helper function to update the camera
  updateCameraFromInteraction(normalizedX, normalizedY) {
    this.mousePos = { x: normalizedX, y: normalizedY };

    // Control camera rotation with normalizedX
    this.camera.alpha = Math.PI / 2 - 2 * (this.mousePos.x * Math.PI);

    // Control camera zoom (radius) with normalizedY
    const zoomFactor =
      this.mousePos.y *
      (this.camera.upperRadiusLimit - this.camera.lowerRadiusLimit);
    this.camera.radius = Math.min(
      Math.max(
        zoomFactor + this.camera.lowerRadiusLimit,
        this.camera.lowerRadiusLimit
      ),
      this.camera.upperRadiusLimit
    );

    this.renderings = N_RENDERINGS; // Enable rendering
  }

  destroy() {
    this.renderings = 0;

    this.nodeContainer.forEach((node) => {
      node.dispose();
    });
    this.nodeContainer = [];

    this.camera.dispose();
    this.camera = null;

    this.engine.stopRenderLoop(this.renderIfRequired);

    // Supprimer les listeners pour éviter les fuites mémoire
    window.removeEventListener("resize", this.resize);

    if (this.canvasElement) {
      this.canvasElement.removeEventListener("mouseenter", this.onMouseEnter);
      this.canvasElement.removeEventListener("mouseleave", this.onMouseLeave);
      this.canvasElement.removeEventListener("mousemove", this.onMouseMove);
      this.canvasElement.removeEventListener("touchstart", this.onTouchStart);
      this.canvasElement.removeEventListener("touchmove", this.onTouchMove);
      this.canvasElement.removeEventListener("touchend", this.onTouchEnd);

      if (this.preventDefaultTouchMove) {
        window.removeEventListener("touchmove", this.preventDefaultTouchMove);
        this.preventDefaultTouchMove = null;
      }
    }
  }
}

function computePivotedRotation(rotation, angle) {
  const r = Quaternion.FromEulerAngles(0, angle, 0);
  const r0 = Quaternion.FromEulerAngles(rotation.x, rotation.y, rotation.z);
  const newRotation = r0.multiply(r).toEulerAngles();
  return newRotation;
}
