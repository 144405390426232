import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const WorldSummary = ({
  worldSlug,
  previousLevelSlug,
  nextLevelSlug,
  onChangeLevel,
}) => {
  const navigate = useNavigate();
  let { t } = useTranslation("component_world_summary");
  t = useMemo(() => t, [t]);

  return (
    <ButtonGroup variant="contained">
      <Tooltip title={t("previousLevel")} arrow>
        <span>
          <Button
            onClick={() => onChangeLevel(previousLevelSlug)}
            disabled={!previousLevelSlug}
          >
            <FontAwesomeIcon icon={["fas", "arrow-left"]} />
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={t("backToWorldPage")} arrow>
        <Button onClick={() => navigate(`/world/${worldSlug}`)}>
          <FontAwesomeIcon icon={["fas", "globe"]} />
        </Button>
      </Tooltip>
      <Tooltip title={t("nextLevel")} arrow>
        <span>
          <Button
            onClick={() => onChangeLevel(nextLevelSlug)}
            disabled={!nextLevelSlug}
          >
            <FontAwesomeIcon icon={["fas", "arrow-right"]} />
          </Button>
        </span>
      </Tooltip>
    </ButtonGroup>
  );
};

WorldSummary.propTypes = {
  worldSlug: PropTypes.string.isRequired,
  previousLevelSlug: PropTypes.string,
  nextLevelSlug: PropTypes.string,
  onChangeLevel: PropTypes.func.isRequired,
};

export default WorldSummary;
