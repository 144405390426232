import React from "react";
import PropTypes from "prop-types";
import { Box, Tooltip, CircularProgress, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClock,
  faUpRightAndDownLeftFromCenter,
  faGem,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Results = ({ levelID }) => {
  const { t } = useTranslation("results");
  const user = useSelector((state) => state.user.user);
  const results = useSelector((state) => state.user.results);
  const resultsStatus = useSelector((state) => state.user.resultsStatus);

  if (resultsStatus === "loading") {
    return (
      <Box display="flex" alignItems="center">
        <CircularProgress size={16} />
      </Box>
    );
  }

  if (user === null) return <></>;

  const levelResult = results?.levelResults?.[levelID] || {};
  const success = levelResult.success || false;
  const scoreValue = levelResult.scoreValue;
  const scoreType = levelResult.scoreType || "DISTANCE+";

  // Icon and unit mapping for scoreType
  const scoreIcons = {
    "TIME+": faClock,
    "TIME-": faClock,
    "DISTANCE+": faUpRightAndDownLeftFromCenter,
    "DISTANCE-": faUpRightAndDownLeftFromCenter,
    "POINT+": faGem,
    "POINT-": faGem,
  };

  const scoreUnits = {
    "TIME+": "s",
    "TIME-": "s",
    "DISTANCE+": "m",
    "DISTANCE-": "m",
    "POINT+": "",
    "POINT-": "",
  };

  const scoreIcon = scoreIcons[scoreType] || null;
  const scoreUnit = scoreUnits[scoreType] || "";

  return (
    <Box display="flex" alignItems="center">
      {scoreValue && scoreIcon && (
        <Tooltip
          title={t("result.tooltip", { value: scoreValue, unit: scoreUnit })}
          arrow
        >
          <Box display="flex" alignItems="center" ml={2}>
            <FontAwesomeIcon
              icon={scoreIcon}
              style={{ marginRight: 4, color: "#bbb" }}
            />
            <Typography variant="body2" style={{ color: "#bbb" }}>
              {scoreValue}
              {scoreUnit}
            </Typography>
          </Box>
        </Tooltip>
      )}

      <Tooltip
        title={success ? t("level.completed") : t("level.notCompleted")}
        arrow
      >
        <Box display="flex" alignItems="center" mb={1}>
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              color: success ? "#DECF04" : "#444444",
              fontSize: 24,
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

Results.propTypes = {
  levelID: PropTypes.string.isRequired,
};

export default Results;
