import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { keyframes } from "@mui/system";

// Animation config
const ANIMATION_SPEED = 0.75; // Duration of one cycle (seconds)
const DECAY_DURATION = 3.0; // Time to smoothly transition to idle
const IDLE_TIMEOUT = ANIMATION_SPEED * 1000; // Delay before stopping animation
const IDLE_GLOW_INTENSITY = 0.3; // Residual glow when idle (between 0 and 1)
const ACTIVE_GLOW_INTENSITY = 0.8; // Full glow intensity when animating

const Neon = ({ text = "Neon", color = "#2F5DDB", variant = "h3" }) => {
  const [glowIntensity, setGlowIntensity] = useState(IDLE_GLOW_INTENSITY);
  const [isActive, setIsActive] = useState(false);
  const idleTimeoutRef = useRef(null);

  // Keyframe animation with dynamic glow intensity
  const neonAnimation = keyframes`
    from {
      text-shadow: 0 0 ${10 * glowIntensity}px #fff,
        0 0 ${20 * glowIntensity}px #fff,
        0 0 ${30 * glowIntensity}px #fff,
        0 0 ${40 * glowIntensity}px ${color},
        0 0 ${70 * glowIntensity}px ${color},
        0 0 ${80 * glowIntensity}px ${color},
        0 0 ${100 * glowIntensity}px ${color},
        0 0 ${150 * glowIntensity}px ${color};
    }
    to {
      text-shadow: 0 0 ${5 * glowIntensity}px #fff,
        0 0 ${10 * glowIntensity}px #fff,
        0 0 ${15 * glowIntensity}px #fff,
        0 0 ${20 * glowIntensity}px ${color},
        0 0 ${35 * glowIntensity}px ${color},
        0 0 ${40 * glowIntensity}px ${color},
        0 0 ${50 * glowIntensity}px ${color},
        0 0 ${75 * glowIntensity}px ${color};
    }
  `;

  useEffect(() => {
    const handleScroll = () => {
      setIsActive(true);
      setGlowIntensity(ACTIVE_GLOW_INTENSITY);

      // Reset previous timeout
      if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);

      // Schedule smooth transition to idle glow
      idleTimeoutRef.current = setTimeout(() => {
        let decayStep = ACTIVE_GLOW_INTENSITY;
        const decayInterval = setInterval(() => {
          decayStep -= 0.05;
          if (decayStep <= IDLE_GLOW_INTENSITY) {
            clearInterval(decayInterval);
            setIsActive(false);
            setGlowIntensity(IDLE_GLOW_INTENSITY); // Keep minimum glow
          } else {
            setGlowIntensity(decayStep);
          }
        }, (DECAY_DURATION * 1000) / 20);
      }, IDLE_TIMEOUT);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(idleTimeoutRef.current);
    };
  }, []);

  return (
    <Typography
      variant={variant}
      sx={{
        color: "white",
        marginBottom: "1rem",
        animation: isActive
          ? `${neonAnimation} ${ANIMATION_SPEED}s ease-in-out infinite alternate`
          : "none",
        transition: `text-shadow ${DECAY_DURATION}s ease-out`,
        textShadow: isActive
          ? "none"
          : `0 0 ${5 * IDLE_GLOW_INTENSITY}px #fff,
            0 0 ${10 * IDLE_GLOW_INTENSITY}px #fff,
            0 0 ${15 * IDLE_GLOW_INTENSITY}px #fff,
            0 0 ${20 * IDLE_GLOW_INTENSITY}px ${color},
            0 0 ${35 * IDLE_GLOW_INTENSITY}px ${color},
            0 0 ${40 * IDLE_GLOW_INTENSITY}px ${color},
            0 0 ${50 * IDLE_GLOW_INTENSITY}px ${color},
            0 0 ${75 * IDLE_GLOW_INTENSITY}px ${color}`,
      }}
    >
      {text}
    </Typography>
  );
};

Neon.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default Neon;
