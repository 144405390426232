import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserData,
  updateUserData,
  deleteUser,
} from "../../redux/userSlice";
import PageContainer from "../PageContainer";
import {
  Stack,
  TextField,
  Button,
  Typography,
  Alert,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from "@mui/material";
import SignInDialog from "../panels/SignInDialog";
import Avatar from "../../misc/Avatar";
import AvatarIDChooser from "../misc/AvatarIDChooser";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, userData, status, deleteStatus, error } = useSelector(
    (state) => state.user
  );

  const [profileData, setProfileData] = useState({
    displayName: "",
    status: "",
    avatarID: "",
  });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [displayNameError, setDisplayNameError] = useState("");
  const [statusError, setStatusError] = useState("");
  const { t, i18n } = useTranslation("page_user_and_profile");

  useEffect(() => {
    if (user && !userData) {
      dispatch(fetchUserData(user.uid));
    }
  }, [user, userData, dispatch]);

  useEffect(() => {
    if (userData) {
      setProfileData(userData);
    }
  }, [userData]);

  const validateDisplayName = (value) => {
    if (!value.trim()) return t("display_name_empty");
    if (value.length > 20) return t("display_name_max_length");
    return "";
  };

  const validateStatus = (value) => {
    if (value.length > 150) return t("status_max_length");
    return "";
  };

  const handleSave = () => {
    const trimmedDisplayName = profileData.displayName.trim();

    // Validation
    const displayNameError = validateDisplayName(trimmedDisplayName);
    const statusError = validateStatus(profileData.status);

    setDisplayNameError(displayNameError);
    setStatusError(statusError);

    // Si des erreurs existent, ne pas poursuivre
    if (displayNameError || statusError) {
      return;
    }

    // Préparer les mises à jour
    const updates = {};
    if (trimmedDisplayName !== userData.displayName) {
      updates.displayName = trimmedDisplayName;
    }
    if (profileData.status !== userData.status) {
      updates.status = profileData.status;
    }
    if (profileData.avatarID !== userData.avatarID) {
      updates.avatarID = profileData.avatarID;
    }

    // Si aucune modification, arrêter ici
    if (Object.keys(updates).length > 0) {
      dispatch(updateUserData({ userId: user.uid, updates }));
    }
  };

  const handleDeleteProfile = () => {
    dispatch(deleteUser());
    setShowConfirmationDialog(false);
  };

  return (
    <PageContainer>
      <SignInDialog open={!user} onClose={() => {}} />

      {error && <Alert severity="error">{error}</Alert>}

      {status === "loading" && (
        <Skeleton variant="rectangular" width="100%" height={118} />
      )}

      {user && (
        <Stack spacing={2}>
          {/* Card 1: User Info */}
          <Card sx={{ userSelect: "none" }}>
            <CardHeader
              avatar={<Avatar avatarID={profileData.avatarID} size={100} />}
              title={profileData.displayName || "Unknown user"}
              subheader={t("created_at", {
                date: new Date(userData?.createdAt).toLocaleDateString(
                  i18n.language
                ),
              })}
            />
            <CardContent>
              {profileData.status && (
                <Typography variant="body2" color="text.secondary">
                  {profileData.status}
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Card 2: Edit Profile */}
          <Card>
            <CardHeader title={t("edit_profile_title")} />
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  label={t("field_display_name")}
                  variant="outlined"
                  value={profileData.displayName || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      displayName: e.target.value,
                    })
                  }
                  fullWidth
                  error={!!displayNameError}
                  helperText={displayNameError}
                />
                <TextField
                  label={t("field_status")}
                  variant="outlined"
                  value={profileData.status || ""}
                  onChange={(e) =>
                    setProfileData({ ...profileData, status: e.target.value })
                  }
                  fullWidth
                  error={!!statusError}
                  helperText={statusError}
                />
                <AvatarIDChooser
                  initialAvatarID={profileData.avatarID}
                  onChange={(avatarID) =>
                    setProfileData({ ...profileData, avatarID })
                  }
                />
              </Stack>{" "}
            </CardContent>
            <CardActions sx={{ justifyContent: "end" }}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                {t("save_profile_button")}
              </Button>
            </CardActions>
          </Card>

          {/* Card 3: Danger Zone */}
          <Card>
            <CardContent>
              <Typography variant="h5">{t("dangerous_area_title")}</Typography>
            </CardContent>
            <CardActions>
              <Tooltip title={t("dangerous_area_warning")}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setShowConfirmationDialog(true)}
                  startIcon={<FontAwesomeIcon icon={["fas", "trash"]} />}
                >
                  {t("delete_profile")}
                </Button>
              </Tooltip>
            </CardActions>
            <Dialog
              open={showConfirmationDialog}
              onClose={() => setShowConfirmationDialog(false)}
            >
              <DialogTitle>{t("confirmation_title")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("confirmation_message")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowConfirmationDialog(false)}>
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleDeleteProfile}
                  disabled={deleteStatus === "loading"}
                >
                  {deleteStatus === "loading" ? t("deleting") : t("confirm")}
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </Stack>
      )}
    </PageContainer>
  );
};

export default Profile;
