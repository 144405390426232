import { Timestamp } from "firebase/firestore";

export const formatDate = (dateValue, locale) => {
  if (!dateValue) return "Invalid Date";

  let date;

  if (dateValue instanceof Timestamp) {
    date = dateValue.toDate();
  } else if (typeof dateValue === "object" && "seconds" in dateValue) {
    date = new Date(dateValue.seconds * 1000);
  } else if (typeof dateValue === "number") {
    date = new Date(dateValue);
  } else if (dateValue instanceof Date) {
    date = dateValue;
  } else {
    return "Invalid Date";
  }

  return new Intl.DateTimeFormat(locale, {
    day: "2-digit",
    month: "short", // "Jan", "Feb", "Mar" au lieu de "January", "February"...
    year: "numeric",
  }).format(date);
};
