import { CubeTexture, PBRMetallicRoughnessMaterial, StandardMaterial, Texture } from '@babylonjs/core';
import Palette from './view/palette';

let hash = require('object-hash');

export default class Materials {
  static instance = undefined;
  static getInstance(...options) {
    if (Materials.instance === undefined)
      Materials.instance = new Materials(...options);
    return Materials.instance;
  }
  static dispose() {
    Materials.instance = undefined;
  }

  constructor(scene, renderer) {
    this.scene = scene;
    this.renderer = renderer;

    const staticUrl = '/static/textures/';

    this.textures = {
      'environment': CubeTexture.CreateFromPrefilteredData(staticUrl + 'environment.env', scene),
      'tiles/color': new Texture(staticUrl + 'tiles/color.jpg', scene),
      'tiles/normal': new Texture(staticUrl + 'tiles/normal.jpg', scene),
      'tiles/metallicRoughness': new Texture(staticUrl + 'tiles/metallicRoughness.png', scene),
      'tiles/occlusion': new Texture(staticUrl + 'tiles/occlusion.png', scene),
      'tiles/emissive': new Texture(staticUrl + 'tiles/emissive.png', scene),
      'generic/base': new Texture(staticUrl + 'generic/base.jpg', scene),
      'generic/normal': new Texture(staticUrl + 'generic/normal.png', scene),
      'generic/metallicRoughness': new Texture(staticUrl + 'generic/metallicRoughness.jpg', scene),
      'generic/emissive': new Texture(staticUrl + 'generic/emissive.jpg', scene),
      'belt/color': new Texture(staticUrl + 'belt/color.png', scene),
      'belt/normal': new Texture(staticUrl + 'belt/normal.png', scene),
      'thumbtack': new Texture(staticUrl + 'icons/thumbtack.svg', scene, false, false),
      'water-ripple-map': new Texture(staticUrl + 'water-ripple-map.png', scene),
    };

    this.textures['reflections'] = this.textures['environment'].clone();
    this.textures['environment'].coordinatesMode = Texture.SKYBOX_MODE;

    scene.environmentTexture = this.textures['reflections'];
    const skyboxMaterial = new StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = this.textures['environment'];
    renderer.skybox.material = skyboxMaterial;

    this.materialCache = [];
  }

  createOrRetrieveMaterial(data) {
    if (data === undefined)
      data = {};

    const dataHash = hash(data);
    for (let m = 0; m < this.materialCache.length; m++) {
      const material = this.materialCache[m];
      if (material.dataHash === dataHash)
        return material;
    }

    let material = new PBRMetallicRoughnessMaterial('material', this.scene);

    let color = Palette.getColor('white', true);
    if (data.color !== undefined) {
      if ('palette' in data.color)
        color = Palette.getColor(data.color.palette, true);
    }

    const uScale = data.uScale !== undefined ? data.uScale : 1;
    const vScale = data.vScale !== undefined ? data.vScale : 1;
    const uOffset = data.uOffset !== undefined ? data.uOffset : 0;
    const vOffset = data.vOffset !== undefined ? data.vOffset : 0;
    const roughness = data.roughness !== undefined ? data.roughness : 1;
    const metallic = data.metallic !== undefined ? data.metallic : 0;

    material.baseColor = color;
    if (data.baseTexture !== undefined) {
      material.baseTexture = this.textures[data.baseTexture].clone();
      material.baseTexture.uScale = uScale;
      material.baseTexture.vScale = vScale;
      material.baseTexture.uOffset = uOffset;
      material.baseTexture.vOffset = vOffset;
    }

    if (data.normalTexture !== undefined) {
      material.normalTexture = this.textures[data.normalTexture].clone();
      material.normalTexture.uScale = uScale;
      material.normalTexture.vScale = vScale;
      material.normalTexture.uOffset = uOffset;
      material.normalTexture.vOffset = vOffset;
    }

    if (data.metallicRoughnessTexture !== undefined) {
      material.metallicRoughnessTexture = this.textures[data.metallicRoughnessTexture].clone();
      material.metallicRoughnessTexture.uScale = uScale;
      material.metallicRoughnessTexture.vScale = vScale;
      material.metallicRoughnessTexture.uOffset = uOffset;
      material.metallicRoughnessTexture.vOffset = vOffset;
    } else {
      material.roughness = roughness;
      material.metallic = metallic;  
    }

    if (data.emissiveTexture !== undefined) {
      material.emissiveTexture = this.textures[data.emissiveTexture].clone();
      material.emissiveTexture.uScale = uScale;
      material.emissiveTexture.vScale = vScale;
      material.emissiveTexture.uOffset = uOffset;
      material.emissiveTexture.vOffset = vOffset;
      material.emissiveIntensity = 0.0;
    }
  
    if (data.emissiveColor !== undefined && data.emissiveColor.palette) {
      const emissiveColor = Palette.getColor(data.emissiveColor.palette, true);
      material.emissiveColor = emissiveColor;
      material.emissiveIntensity = 10.0;
    }

    if (data.transparency !== undefined) {
      material.alpha = 1.0 - data.transparency;
      material.subSurface.isTranslucencyEnabled = true;
      material.subSurface.tintColor = color;
      material.subSurface.indexOfRefraction = 1.5;
      material.subSurface.translucencyIntensity = 0.7;
    }
    if (data.refractionColor !== undefined) {
      material.subSurface.isRefractionEnabled = true;
      material.subSurface.indexOfRefraction = 1.5;
      material.subSurface.tintColor = Palette.getColor(data.refractionColor.palette, true);
    }

    material.data = data;
    material.dataHash = dataHash;

    material.freeze();

    this.materialCache.push(material);
    return material;
  }
}
