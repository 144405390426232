import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Alert } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { firestore } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import DocumentCardSkeleton from "../panels/DocumentCardSkeleton";
import DocumentCard from "../panels/DocumentCard";
import AutomaticTranslation from "../misc/AutomaticTranslation";
import Storage from "../../firebase/Storage";
import WorldImage from "../misc/WorldImage";

const WorldDetailPage = () => {
  const { worldSlug } = useParams();
  const { t } = useTranslation("page_world_detail");
  const [world, setWorld] = useState(null);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWorld = async () => {
      setLoading(true);
      setError(null);
      try {
        const worldQuery = query(
          collection(firestore, "worlds"),
          where("slug", "==", worldSlug)
        );
        const worldSnapshot = await getDocs(worldQuery);
        if (worldSnapshot.empty) {
          setError(t("world_not_found"));
          setLoading(false);
          return;
        }
        const worldData = worldSnapshot.docs[0].data();
        setWorld(worldData);

        // Fetch levels by IDs
        const levelPromises = worldData.levels.map((levelID) =>
          getDoc(doc(firestore, "levels", levelID))
        );
        const levelSnapshots = await Promise.all(levelPromises);
        const fetchedLevels = levelSnapshots
          .filter((snapshot) => snapshot.exists())
          .map((snapshot) => ({ id: snapshot.id, ...snapshot.data() }));
        setLevels(fetchedLevels);
      } catch (err) {
        console.error("Error fetching world or levels:", err);
        setError(t("error_loading_world"));
      } finally {
        setLoading(false);
      }
    };

    fetchWorld();
  }, [worldSlug, t]);

  if (loading && !world) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
      <AutomaticTranslation
        originalText={world.name}
        translation={world.translation?.name}
        typographyProps={{
          variant: "h4",
          color: "primary",
          gutterBottom: true,
        }}
      />
      <AutomaticTranslation
        originalText={world.description}
        translation={world.translation?.description}
        typographyProps={{
          variant: "body1",
          color: "text.secondary",
          gutterBottom: true,
        }}
      />
      <Box
        sx={{
          maxWidth: "50vw",
          borderRadius: "1em",
        }}
      >
        <WorldImage path={world.screenshot} />
      </Box>
      {loading ? (
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          spacing={2}
          sx={{ width: "100%", maxWidth: 1200, m: 2 }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <DocumentCardSkeleton key={index} />
          ))}
        </Masonry>
      ) : levels.length === 0 ? (
        <Alert severity="info">{t("no_levels_in_world")}</Alert>
      ) : (
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          spacing={2}
          sx={{ width: "100%", maxWidth: 1200, mt: 2 }}
        >
          {levels.map((level) => (
            <DocumentCard
              key={level.id}
              doc={level}
              type={Storage.TYPE.LEVEL}
              showPlayButton={true}
            />
          ))}
        </Masonry>
      )}
    </Box>
  );
};

export default WorldDetailPage;
