import React from "react";
import { useSelector } from "react-redux";
import { AppBar, Button, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import MBGLogo from "./misc/MBGLogo";
import LogButton from "./misc/LogButton";
import LanguageButton from "./misc/LanguageButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TopBar = () => {
  const userData = useSelector((state) => state.user.userData);
  const theme = useTheme();
  const wideScreen = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: "space-between", bgcolor: "background.paper" }}>
        <Button color="inherit" component={Link} to="/" sx={{ fontSize: "125%" }}>
          <MBGLogo height="2em" />
        </Button>
        {wideScreen ? (
          <div>
            <LanguageButton />
            <LogButton userSlug={userData?.slug} />
          </div>
        ) : (
          <IconButton color="inherit">
            <FontAwesomeIcon icon={["fas", "bars"]} />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
