import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firestore } from "../firebase/config";

// Async thunk to fetch user data from Firestore
export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (userId, thunkAPI) => {
    try {
      const userDocRef = doc(firestore, "users", userId);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          ...data,
          createdAt: data.createdAt?.toDate().toISOString(),
        };
      } else {
        throw new Error("User document not found");
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch user level results from Firestore
export const fetchUserResults = createAsyncThunk(
  "user/fetchUserResults",
  async (userId, { getState, rejectWithValue }) => {
    const state = getState();
    if (state.user.resultsStatus === "succeeded") {
      // Ne pas refetch si les résultats sont déjà disponibles
      return state.user.results;
    }

    try {
      const resultsDocRef = doc(firestore, "results", userId);
      const docSnap = await getDoc(resultsDocRef);
      if (docSnap.exists()) {
        return docSnap.data() || {};
      }
      return {};
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to fetch user likes from Firestore
export const fetchUserLikes = createAsyncThunk(
  "user/fetchUserLikes",
  async (userId, { getState, rejectWithValue }) => {
    const state = getState();
    if (state.user.likesStatus === "succeeded") {
      // Si les likes ont déjà été chargés, ne pas refetch
      return state.user.likes;
    }

    try {
      const likesDocRef = doc(firestore, "likes", userId);
      const docSnap = await getDoc(likesDocRef);
      if (docSnap.exists()) {
        const { likesMachines = [], likesLevels = [] } = docSnap.data();

        return { likesMachines, likesLevels };
      }
      return { likesMachines: [], likesLevels: [] };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const toggleLikeForDocument = createAsyncThunk(
  "user/toggleLikeForDocument",
  async ({ documentID, documentType }, { getState, rejectWithValue }) => {
    const user = getState().user.user;
    if (!user) {
      return rejectWithValue("Not authenticated.");
    }
    try {
      const toggleLikeCounterCallable = httpsCallable((getFunctions()), "toggleLikeCounter");
      const result = await toggleLikeCounterCallable({
        documentId: documentID,
        documentType,
      });
      return {
        documentID,
        documentType,
        likeCount: result.data.likeCount,
        isLiked: result.data.isLiked,
      };
    } catch (err) {
      console.error("toggleLikeCounter error:", err);
      return rejectWithValue(err.message);
    }
  }
);

// Async thunk to update user data in Firestore
export const updateUserData = createAsyncThunk(
  "user/updateUserData",
  async ({ userId, updates }, thunkAPI) => {
    try {
      const userDocRef = doc(firestore, "users", userId);
      await updateDoc(userDocRef, updates);
      return updates;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (_, thunkAPI) => {
    try {
      const functions = getFunctions();
      const deleteUser = httpsCallable(functions, "deleteUser");
      const response = await deleteUser();
      if (response.data.success) {
        return true;
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null, // Firebase Auth user
    userData: null, // Firestore user data
    likes: {
      likesMachines: [], // List of liked machine IDs
      likesLevels: [], // List of liked level IDs
    },
    results: {}, // Map of level results
    claims: null, // Firebase user claims
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    likesStatus: "idle", // Status for likes fetch
    resultsStatus: "idle", // Status for level results fetch
    deleteStatus: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setUser(state, action) {
      const { user } = action.payload;
      const { uid, email, displayName, photoURL } = user;
      state.user = { uid, email, displayName, photoURL };
      state.claims = action.payload.claims; // Store the claims
    },
    clearUser(state) {
      state.user = null;
      state.userData = null;
      state.likes = { likesMachines: [], likesLevels: [] };
      state.results = {};
      state.claims = null;
      state.status = "idle";
      state.likesStatus = "idle";
      state.resultsStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle user data
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.status = "succeeded";
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      })

      // Handle user likes
      .addCase(fetchUserLikes.fulfilled, (state, action) => {
        state.likes = action.payload;
        state.likesStatus = "succeeded";
      })
      .addCase(fetchUserLikes.rejected, (state, action) => {
        state.error = action.payload;
        state.likesStatus = "failed";
      })
      .addCase(toggleLikeForDocument.fulfilled, (state, action) => {
        state.likes = action.payload; // Mettre à jour les likes dans Redux
      })

      // Handle user level results
      .addCase(fetchUserResults.fulfilled, (state, action) => {
        state.results = action.payload;
        state.resultsStatus = "succeeded";
      })
      .addCase(fetchUserResults.rejected, (state, action) => {
        state.error = action.payload;
        state.resultsStatus = "failed";
      })

      // Handle user data update
      .addCase(updateUserData.fulfilled, (state, action) => {
        state.userData = { ...state.userData, ...action.payload };
      })

      // Handle user deletion
      .addCase(deleteUser.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.deleteStatus = "succeeded";
        state.user = null;
        state.userData = null;
        state.likes = { likesMachines: [], likesLevels: [] };
        state.results = {};
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.error = action.payload;
      });
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
