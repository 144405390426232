import { Mesh, MeshBuilder, Vector3, VertexData } from "@babylonjs/core";

export function createPrism(width, height, depth, scene) {
  // Define the shape of the triangular face (isocèle)
  const shape = [
    new Vector3(width / 2, 0, 0), // Bottom right (C)
    new Vector3(0, height, 0), // Top (A)
    new Vector3(-width / 2, 0, 0), // Bottom left (B)
  ];

  // Define the extrusion path along the Z-axis
  const path = [
    new Vector3(0, 0, -depth / 2), // Back face
    new Vector3(0, 0, depth / 2), // Front face
  ];

  // Use MeshBuilder.ExtrudeShape to create the prism
  const prism = MeshBuilder.ExtrudeShape(
    "trianglePrism",
    {
      shape,
      path,
      sideOrientation: MeshBuilder.DOUBLESIDE,
      cap: Mesh.CAP_ALL, // Cap both ends
      closeShape: true,
    },
    scene
  );

  // Force recalculation of normals to fix lighting issues
  prism.convertToFlatShadedMesh();

  return prism;
}

export function createTriangle(width, height, depth, scene) {
  // Define the shape of the triangular face (right triangle)
  const shape = [
    new Vector3(width / 2, 0, 0), // Bottom right (C)
    new Vector3(width / 2, height, 0), // Bottom left (B)
    new Vector3(-width / 2, 0, 0), // Top left (A)
  ];

  // Define the extrusion path along the Z-axis
  const path = [
    new Vector3(0, 0, depth / 2), // Front face
    new Vector3(0, 0, -depth / 2), // Back face
  ];

  // Use MeshBuilder.ExtrudeShape to create the prism
  const triangle = MeshBuilder.ExtrudeShape(
    "rightTrianglePrism",
    {
      shape,
      path,
      sideOrientation: MeshBuilder.DOUBLESIDE,
      cap: Mesh.CAP_ALL, // Cap both ends
      closeShape: true,
    },
    scene
  );

  // Force recalculation of normals to fix lighting issues
  triangle.convertToFlatShadedMesh();

  return triangle;
}

export function createCrystal(width, height, scene) {
  // Define the vertices for the crystal
  const positions = [
    0,
    height / 2,
    0, // Top point (A)
    -width / 2,
    0,
    -width / 2, // Bottom left front (B)
    width / 2,
    0,
    -width / 2, // Bottom right front (C)
    width / 2,
    0,
    width / 2, // Bottom right back (D)
    -width / 2,
    0,
    width / 2, // Bottom left back (E)
    0,
    -height / 2,
    0, // Bottom point (F)
  ];

  // Define the indices for the crystal faces
  const indices = [
    // Top pyramid faces
    0,
    1,
    2, // Front
    0,
    2,
    3, // Right
    0,
    3,
    4, // Back
    0,
    4,
    1, // Left

    // Bottom pyramid faces
    5,
    2,
    1, // Front
    5,
    3,
    2, // Right
    5,
    4,
    3, // Back
    5,
    1,
    4, // Left
  ];

  // Define UVs for basic mapping
  const uvs = [
    0.5,
    1, // Top point (A)
    0,
    0.5, // Bottom left front (B)
    1,
    0.5, // Bottom right front (C)
    1,
    0, // Bottom right back (D)
    0,
    0, // Bottom left back (E)
    0.5,
    0, // Bottom point (F)
  ];

  // Create the mesh and apply the vertex data
  const crystal = new Mesh("crystal", scene);
  const vertexData = new VertexData();
  vertexData.positions = positions;
  vertexData.indices = indices;
  vertexData.uvs = uvs;

  vertexData.applyToMesh(crystal);

  // Recalculate normals for proper lighting
  crystal.convertToFlatShadedMesh();

  return crystal;
}

export function createBentPipe(
  innerRadius,
  arcRadius,
  arcLength,
  circleSegments,
  arcSegments,
  scene
) {
  // Step 1: Define the shape (circle on the YZ plane)
  const shape = [];
  for (let i = 0; i <= circleSegments; i++) {
    const angle = (2 * Math.PI * i) / circleSegments;
    shape.push(
      new Vector3(
        innerRadius * Math.cos(angle),
        innerRadius * Math.sin(angle),
        0
      )
    );
  }

  // Step 2: Define the path (arc of a circle on the XY plane)
  const path = [];
  for (let i = 0; i <= arcSegments; i++) {
    const angle = (arcLength * i) / arcSegments; // Distribute points evenly across the arc length
    path.push(
      new Vector3(
        -arcRadius * Math.cos(angle) + arcRadius,
        arcRadius * Math.sin(angle),
        0
      )
    );
  }

  // Step 3: Extrude the shape along the path
  const bentPipe = MeshBuilder.ExtrudeShape(
    "bentPipe",
    {
      shape: shape,
      path: path,
      sideOrientation: MeshBuilder.DOUBLESIDE,
      cap: Mesh.CAP_ALL,
      closeShape: true,
    },
    scene
  );

  // Recalculate normals for proper shading
  applySmoothShading(bentPipe);
  // bentPipe.convertToFlatShadedMesh();

  return bentPipe;
}

function applySmoothShading(mesh) {
  const vertexData = VertexData.ExtractFromMesh(mesh);
  const positions = vertexData.positions;
  const indices = vertexData.indices;
  const normals = new Array(positions.length).fill(0);

  if (!positions || !indices) {
    console.error("Mesh data is missing positions or indices.");
    return;
  }

  VertexData.ComputeNormals(positions, indices, normals);

  vertexData.applyToMesh(mesh, true);
}
