import React from "react";

import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../Page";
import DynamicBackground from "../generic/DynamicBackground";
import PageContainer from "../PageContainer";
import Neon from "../generic/Neon";
import WebM from "../generic/WebM";
import { Link } from "react-router-dom";

const NotFoundPage = (props) => {
  const { t } = useTranslation("page_not_found");

  return (
    <Page>
      <DynamicBackground>
        <PageContainer>
          <Neon text={t("title")} />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
            sx={{
              padding: 3,
            }}
          >
            <WebM name="idle" width={116} height={200} />
            <Stack spacing={2}>
              <Typography>{t("description")}</Typography>
              <Button
                color="inherit"
                variant="contained"
                component={Link}
                to="/"
              >
                {t("return")}
              </Button>
            </Stack>
          </Stack>
        </PageContainer>
      </DynamicBackground>
    </Page>
  );
};

export default NotFoundPage;
