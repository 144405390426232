import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { auth } from "../../firebase/config";
import Avatar from "../../misc/Avatar";
import SignInDialog from "../panels/SignInDialog";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearUser } from "../../redux/userSlice";

const LogButton = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const userData = useSelector((state) => state.user.userData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSignIn, setShowSignIn] = useState(false);
  const { t } = useTranslation(["auth"]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    auth.signOut();
    dispatch(clearUser());
    handleMenuClose();
  };

  return (
    <>
      {!user ? (
        <>
          <Button color="inherit" onClick={() => setShowSignIn(true)}>
            {t("signin")}
          </Button>
          <SignInDialog
            open={showSignIn}
            onClose={() => setShowSignIn(false)}
          />
        </>
      ) : (
        <>
          <IconButton onClick={handleMenuOpen}>
            <Avatar avatarID={userData?.avatarID} size={40} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              key="profile"
              component={Link}
              to="/profile"
              onClick={handleMenuClose}
            >
              {t("my_profile", { ns: "titles" })}
            </MenuItem>
            <MenuItem
              key="my-levels"
              component={Link}
              to={`/levels?user=${userData?.slug}`}
              onClick={handleMenuClose}
            >
              {t("my_levels", { ns: "titles" })}
            </MenuItem>
            <MenuItem
              key="my-machines"
              component={Link}
              to={`/machines?user=${userData?.slug}`}
              onClick={handleMenuClose}
            >
              {t("my_machines", { ns: "titles" })}
            </MenuItem>
            <MenuItem key="sign-out" onClick={handleSignOut}>
              {t("logout")}
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default LogButton;
