import React from "react";
import { Button, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const ShareButton = () => {
  const { t } = useTranslation("component_share_button");
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      enqueueSnackbar(t("copy_success"), { variant: "success" });
    });
  };

  return (
    <Tooltip title={t("copy_tooltip")}>
      <Button
        onClick={copyToClipboard}
        variant="contained"
        startIcon={<FontAwesomeIcon icon={["fas", "link"]} />}
      >
        {t("copy_link")}
      </Button>
    </Tooltip>
  );
};

export default ShareButton;
