import { Vector3 } from "@babylonjs/core";

// behavior in case min > max: return max.
function clamp(num, min=0, max=1) {
  return num >= max ? max : num <= min ? min : num;
}

function smoothstep(x, edge0=0, edge1=1) {
  x = clamp((x - edge0) / (edge1 - edge0));
  return x * x * (3.0 - 2.0 * x);
}

function uniformRandomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

function fModulo(a, b) {
  if (a >= 0)
    return a % b;

  const d = Math.ceil(-a / b);
  return a + d * b;
}

// choose randomly an element of an array.
function sample(array) {
  return array[Math.floor(Math.random() * array.length)];
}

// randomize an array.
function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function equalsWithEpsilon(v1, v2, epsilon=0.0001) {
  return Math.abs(v1 - v2) < epsilon;
}

// cf. https://stackoverflow.com/questions/25582882/javascript-math-random-normal-distribution-gaussian-bell-curve
// note: majority of numbers are choosen bellow +- stdev, its common to have numbers that are twice stdev, its rare but possible to have more.
function gaussianRandom(mean, stdev, rg=Math.random) {
  const u = 1 - rg(); // Converting [0,1) to (0,1]
  const v = rg();
  const z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
  // Transform to the desired mean and standard deviation:
  return z * stdev + mean;
}

function closestEvenNumber(v) {
  return 2 * Math.round(v / 2);
}

function discretizeVector(v, step=1.0) {
  return new Vector3(round(v.x, step), round(v.y, step), round(v.z, step));
}

// ref: https://stackoverflow.com/questions/6137986/javascript-roundoff-number-to-nearest-0-5
function round(value, step=1.0) {
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}

export {
  clamp,
  smoothstep,
  uniformRandomFloat,
  fModulo,
  sample,
  shuffle,
  equalsWithEpsilon,
  gaussianRandom,
  closestEvenNumber,
  discretizeVector,
  round
}
