import {
  Engine,
  Scene,
  RenderTargetTexture,
  HemisphericLight,
  Vector3,
  InstancedMesh,
  Color4,
} from "@babylonjs/core";

class BabylonManager {
  constructor() {
    if (BabylonManager.instance) return BabylonManager.instance;

    // add dummy 3d canvas to help creating babylon
    this.dummyCanvas = document.createElement("canvas");
    this.dummyCanvas.setAttribute("width", 1);
    this.dummyCanvas.setAttribute("height", 1);
    document.body.appendChild(this.dummyCanvas);
    this.dummyCanvas.style.position = "absolute";
    this.dummyCanvas.style.top = 0;
    this.dummyCanvas.style.left = 0;
    this.dummyCanvas.style.zIndex = -1;
    this.dummyCanvas.style.display = "none";

    this.engine = new Engine(this.dummyCanvas, true);
    this.scene = new Scene(this.engine);
    // this.scene.debugLayer.show();

    this.light = new HemisphericLight(
      "light",
      new Vector3(0.2, 1, 0.5),
      this.scene
    );
    this.light.intensity = 1;

    this.renderTargetTexture = null;

    this.renderList = [];
    this.materialCache = new Map();
    this.meshCache = new Map();

    BabylonManager.instance = this;
  }

  show(renderList) {
    this.renderList = renderList;

    // To let the instances feature work here, toggle the visibility of the instances outside the renderList,
    // because when a RTT render list is displayed, all the instances are rendered even if not in the render list.
    renderList.forEach((item) => {
      if (item instanceof InstancedMesh) {
        const parentMesh = item.sourceMesh;
        parentMesh.instances.forEach((instance) => {
          instance.isVisible = renderList.includes(instance);
        });
      }
    });
  }

  _createOrRetrieveRenderTargetTexture(widthInPixels, heightInPixels) {
    if (this.renderTargetTexture?.getRenderWidth() === widthInPixels && this.renderTargetTexture?.getRenderHeight() === heightInPixels )
      return this.renderTargetTexture;

    if (this.renderTargetTexture !== null)
      this.renderTargetTexture.dispose();

    this.renderTargetTexture = new RenderTargetTexture(
      "rtt",
      { width: widthInPixels, height: heightInPixels },
      this.scene,
      {
        samples: 4
      }
    );
    this.renderTargetTexture.clearColor = Color4.FromHexString("0x1F1F1FFF");
    return this.renderTargetTexture;
  }

  render(canvas, camera) {
    // 1. Obtenir les dimensions du canvas en pixels
    const widthInPixels = canvas.width;
    const heightInPixels = canvas.height;

    this.engine.setSize(widthInPixels, heightInPixels);

    // 2. Récupérer ou créer le RenderTargetTexture
    const renderTargetTexture = this._createOrRetrieveRenderTargetTexture(
      widthInPixels,
      heightInPixels
    );

    // Activer le RTT dans la scène pour capturer le rendu
    renderTargetTexture.renderList = this.renderList;
    // console.log("Objects in renderList:", this.renderList.map((m) => m.name));
    renderTargetTexture.activeCamera = camera;
    this.scene.render(); // sinon le sol ne se rend pas!?
    renderTargetTexture.render(); // Render uniquement dans le RTT, sans rendre la scène complète

    // 3. Configurer le rendu
    // Attacher le RTT à la caméra

    const pixelsPromise = renderTargetTexture.readPixels();
    if (pixelsPromise === null) return; // why?
    pixelsPromise.then((pixels) => {
      const context = canvas.getContext("2d");
      if (!context) {
        console.error("Impossible d'obtenir le contexte 2D du canvas.");
        return;
      }

      // Créer une ImageData directement avec les pixels lus
      const imageData = context.createImageData(widthInPixels, heightInPixels);

      // Copie directe des pixels sans inversion
      imageData.data.set(pixels);

      // Dessiner l'image directement sur le canvas
      context.putImageData(imageData, 0, 0);

      // Nettoyage : Détacher le RTT pour éviter tout conflit avec le prochain rendu
      camera.outputRenderTarget = null;
    });
  }
}

const BabylonManagerSingleton = new BabylonManager();
export default BabylonManagerSingleton;
