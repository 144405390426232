import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../Page";
import DynamicBackground from "../generic/DynamicBackground";
import PageContainer from "../PageContainer";
import Neon from "../generic/Neon";
import SignInDialog from "../panels/SignInDialog";

const Restricted = () => {
  const { t } = useTranslation("auth");
  const [showSignIn, setShowSignIn] = useState(false);

  return (
    <Page>
      <DynamicBackground>
        <PageContainer>
          <Neon text={t("restricted")} />
          <Typography>{t("must_be_signedin")}</Typography>
          <Button variant="contained" onClick={() => setShowSignIn(true)}>
            {t("login")}
          </Button>
          <SignInDialog
            open={showSignIn}
            onClose={() => setShowSignIn(false)}
          />
        </PageContainer>
      </DynamicBackground>
    </Page>
  );
};

export default Restricted;
