import { useEffect, useState } from "react";
import { getStorageUrl } from "../../firebase/config";
import Image from "../generic/Image";

const WorldImage = ({ path }) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchUrl = async () => {
      if (!path) {
        setLoading(false);
        setError(true);
        return;
      }
      try {
        const storageUrl = await getStorageUrl("worlds/" + path);
        setUrl(storageUrl);
      } catch (err) {
        console.error("Error fetching storage URL:", err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchUrl();
  }, [path]);

  return (
    <Image
      src={url}
      loading={loading}
      error={error}
      title={path}
    />
  );
};


export default WorldImage;
