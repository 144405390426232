import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const ViewsLabel = ({ attemptCount }) => {
  const { t } = useTranslation("document_card");

  return (
    <Tooltip title={t("views_count", { count: attemptCount })} arrow>
      <Box
        display="flex"
        alignItems="center"
        sx={{ userSelect: "none", color: "text.secondary" }}
      >
        <FontAwesomeIcon icon={["fas", "eye"]} />
        <Typography variant="body2" sx={{ ml: 0.5 }}>
          {attemptCount}
        </Typography>
      </Box>
    </Tooltip>
  );
};

ViewsLabel.propTypes = {
  attemptCount: PropTypes.number.isRequired,
};

export default ViewsLabel;
