import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  withDefault,
} from "use-query-params";
import {
  collection,
  orderBy,
  query,
  where,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../../firebase/config";

import DocumentCard from "./DocumentCard";
import DocumentCardSkeleton from "./DocumentCardSkeleton";
import TagFilter from "../misc/TagFilter";
import Storage from "../../firebase/Storage";

const PAGE_SIZE = 9;

/**
 * Query params: sort, tags, user
 */
const queryConfig = {
  sort: withDefault(StringParam, "newest"),
  tags: withDefault(ArrayParam, []),
  user: StringParam,
};

const DocumentList = ({
  type,
  onLoad,
  syncFiltersWithUrl = true,
  showTitle = true,
}) => {
  const { t } = useTranslation("page_documents");
  const { slug: loggedInUserSlug } =
    useSelector((state) => state.user.userData) || {};

  const user = useSelector((state) => state.user.user);
  const claims = useSelector((state) => state.user.claims);

  // Depending on syncFiltersWithUrl, we manage query params or local state.
  const [queryParams, setQueryParams] = useQueryParams(queryConfig);

  const [localSort, setLocalSort] = useState("newest");
  const [localTags, setLocalTags] = useState([]);
  const [localUser, setLocalUser] = useState(undefined);

  const effectiveSort = syncFiltersWithUrl ? queryParams.sort : localSort;
  const effectiveTags = syncFiltersWithUrl ? queryParams.tags : localTags;
  const effectiveUserParam = syncFiltersWithUrl ? queryParams.user : localUser;

  const updateSort = (newValue) => {
    if (syncFiltersWithUrl) {
      setQueryParams({ sort: newValue });
    } else {
      setLocalSort(newValue);
    }
  };
  const updateTags = (newTags) => {
    if (syncFiltersWithUrl) {
      setQueryParams({ tags: newTags });
    } else {
      setLocalTags(newTags);
    }
  };
  const updateUserParam = (newSlug) => {
    if (syncFiltersWithUrl) {
      setQueryParams({ user: newSlug || undefined });
    } else {
      setLocalUser(newSlug || undefined);
    }
  };

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const lastDocRef = useRef(null);

  const buildQuery = useCallback(
    (reset) => {
      let orderField = "createdAt";
      let orderDir = "desc";
      if (effectiveSort === "oldest") orderDir = "asc";
      else if (effectiveSort === "az") {
        orderField = "name";
        orderDir = "asc";
      } else if (effectiveSort === "za") {
        orderField = "name";
        orderDir = "desc";
      }

      let baseQuery = query(
        collection(firestore, type),
        orderBy(orderField, orderDir),
        limit(PAGE_SIZE)
      );

      // Filter by userSlug if defined
      if (effectiveUserParam) {
        baseQuery = query(
          baseQuery,
          where("userSlug", "==", effectiveUserParam)
        );
        // If slug matches logged-in user, include unpublished; otherwise published only
        if (loggedInUserSlug !== effectiveUserParam) {
          baseQuery = query(baseQuery, where("published", "==", true));
        }
      } else {
        // No user => all published
        baseQuery = query(baseQuery, where("published", "==", true));
      }

      // Filter by tags if any
      if (effectiveTags.length > 0) {
        baseQuery = query(
          baseQuery,
          where("tags", "array-contains-any", effectiveTags)
        );
      }

      if (!reset && lastDocRef.current) {
        baseQuery = query(baseQuery, startAfter(lastDocRef.current));
      }

      return baseQuery;
    },
    [effectiveSort, effectiveTags, effectiveUserParam, type, loggedInUserSlug]
  );

  const fetchDocuments = useCallback(
    async (reset = false) => {
      if (loadingRef.current) return;
      setLoading(true);
      loadingRef.current = true;
      if (reset) {
        setError(null);
        setHasMore(true);
        lastDocRef.current = null;
      }

      try {
        const docsQuery = buildQuery(reset);
        const snap = await getDocs(docsQuery);

        if (snap.empty) {
          setHasMore(false);
          if (reset) setDocuments([]);
        } else {
          const newDocs = snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          lastDocRef.current = snap.docs[snap.docs.length - 1];
          setDocuments((prev) => (reset ? newDocs : [...prev, ...newDocs]));
          if (snap.docs.length < PAGE_SIZE) setHasMore(false);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
        loadingRef.current = false;
      }
    },
    [buildQuery]
  );

  useEffect(() => {
    fetchDocuments(true);
  }, [type, effectiveSort, effectiveTags, effectiveUserParam, fetchDocuments]);

  // Show the checkbox only if no user is set or user matches logged-in user's slug
  const canShowCheckbox =
    !!loggedInUserSlug &&
    (!effectiveUserParam || effectiveUserParam === loggedInUserSlug);

  // Checkbox is checked if the user param equals the logged-in user's slug
  const isMyDocsChecked =
    loggedInUserSlug && effectiveUserParam === loggedInUserSlug;

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      if (loggedInUserSlug) updateUserParam(loggedInUserSlug);
    } else {
      updateUserParam(undefined);
    }
  };

  const renderUserAlert = () => {
    if (!effectiveUserParam) return null;
    if (loggedInUserSlug && effectiveUserParam === loggedInUserSlug) {
      return (
        <Alert
          severity="info"
          sx={{ mb: 2, maxWidth: 600, textAlign: "center" }}
        >
          {t("viewing_my_documents")}
        </Alert>
      );
    }
    return (
      <Alert severity="info" sx={{ mb: 2, maxWidth: 600, textAlign: "center" }}>
        {t("viewing_documents_of_user")} <strong>{effectiveUserParam}</strong>
      </Alert>
    );
  };
  
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {showTitle && (
        <Typography variant="h2" sx={{ mt: 2 }}>
          {t("title_" + type)}
        </Typography>
      )}

      {renderUserAlert()}

      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        m={3}
        gap={2}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="sort-label">{t("sort_by")}</InputLabel>
          <Select
            labelId="sort-label"
            value={effectiveSort}
            label={t("sort_by")}
            onChange={(e) => updateSort(e.target.value)}
          >
            <MenuItem value="newest">{t("created_at_desc")}</MenuItem>
            <MenuItem value="oldest">{t("created_at_asc")}</MenuItem>
            <MenuItem value="az">{t("name_asc")}</MenuItem>
            <MenuItem value="za">{t("name_desc")}</MenuItem>
          </Select>
        </FormControl>

        <TagFilter
          type={type}
          selectedTags={effectiveTags}
          onTagChange={updateTags}
        />

        {canShowCheckbox && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isMyDocsChecked}
                onChange={handleCheckboxChange}
              />
            }
            label={t("show_only_my_docs")}
          />
        )}
      </Box>

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {t("error", { error })}
        </Typography>
      )}

      {!loading && documents.length === 0 && (
        <Alert
          severity="info"
          sx={{ mt: 2, maxWidth: 600, textAlign: "center" }}
        >
          {t("no_documents_found")}
        </Alert>
      )}

      {loading && documents.length === 0 ? (
        <Masonry
          columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          spacing={2}
          sx={{ width: "100%", maxWidth: 1200, m: 2 }}
        >
          {Array.from({ length: 3 }).map((_, i) => (
            <DocumentCardSkeleton key={i} />
          ))}
        </Masonry>
      ) : (
        <>
          <Masonry
            columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            spacing={2}
            sx={{ width: "100%", maxWidth: 1200, mt: 2 }}
          >
            {documents.map((doc) => (
              <DocumentCard
                key={doc.id}
                doc={doc}
                type={type}
                onLoad={onLoad}
                showLoadButton={onLoad !== undefined}
                showPlayButton={onLoad === undefined}
                showEditButton={onLoad === undefined && type===Storage.TYPE.LEVEL && claims?.editor && user.uid === doc.uid}
                showInfoButton={onLoad === undefined}
              />
            ))}
            {loading &&
              documents.length > 0 &&
              Array.from({ length: 2 }).map((_, idx) => (
                <DocumentCardSkeleton key={`skeleton-${idx}`} />
              ))}
          </Masonry>

          {hasMore && !loading && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => fetchDocuments(false)}
              sx={{ mt: 2 }}
            >
              {t("load_more")}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

DocumentList.propTypes = {
  type: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  syncFiltersWithUrl: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default DocumentList;
