import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Tooltip, Box, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { AvatarGenerator } from "../lib/avatar";

const Avatar = memo(({ avatarID = null, displayName = "", profileLink = null, size }) => {
  const canvasRef = useRef(null);
  const generatorRef = useRef(null);

  useEffect(() => {
    if (!avatarID || !canvasRef.current) return;

    // Create generator instance
    generatorRef.current = new AvatarGenerator(avatarID, size);

    // Generate avatar data and render
    const avatarData = generatorRef.current.generateAvatarData();
    generatorRef.current.renderAvatar(canvasRef.current, avatarData);

    return () => {
      // Cleanup the generator instance
      if (generatorRef.current) {
        generatorRef.current.destroy();
        generatorRef.current = null;
      }
    };
  }, [avatarID, size]);

  const avatarContent = (
    <Box
      component="div"
      sx={{
        width: size,
        height: size,
        position: "relative",
        "&:hover canvas": {
          cursor: profileLink ? "pointer" : "default",
          transform: profileLink ? "scale(1.2)" : "none",
        },
      }}
    >
      {!avatarID ? (
        <Skeleton variant="circular" width={size} height={size} />
      ) : (
        <canvas
          ref={canvasRef}
          width={size}
          height={size}
          style={{
            display: "block",
          }}
        />
      )}
    </Box>
  );

  if (!avatarID) {
    return <Skeleton variant="circular" width={size} height={size} />;
  }

  return (
    <Tooltip title={displayName || ""} arrow>
      {profileLink ? (
        <Box
          component={Link}
          to={`/user/${profileLink}`}
          sx={{ textDecoration: "none" }}
        >
          {avatarContent}
        </Box>
      ) : (
        avatarContent
      )}
    </Tooltip>
  );
});

Avatar.propTypes = {
  avatarID: PropTypes.string,
  displayName: PropTypes.string,
  size: PropTypes.number.isRequired,
  profileLink: PropTypes.string,
};

export default Avatar;
