import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Tooltip, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { toggleLikeForDocument } from "../../redux/userSlice";

const LikeButton = ({ likeCount, type, documentID }) => {
  const { t } = useTranslation("document_card");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector((state) => state.user.user);
  const likes = useSelector((state) => state.user.likes);

  const [loading, setLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [displayedLikeCount, setDisplayedLikeCount] = useState(likeCount);

  useEffect(() => {
    if (!user) {
      setIsLiked(false);
    } else {
      const userLikes =
        type === "machines" ? likes.likesMachines : likes.likesLevels;
      setIsLiked(userLikes?.includes(documentID));
    }
    setDisplayedLikeCount(likeCount);
  }, [user, likes, type, documentID, likeCount]);

  const handleToggleLike = useCallback(async () => {
    if (!user) {
      enqueueSnackbar(t("must_be_signedin", {ns: "auth"}), { variant: "info" });
      return;
    }

    if (loading) return;

    setLoading(true);

    // Gestion optimiste
    const oldLikeCount = displayedLikeCount;
    const oldIsLiked = isLiked;
    const newLikeCount = isLiked
      ? displayedLikeCount - 1
      : displayedLikeCount + 1;
    setDisplayedLikeCount(newLikeCount);
    setIsLiked(!isLiked);

    try {
      const action = await dispatch(
        toggleLikeForDocument({ documentID, documentType: type })
      );

      if (action.type.endsWith("fulfilled")) {
        setDisplayedLikeCount(action.payload.likeCount);
        setIsLiked(action.payload.isLiked);
      }
      if (action.type.endsWith("rejected")) {
        console.warn("Action rejected, reverting changes.");
        setDisplayedLikeCount(oldLikeCount);
        setIsLiked(oldIsLiked);
      }
    } catch (error) {
      console.error("An error occurred while toggling like:", error);
      setDisplayedLikeCount(oldLikeCount);
      setIsLiked(oldIsLiked);
    } finally {
      setLoading(false);
    }
  }, [
    user,
    loading,
    isLiked,
    displayedLikeCount,
    dispatch,
    documentID,
    type,
    enqueueSnackbar,
    t,
  ]);

  return (
    <Tooltip title={t("likes_count", { count: displayedLikeCount })} arrow>
      <Box
        display="flex"
        alignItems="center"
        role="button"
        aria-pressed={isLiked}
        aria-label={t("likes_count", { count: displayedLikeCount })}
        sx={{
          cursor: loading ? "not-allowed" : "pointer",
          color: isLiked ? "primary.main" : "text.secondary",
          userSelect: "none",
        }}
        onClick={handleToggleLike}
      >
        {loading ? (
          <CircularProgress size={16} />
        ) : (
          <FontAwesomeIcon icon={["fas", "thumbs-up"]} />
        )}
        &nbsp;{displayedLikeCount}
      </Box>
    </Tooltip>
  );
};

LikeButton.propTypes = {
  likeCount: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["machines", "levels"]).isRequired,
  documentID: PropTypes.string.isRequired,
};

export default LikeButton;
