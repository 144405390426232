import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const AutomaticTranslation = ({
  translation,
  originalText,
  typographyProps = {},
  to,
}) => {
  const { i18n, t } = useTranslation("translation");
  const targetLanguage = i18n.language;

  // Check if a translation exists for the target language
  const translatedText = translation?.[targetLanguage] || originalText;
  const isTranslated = !!translation?.[targetLanguage];

  // If no translation is available, render without a tooltip (fail silently)
  if (!isTranslated) {
    return (
      <Box display="flex" alignItems="center">
        <Typography
          {...typographyProps}
          sx={{
            cursor: "default", // No pointer cursor if translation is not available
            ...typographyProps.sx, // Merge additional styles
          }}
        >
          {originalText}
        </Typography>
      </Box>
    );
  }

  // If no translation is available, render without a tooltip (fail silently)
  const content = (
    <Typography
      {...typographyProps}
      sx={{
        cursor: isTranslated ? "pointer" : "default",
        ...typographyProps.sx, // Merge additional styles
      }}
    >
      {translatedText}
    </Typography>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      title={isTranslated ? t("automatic_translation_tooltip", { original: originalText }) : ""}
      sx={{userSelect: "none"}}
    >
      {to ? (
        <a href={to} style={{ textDecoration: "none", color: "inherit" }}>
          {content}
        </a>
      ) : (
        content
      )}
    </Box>
  );
};

AutomaticTranslation.propTypes = {
  translation: PropTypes.object, // Expected format: { fr: "text", en: "text" }
  originalText: PropTypes.string.isRequired, // Original text to display if no translation is available
  typographyProps: PropTypes.object, // Props for the MUI Typography component
  to: PropTypes.string, // Optional hyperlink for the translated text
};

export default AutomaticTranslation;
