import Zdog, { TAU } from "zdog";
import alea from "alea";

const OFFSET = 7;

export class AvatarGenerator {
  constructor(avatarID, size) {
    this.avatarID = avatarID;
    this.size = size;
    this.random = alea(avatarID); // Seeded random generator
    this.illo = null;
    this.mousePos = { x: 0, y: 0 }; // Mouse position relative to canvas
    this.avatarData = null; // Generated avatar data
  }

  // Generate avatar data
  generateAvatarData() {
    return {
      primaryColor: this.generateRandomHSVColor(100, 100),
      secondaryColor: this.generateRandomHSVColor(60, 80),
      backgroundColor: this.generateRandomHSVColor(40, 60),
      darkColor: this.getRandomArrayElement(["#000", "#222", "#444"]),

      // backgroundSize: this.getRandomInRange(45, 55),
      // backgroundSides: this.getRandomInRange(5, 12),

      headSize: this.getRandomInRange(30, 50),
      headStroke: this.getRandomInRange(5, 20),
      headColorContrast: this.getRandomInRange(-20, 20),
      headSmallerAxis: this.getRandomArrayElement(["width", "height", "depth"]),
      headSmallerAxisRatio: this.getRandomFloat(0.8, 1.0),

      eyeHeight: this.getRandomInRange(0, 10),
      eyeSize: this.getRandomInRange(8, 15),
      eyeGap: this.getRandomInRange(20, 30),
      eyeShape: this.getRandomArrayElement(["circle", "square"]),
      hasEyeReflection: this.getRandomBoolean(),
      hasEyeHalo: this.getRandomBoolean(),

      mouthType: this.getRandomArrayElement([
        "none",
        "smile",
        "line",
        "circle",
        "rectangle",
      ]),
      mouthWidth: this.getRandomInRange(10, 40),
      mouthHeight: this.getRandomInRange(5, 10),
      mouthElevation: this.getRandomInRange(12, 16),

      earType: this.getRandomArrayElement(["none", "circle", "square"]),
      earYRotation: this.getRandomArrayElement([0, TAU / 4]),
      earSize: this.getRandomInRange(5, 20),
      earFilled: this.getRandomBoolean(),

      hasAntenna: this.getRandomBoolean(),
      antennaEndsWithBall: this.getRandomBoolean(),
      antennaLength: this.getRandomInRange(15, 20),
      antennaBallSize: this.getRandomInRange(5, 10),
    };
  }

  // Render the avatar on the canvas
  renderAvatar(canvasElement, avatarData) {
    // Cleanup any existing illustration
    if (this.illo) this.illo.remove();

    // Create a new Zdog illustration
    this.illo = new Zdog.Illustration({
      element: canvasElement,
      zoom: this.size / 100,
      rotate: { x: -Zdog.TAU / 16, y: Zdog.TAU / 16 },
      // onPrerender: (ctx) => this.renderBackground(ctx, avatarData),
    });

    this.firstMove = true;

    this.createHead(avatarData);
    this.createEyes(avatarData);
    this.createMouth(avatarData);
    this.createEars(avatarData);
    this.createAntenna(avatarData);

    // Mouse interaction
    canvasElement.addEventListener("mousemove", (event) => {
      if (this.firstMove) {
        if (this.illo) {
          this.illo.rotate.x = 0;
          this.illo.rotate.y = 0;
        }
        this.firstMove = false;
      }

      const rect = canvasElement.getBoundingClientRect();
      const canvasX = event.clientX - rect.left - rect.width / 2;
      const canvasY = event.clientY - rect.top - rect.height / 2;
      this.mousePos = { x: canvasX / rect.width, y: canvasY / rect.height };
    });

    // Animation loop
    const animate = () => {
      if (!this.illo) return;

      this.head.rotate.y = -this.mousePos.x * Zdog.TAU * 0.1; // Horizontal rotation
      this.head.rotate.x = -this.mousePos.y * Zdog.TAU * 0.1; // Vertical rotation
      this.illo.updateRenderGraph();
      requestAnimationFrame(animate);
    };

    animate();
  }

  // renderBackground(ctx, avatarData) {
  //   const { backgroundSize, backgroundSides, secondaryColor } = avatarData;

  //   ctx.fillStyle = this.adjustBrightness(secondaryColor, -50);
  //   ctx.beginPath();

  //   const spikes = backgroundSides;
  //   const outerRadius = backgroundSize;
  //   const innerRadius = backgroundSize / 2;

  //   for (let i = 0; i < spikes; i++) {
  //     const outerAngle = (Math.PI * 2 * i) / spikes;
  //     const innerAngle = outerAngle + Math.PI / spikes;

  //     const outerX = Math.cos(outerAngle) * outerRadius;
  //     const outerY = Math.sin(outerAngle) * outerRadius;
  //     ctx.lineTo(outerX, outerY);

  //     const innerX = Math.cos(innerAngle) * innerRadius;
  //     const innerY = Math.sin(innerAngle) * innerRadius;
  //     ctx.lineTo(innerX, innerY);
  //   }

  //   ctx.closePath();
  //   ctx.fill();
  // }

  createHead(avatarData) {
    const backgroundColorVaration1 = this.adjustBrightness(
      avatarData.backgroundColor,
      avatarData.headColorContrast
    );
    const backgroundColorVaration2 = this.adjustBrightness(
      avatarData.backgroundColor,
      -avatarData.headColorContrast
    );

    const headProps = {
      addTo: this.illo,
      width: avatarData.headSize,
      height: avatarData.headSize,
      depth: avatarData.headSize,
      color: avatarData.backgroundColor,
      stroke: avatarData.headStroke,
      topFace: backgroundColorVaration1,
      leftFace: backgroundColorVaration2,
      rightFace: backgroundColorVaration2,
    };
    headProps[avatarData.headSmallerAxis] =
      headProps[avatarData.headSmallerAxis] * avatarData.headSmallerAxisRatio;

    this.head = new Zdog.Box(headProps);
  }

  createEyes(avatarData) {
    const eyeProps = {
      addTo: this.head,
      color: avatarData.darkColor,
      fill: true,
      stroke: 3,
      translate: {
        y: -avatarData.eyeHeight,
        z: avatarData.headSize / 2 + OFFSET,
      },
    };

    const leftEye =
      avatarData.eyeShape === "circle"
        ? new Zdog.Ellipse({ ...eyeProps, diameter: avatarData.eyeSize })
        : new Zdog.Rect({
            ...eyeProps,
            width: avatarData.eyeSize,
            height: avatarData.eyeSize,
          });

    const rightEye = leftEye.copy();
    rightEye.translate.x = avatarData.eyeGap / 2;
    leftEye.translate.x = -avatarData.eyeGap / 2;

    if (avatarData.hasEyeHalo) {
      const leftEyeHalo = leftEye.copy();
      leftEyeHalo.translate.z += 1;
      leftEyeHalo.fill = false;
      leftEyeHalo.color = avatarData.primaryColor;
      const rightEyeHalo = rightEye.copy();
      rightEyeHalo.translate.z += 1;
      rightEyeHalo.fill = false;
      rightEyeHalo.color = avatarData.primaryColor;

      leftEye.color = "#fff";
      rightEye.color = "#fff";
      leftEye.stroke = false;
      rightEye.stroke = false;
    }

    if (avatarData.hasEyeReflection && !avatarData.hasEyeHalo) {
      const reflection = {
        color: "#FFF",
        fill: true,
        diameter: avatarData.eyeSize / 4,
      };
      const leftReflection = new Zdog.Ellipse({
        ...reflection,
        addTo: leftEye,
      });
      leftReflection.translate.set({
        x: 2,
        y: -2,
        z: 2,
      });
      const rightReflection = new Zdog.Ellipse({
        ...reflection,
        addTo: rightEye,
      });
      rightReflection.translate.set({
        x: 2,
        y: -2,
        z: 2,
      });
    }
  }

  createMouth(avatarData) {
    const {
      mouthType,
      mouthWidth,
      mouthHeight,
      mouthElevation,
      darkColor,
      headSize,
    } = avatarData;

    // No mouth
    if (mouthType === "none") {
      return;
    }

    const offset = headSize / 2 + OFFSET;

    switch (mouthType) {
      case "smile":
        new Zdog.Ellipse({
          addTo: this.head,
          width: mouthHeight,
          height: mouthWidth,
          quarters: 2,
          translate: { y: mouthElevation, z: offset },
          rotate: { z: Zdog.TAU / 4 },
          stroke: 2,
          color: darkColor,
          fill: false,
        });
        break;

      case "line":
        new Zdog.Rect({
          addTo: this.head,
          width: mouthWidth,
          height: 1, // Line mouth is essentially flat
          translate: { y: mouthElevation, z: offset },
          stroke: 2,
          color: darkColor,
          fill: true,
        });
        break;

      case "circle":
        new Zdog.Ellipse({
          addTo: this.head,
          width: mouthWidth,
          height: mouthHeight,
          translate: { y: mouthElevation, z: offset },
          stroke: 2,
          color: darkColor,
          fill: true,
        });
        break;

      case "rectangle":
        new Zdog.Rect({
          addTo: this.head,
          width: mouthWidth,
          height: mouthHeight,
          translate: { y: mouthElevation, z: offset },
          stroke: 2,
          color: darkColor,
          fill: true,
        });
        break;

      default:
        console.warn(`Unknown mouth type: ${mouthType}`);
    }
  }

  createEars(avatarData) {
    const {
      earType,
      earYRotation,
      earSize,
      earFilled,
      secondaryColor,
      headSize,
    } = avatarData;

    if (earType === "none") return;

    const earStroke = earFilled ? false : 2; // Stroke width for non-filled ears
    const earOffsetX = headSize / 2 + earSize / 2 + OFFSET; // Position ears on the sides of the head

    // Helper function to create a single ear
    const createEar = (xOffset) => {
      if (earType === "circle") {
        new Zdog.Ellipse({
          addTo: this.head,
          diameter: earSize,
          translate: { x: xOffset, z: 0 },
          rotate: { y: earYRotation },
          stroke: earStroke,
          color: secondaryColor,
          fill: earFilled,
        });
      } else if (earType === "square") {
        new Zdog.Rect({
          addTo: this.head,
          width: earSize,
          height: earSize,
          translate: { x: xOffset, z: 0 },
          rotate: { y: earYRotation },
          stroke: earStroke,
          color: secondaryColor,
          fill: earFilled,
        });
      } else {
        console.warn(`Unknown ear type: ${earType}`);
      }
    };

    createEar(-earOffsetX);
    createEar(earOffsetX);
  }

  createAntenna(avatarData) {
    const {
      hasAntenna,
      antennaEndsWithBall,
      antennaLength,
      antennaBallSize,
      darkColor,
      secondaryColor,
      headSize,
    } = avatarData;

    // No antenna
    if (!hasAntenna) {
      return;
    }

    const TAU = Zdog.TAU;

    // Calculate position and orientation of the antenna
    const antennaBaseOffset = headSize / 2; // Place the antenna on top of the head

    // Create the antenna (cylinder)
    new Zdog.Cylinder({
      addTo: this.head,
      diameter: 3, // Fixed diameter for antenna
      length: antennaLength,
      translate: { y: -antennaBaseOffset - antennaLength / 2 },
      rotate: { x: TAU / 4 },
      color: darkColor,
      stroke: false,
    });

    // Add ball at the end of the antenna, if specified
    if (antennaEndsWithBall) {
      new Zdog.Ellipse({
        addTo: this.head,
        diameter: antennaBallSize,
        translate: { y: -antennaBaseOffset - antennaLength },
        color: secondaryColor,
        stroke: false,
        fill: true,
      });
    }
  }

  getRandomFloat(min, max) {
    return this.random() * (max - min) + min;
  }

  getRandomInRange(min, max) {
    return Math.floor(this.random() * (max - min + 1)) + min;
  }

  getRandomBoolean() {
    return this.random() > 0.5;
  }

  getRandomArrayElement(array) {
    return array[Math.floor(this.random() * array.length)];
  }

  generateRandomHSVColor(saturation, value) {
    const hue = Math.floor(this.random() * 360); // Random hue
    return hsvToHex(hue, saturation, value);
  }

  adjustSaturation(hexColor, adjustment) {
    const { h, s, v } = hexToHsv(hexColor);
    const newSaturation = Math.min(100, Math.max(0, s + adjustment)); // Clamp to [0, 100]
    return hsvToHex(h, newSaturation, v);
  }

  adjustBrightness(hexColor, adjustment) {
    const { h, s, v } = hexToHsv(hexColor);
    const newValue = Math.min(100, Math.max(0, v + adjustment)); // Clamp to [0, 100]
    return hsvToHex(h, s, newValue);
  }

  destroy() {
    if (this.illo?.element) {
      this.illo.element.removeEventListener("mousemove", this.mouseMoveHandler);
    }
    if (this.illo) {
      this.illo.remove();
      this.illo = null;
    }
    this.mousePos = { x: 0, y: 0 };
    this.firstMove = true;
  }
}

function hexToHsv(hex) {
  const rgb = hexToRgb(hex);
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;
  let h = 0;
  if (delta === 0) {
    h = 0;
  } else if (max === r) {
    h = ((g - b) / delta) % 6;
  } else if (max === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }
  h = Math.round(h * 60);
  if (h < 0) h += 360;
  const s = max === 0 ? 0 : (delta / max) * 100;
  const v = max * 100;
  return { h, s: Math.round(s), v: Math.round(v) };
}

function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
}

function hsvToHex(h, s, v) {
  s /= 100;
  v /= 100;
  const k = (n) => (n + h / 60) % 6;
  const f = (n) => v - v * s * Math.max(Math.min(k(n), 4 - k(n), 1), 0);
  const toHex = (x) =>
    Math.round(x * 255)
      .toString(16)
      .padStart(2, "0");
  return `#${toHex(f(5))}${toHex(f(3))}${toHex(f(1))}`;
}
