import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Skeleton,
} from "@mui/material";

const DocumentCardSkeleton = () => {
  return (
    <Card
      sx={{
        m: 0.5,
        minWidth: 200,
        maxWidth: 300,
        userSelect: "none",
        textDecoration: "none",
        cursor: "auto",
      }}
    >
      <CardMedia>
        <Skeleton variant="rectangular" height={140} />
      </CardMedia>
      <CardActions
        sx={{
          justifyContent: "space-between",
          padding: "8px 16px",
        }}
      >
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width={50} height={20} />
      </CardActions>
      <CardContent>
        <Skeleton variant="text" width="80%" height={24} />
        <Skeleton variant="text" width="60%" height={18} sx={{ mt: 1 }} />
        <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
          <Skeleton variant="rounded" width={60} height={24} />
          <Skeleton variant="rounded" width={60} height={24} />
        </Box>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "space-between",
          padding: "8px 16px",
        }}
      >
        <Skeleton variant="text" width={40} height={20} />
        <Skeleton variant="text" width={40} height={20} />
      </CardActions>
    </Card>
  );
};

export default DocumentCardSkeleton;
