import Page from "./Page";
import PageContainer from "./PageContainer";
import DocumentList from "./panels/DocumentList";

const DocumentsPage = ({ type }) => {
  return (
    <Page>
      <PageContainer>
        <DocumentList type={type} />
      </PageContainer>
    </Page>
  );
};

export default DocumentsPage;
