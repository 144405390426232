import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { firestore } from "../../firebase/config";
import {
  doc,
  updateDoc,
  deleteDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";

import Select from "react-select";
import Tags from "../../firebase/tags";
import Page from "../Page";
import PageContainer from "../PageContainer";
import Storage from "../../firebase/Storage";
import { slugify } from "../../engine/core/lib/slugify";
import DocumentSummary from "../misc/DocumentSummary";
import WorldSummary from "../misc/WorldSummary";
import CompressedPreview from "../panels/CompressedPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Results from "../document_parts/Results";
import LikeButton from "../document_parts/LikeButton";
import AttemptLabel from "../document_parts/AttemptLabel";
import { formatDate } from "../../lib/date";
import ShareButton from "../misc/ShareButton";
import MiniMarkdownRenderer from "../misc/MiniMarkdownRenderer";
import { enqueueSnackbar } from "notistack";

const DocumentPage = ({ type }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("page_document");

  // Redux selectors
  const user = useSelector((state) => state.user.user);
  const claims = useSelector((state) => state.user.claims);

  // Local states
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  const [currentName, setCurrentName] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [isPublished, setIsPublished] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  useEffect(() => {
    const fetchDocumentBySlug = async () => {
      setLoading(true);
      setError(null);

      try {
        const q = query(collection(firestore, type), where("slug", "==", slug));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          const docData = { id: docSnap.id, ...docSnap.data() };
          
          setDocument(docData);
          setCurrentName(docData.name);
          setCurrentDescription(docData.description);
          setSelectedTags(Tags.arrayToOptions(docData.tags || [], t));
          setIsPublished(docData.published || false);

          setIsOwner(user?.uid === docData.uid);
        } else {
          setError(t("not_found"));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentBySlug();
  }, [slug, type, user?.uid, t]);

  // Validation functions
  const isValidName = (name) => name.trim().length > 0 && name.length <= 20;
  const isValidDescription = (description) => description.length <= 512;

  const validateFields = () => {
    let valid = true;

    if (!isValidName(currentName)) {
      setNameError(t("name_invalid"));
      valid = false;
    } else {
      setNameError("");
    }

    if (!isValidDescription(currentDescription)) {
      setDescriptionError(t("description_invalid"));
      valid = false;
    } else {
      setDescriptionError("");
    }

    return valid;
  };

  const handleFieldChange = (setter) => (e) => {
    const value = e.target.value;
    setter(value);
    setIsSaveEnabled(true);
  };

  const handleSave = async () => {
    if (!validateFields()) return;

    try {
      const updates = {};
      let newSlug = undefined;

      if (currentName !== document.name) {
        updates.name = currentName;
        newSlug = slugify(currentName, document.id);
      }
      if (currentDescription !== document.description)
        updates.description = currentDescription;
      if (
        JSON.stringify(Tags.optionsToArray(selectedTags)) !==
        JSON.stringify(document.tags)
      ) {
        updates.tags = Tags.optionsToArray(selectedTags);
      }
      if (isPublished !== document.published) updates.published = isPublished;

      if (Object.keys(updates).length === 0) return;

      const docRef = doc(firestore, type, document.id);

      // Attendre la mise à jour et s'assurer qu'elle réussit avant de naviguer
      await updateDoc(docRef, updates).then(() => {
        // Met à jour l'état local du document après une sauvegarde réussie
        setDocument((prev) => ({ ...prev, ...updates }));
        setIsSaveEnabled(false);

        if (newSlug) {
          navigate(`/${Storage.typeString(type, false, false)}/${newSlug}`, {
            replace: true,
          });
        }
      });
    } catch (err) {
      console.error("Error updating document:", err);
    }
  };

  const handleDelete = async () => {
    if (!document) return;

    try {
      const docRef = doc(firestore, type, document.id);
      await deleteDoc(docRef);
      navigate("/home");
    } catch (err) {
      console.error("Error deleting document: ", err);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  if (loading) return <p>{t("loading")}</p>;
  if (error) return <p>{error}</p>;

  const onChangeLevel = (levelSlug) => {
    navigate(`/level/${levelSlug}`, { replace: true });
  };

  const handlePlay = () => {
    if (user)
      navigate(
        `/factory?${Storage.typeToGETParameterName(type)}=${document.slug}`
      );
    else
      enqueueSnackbar(t("must_be_signedin", { ns: "auth" }), {
        variant: "info",
      });
  };
  const handleEdit = () => {
    console.log(claims);
    console.log(document);
    console.log(user);
    
    if (user && claims?.editor && document.uid === user.uid)
      navigate(`/editor?${Storage.typeToGETParameterName(type)}=${slug}`);
    else
      enqueueSnackbar(t("must_be_signedin", { ns: "auth" }), {
        variant: "info",
      });
  };

  return (
    <Page>
      <PageContainer>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DocumentSummary
            type={type}
            slug={document.slug}
            userSlug={document.userSlug}
            userAvatarID={document.userAvatarID}
            userDisplayName={document.userDisplayName}
            name={document.name}
            published={document.published}
            translation={document.translation}
            small={false}
          />

          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="caption">
              {t("created_at", {
                date: formatDate(document.createdAt, i18n.language),
              })}
            </Typography>
            <Typography variant="caption">
              {t("modified_at", {
                date: formatDate(document.modifiedAt, i18n.language),
              })}
            </Typography>
            <Typography variant="caption">
              v{document.version}{" "}
              <span style={{ color: "#888" }}>- {document.id}</span>
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            m: 2,
          }}
        >
          <CompressedPreview
            data={document.data}
            type={type}
            width={400}
            height={300}
            centerOverlay={
              <Tooltip title={t("play")}>
                <IconButton
                  disableRipple
                  onClick={handlePlay}
                  sx={{
                    opacity: 0.6,
                    transition: "opacity 0.3s",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  {" "}
                  <FontAwesomeIcon icon={["fas", "play"]} size="2x" />
                </IconButton>
              </Tooltip>
            }
          />
          <Card sx={{ minWidth: "50%" }}>
            <CardContent>
              <Stack
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {document.worldSlug && (
                  <WorldSummary
                    worldSlug={document.worldSlug}
                    previousLevelSlug={document.previousLevelSlug}
                    nextLevelSlug={document.nextLevelSlug}
                    onChangeLevel={onChangeLevel}
                  />
                )}

                {document.tags && (
                  <Stack direction="row">
                    {document.tags.map((tag) => (
                      <Chip
                        key={tag}
                        label={t(tag, { ns: "tags" })}
                        // variant="outlined"
                        sx={{
                          marginRight: 0.5,
                          marginBottom: 0.5,
                          userSelect: "none",
                        }}
                      />
                    ))}
                  </Stack>
                )}

                <Results levelID={document.id} />

                <Stack direction="row" spacing={2} alignItems="center">
                  <LikeButton
                    likeCount={document.likeCount || 0}
                    type={type}
                    documentID={document.id}
                  />
                  <AttemptLabel attemptCount={document.attemptCount || 0} />
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                  <ShareButton
                    type={type}
                    name={document.translation.name[i18n.language]}
                  />

                  {(claims?.editor && document.uid === user.uid) && (
                    <Tooltip disableRipple title={t("edit")}>
                      <IconButton color="primary" onClick={handleEdit}>
                        <FontAwesomeIcon icon={["fas", "pen-to-square"]} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>

        <Card sx={{ m: 2, overflow: "visible" }}>
          <CardHeader title={t("description")} />
          <CardContent>
            {document.description ? (
              <MiniMarkdownRenderer
                translation={document.translation?.description}
              >
                {document.description}
              </MiniMarkdownRenderer>
            ) : (
              <Typography>{t("no_description")}</Typography>
            )}
          </CardContent>
        </Card>

        {isOwner && (
          <Card
            sx={{ m: 2, border: "1px solid lightgray", overflow: "visible" }}
          >
            <CardHeader
              title={
                type === Storage.TYPE.LEVEL
                  ? t("edit_level_info")
                  : t("edit_machine_info")
              }
            />
            <CardContent>
              <Stack spacing={2} p={2}>
                <TextField
                  label={t("name")}
                  value={currentName}
                  onChange={handleFieldChange(setCurrentName)}
                  error={!!nameError}
                  helperText={nameError}
                  fullWidth
                />
                <TextField
                  label={t("description")}
                  value={currentDescription}
                  onChange={handleFieldChange(setCurrentDescription)}
                  error={!!descriptionError}
                  helperText={descriptionError}
                  fullWidth
                  multiline
                  rows={6}
                />
                <Select
                  isMulti
                  value={selectedTags}
                  onChange={(options) => {
                    setSelectedTags(options);
                    setIsSaveEnabled(true);
                  }}
                  options={Tags.getSuggestionsByType(type, t)}
                  styles={Tags.getSelectStyles()}
                />
                {claims?.publisher && (
                  <Button
                    variant="outlined"
                    color={isPublished ? "success" : "warning"}
                    onClick={() => {
                      setIsPublished((prev) => !prev);
                      setIsSaveEnabled(true);
                    }}
                  >
                    {isPublished
                      ? t("published", { ns: "keywords" })
                      : t("unpublished", { ns: "keywords" })}
                  </Button>
                )}
              </Stack>
            </CardContent>
            <CardActions sx={{ justifyContent: "end" }}>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!isSaveEnabled}
              >
                {t("save", { ns: "keywords" })}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setOpenDeleteDialog(true)}
              >
                {t("delete")}
              </Button>
            </CardActions>
          </Card>
        )}

        {/* Confirmation de suppression */}
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>{t("confirm_delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("delete_warning")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)}>
              {t("cancel")}
            </Button>
            <Button onClick={handleDelete} color="error">
              {t("delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </PageContainer>
    </Page>
  );
};

export default DocumentPage;
