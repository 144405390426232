import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Skeleton, Box } from "@mui/material";
import pako from "pako";
import BabylonPreviewRenderer from "./BabylonPreviewRenderer";

const CompressedPreview = ({
  data,
  type,
  width = 300,
  height = 200,
  centerOverlay,
  topRightOverlay,
  bottomRightOverlay,
  topLeftOverlay,
  bottomLeftOverlay,
}) => {
  const [decompressedData, setDecompressedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!data) {
      setError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(false);

    try {
      // Decompress data
      const compressedBuffer = Uint8Array.from(atob(data), (c) =>
        c.charCodeAt(0)
      );
      const decompressedBuffer = pako.inflate(compressedBuffer);
      const jsonData = JSON.parse(new TextDecoder().decode(decompressedBuffer));
      setDecompressedData(jsonData);
    } catch (error) {
      console.error("Error decompressing data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [data]);

  if (loading || error) {
    return (
      <Skeleton
        variant="rectangular"
        animation={loading ? "wave" : false}
        width={width}
        height={height}
      />
    );
  }

  return (
    <Box position="relative" width="100%" height="100%">
      <BabylonPreviewRenderer
        data={decompressedData}
        type={type}
        width={width}
        height={height}
      />
      {centerOverlay && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          {centerOverlay}
        </Box>
      )}
      {topRightOverlay && (
        <Box position="absolute" top={0} right={0}>
          {topRightOverlay}
        </Box>
      )}
      {bottomRightOverlay && (
        <Box position="absolute" bottom={0} right={0}>
          {bottomRightOverlay}
        </Box>
      )}
      {topLeftOverlay && (
        <Box position="absolute" top={0} left={0}>
          {topLeftOverlay}
        </Box>
      )}
      {bottomLeftOverlay && (
        <Box position="absolute" bottom={0} left={0}>
          {bottomLeftOverlay}
        </Box>
      )}
    </Box>
  );
};

CompressedPreview.propTypes = {
  data: PropTypes.string.isRequired, // Compressed JSON
  type: PropTypes.oneOf(["machines", "levels"]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  centerOverlay: PropTypes.element,
  topRightOverlay: PropTypes.element,
  bottomRightOverlay: PropTypes.element,
  topLeftOverlay: PropTypes.element,
  bottomLeftOverlay: PropTypes.element,
};

export default CompressedPreview;
