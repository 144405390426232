export const PI = 3.141592654;
export const Color = {
  gold: "#FFD700",
  carbon: "#625D5D",
  environment: "#15B5B2",

  white: "#FFFFFF",
  yellow: "#F4C30F",
  orange: "#FB6E03",
  red: "#F61D11",
  pink: "#F74EEC",
  lightGreen: "#3CEA19",
  darkGreen: "#1F5608",
  turquois: "#00FFE5",
  lightBlue: "#4088E6",
  darkBlue: "#0E0E96",
  brown: "#722F08",
  black: "#333333",
  grey: "#7B7B7B",
};

export const Material = {
  plastic: "plastic",
  carbon: "carbon",
  metal: "metal",
  rubber: "rubber",
  tile: "tile",
  shiny: "shiny",
};

export const preview_constants = {
  machine: {
    root: true,
    shapes: [
      {
        type: "Box",
        dimension: [1, 1, 1],
        color: Color.gold,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 0.5, 0],
      },
      "+z": {
        translation: [0, 0, 0.5],
        rotation: [PI, -PI / 2, -PI / 2],
      },
      "-z": {
        translation: [0, 0, -0.5],
        rotation: [PI, PI / 2, -PI / 2],
      },
      "+x": {
        translation: [0.5, 0, 0],
        rotation: [PI, 0, -PI / 2],
      },
      "-x": {
        translation: [-0.5, 0, 0],
        rotation: [0, 0, PI / 2],
      },
      "-y": {
        translation: [0, -0.5, 0],
        rotation: [PI, 0, 0],
      },
    },
  },
  cockpit: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.75, 0],
        dimension: [1.0, 1.5, 1.0],
        color: Color.black,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 1.5, 0],
        rotation: [0, 0, PI / 2],
        radius: 0.5,
        height: 0.99,
        color: Color.black,
        material: Material.metal,
      },
    ],
  },
  engineer: {
    root: true,
    shapes: [
      // Head
      {
        type: "Capsule",
        radius: 0.4,
        height: 1,
        translation: [0, 1.1, 0],
        rotation: [0, 0, PI / 2],
        color: Color.white,
        material: Material.metal,
      },
      // Head visor
      {
        type: "Capsule",
        radius: 0.3,
        height: 1,
        translation: [0, 1.1, 0.15],
        rotation: [0, 0, PI / 2],
        color: Color.carbon,
        material: Material.carbon,
      },
      // Body
      {
        type: "Box",
        dimension: [0.35, 0.3, 0.2],
        translation: [0, 0.6, 0],
        color: Color.white,
        material: Material.metal,
      },
      // Body bottom
      {
        type: "Box",
        dimension: [0.3, 0.3, 0.15],
        translation: [0, 0.35, 0],
        color: Color.carbon,
        material: Material.carbon,
      },
      // Arms
      {
        type: "Capsule",
        radius: 0.1,
        height: 1.3,
        translation: [0, 0.6, 0],
        rotation: [0, 0, Math.PI / 2],
        color: Color.carbon,
        material: Material.carbon,
      },
      // Left Leg top
      {
        type: "Capsule",
        radius: 0.1,
        height: 0.4,
        translation: [-0.15, 0.2, 0],
        rotation: [0, 0, 0],
        color: Color.carbon,
        material: Material.carbon,
      },
      // Right Leg top
      {
        type: "Capsule",
        radius: 0.1,
        height: 0.4,
        translation: [0.15, 0.2, 0],
        rotation: [0, 0, 0],
        color: Color.carbon,
        material: Material.carbon,
      },
      // Left Leg bottom
      {
        type: "Cylinder",
        radius: 0.1,
        height: 0.5,
        translation: [-0.15, -0.25, 0],
        rotation: [0, 0, 0],
        color: Color.white,
        material: Material.metal,
      },
      // Right Leg bottom
      {
        type: "Cylinder",
        radius: 0.1,
        height: 0.5,
        translation: [0.15, -0.25, 0],
        rotation: [0, 0, 0],
        color: Color.white,
        material: Material.metal,
      },
      // Left Eye
      {
        type: "Sphere",
        radius: 0.1,
        translation: [-0.23, 1.15, 0.35],
        color: Color.lightBlue,
        material: Material.plastic,
      },
      // Right Eye
      {
        type: "Sphere",
        radius: 0.1,
        translation: [0.23, 1.15, 0.35],
        color: Color.lightBlue,
        material: Material.plastic,
      },
    ],
  },
  cpu: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.45, 0],
        dimension: [0.8, 0.9, 0.8],
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
  },
  barrel: {
    root: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.5, 0],
        radius: 0.5,
        height: 2,
        color: Color.white,
        material: Material.metal,
      },
    ],
  },
  block1x1x1: {
    root: true,
    shapes: [
      {
        type: "Box",
        dimension: [1, 1, 1],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  block3x3x2: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.5, 0],
        dimension: [3, 2, 3],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  block5x5x3: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 1, 0],
        dimension: [5, 3, 5],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  block7x7x4: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 1.5, 0],
        dimension: [7, 4, 7],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  block9x9x5: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 2, 0],
        dimension: [9, 5, 9],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  brick1x1x1: {
    root: true,
    shapes: [
      {
        type: "Box",
        dimension: [1, 1, 1],
        color: Color.white,
        material: Material.metal,
      },
    ],
  },
  brick3x1x1: {
    root: true,
    shapes: [
      {
        type: "Box",
        dimension: [3, 1, 1],
        color: Color.white,
        material: Material.metal,
      },
    ],
  },
  brick5x1x1: {
    root: true,
    shapes: [
      {
        type: "Box",
        dimension: [5, 1, 1],
        color: Color.white,
        material: Material.metal,
      },
    ],
  },
  cone: {
    root: true,
    shapes: [
      { // base
        type: 'Box',
        translation: [0, -0.375, 0],
        dimension: [1, 0.25, 1],
        color: Color.white,
        material: Material.metal,
      },
      { // cone
        type: 'Cone',
        translation: [0, 0, 0],
        height: 0.9,
        bottomRadius: 0.45,
        topRadius: 0.01,
        color: Color.white,
        material: Material.metal,
      }
    ],
  },
  crystal: {
    nested: true,
    shapes: [
      {
        type: "Crystal",
        translation: [0, 0.5, 0],
        width: 0.4,
        height: 0.6,
        color: Color.yellow,
        material: Material.shiny,
      },
    ],
  },
  domino: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.9, 0],
        dimension: [0.2, 2.8, 0.95],
        color: Color.white,
        material: Material.metal,
      },
    ],
  },
  flag: {
    root: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, -0.4, 0],
        height: 0.2,
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: 'Box',
        translation: [0, 1.225, -0.25],
        dimension: [0.05, 2.5, 0.45],
        color: Color.white,
        material: Material.plastic,
      }
    ],
  },
  marble: {
    root: true,
    shapes: [
      {
        type: "Sphere",
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
    ],
  },
  ramp5x5x1: {
    root: true,
    shapes: [
      {
        type: "Triangle",
        translation: [2, -0.5, 0],
        width: 5,
        height: 1,
        depth: 5,
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  ramp5x5x3: {
    root: true,
    shapes: [
      {
        type: "Triangle",
        translation: [2, -0.5, 0],
        width: 5,
        height: 3,
        depth: 5,
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  ramp5x5x5: {
    root: true,
    shapes: [
      {
        type: "Triangle",
        translation: [2, -0.5, 0],
        width: 5,
        height: 5,
        depth: 5,
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  stele: {
    root: true,
    shapes: [
      { // base
        type: 'Box',
        translation: [0, -0.45, 0],
        dimension: [2.95, 0.1, 2.95],
        color: Color.carbon,
        material: Material.carbon,
      },
      { // top
        type: 'Box',
        translation: [0, 2.5, 0],
        dimension: [2, 5, 0.6],
        color: Color.carbon,
        material: Material.carbon,
      },
      { // crystal 1
        type: "Crystal",
        translation: [0, 4, 0.3],
        width: 0.4,
        height: 0.6,
        color: Color.yellow,
        material: Material.shiny,
      },
      { // crystal 2
        type: "Crystal",
        translation: [0, 4, -0.3],
        width: 0.4,
        height: 0.6,
        color: Color.yellow,
        material: Material.shiny,
      },
    ],
  },
  wall3x1x2: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.5, 0],
        dimension: [3, 2, 1],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  wall5x1x3: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 1, 0],
        dimension: [5, 3, 1],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  wall7x1x4: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 1.5, 0],
        dimension: [7, 4, 1],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  wall9x1x5: {
    root: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 2, 0],
        dimension: [9, 5, 1],
        color: Color.environment,
        material: Material.tile,
      },
    ],
  },
  cube: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.5, 0],
        // rotation: [0, 0, 0],
        dimension: [1, 1, 1],
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
      "+z": {
        translation: [0, 0.5, 0.5],
        rotation: [PI, -PI / 2, -PI / 2],
      },
      "-z": {
        translation: [0, 0.5, -0.5],
        rotation: [PI, PI / 2, -PI / 2],
      },
      "+x": {
        translation: [0.5, 0.5, 0],
        rotation: [PI, 0, -PI / 2],
      },
      "-x": {
        translation: [-0.5, 0.5, 0],
        rotation: [0, 0, PI / 2],
      },
    },
  },
  prism: {
    nested: true,
    shapes: [
      {
        type: "Prism",
        height: 0.866,
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      "+x": {
        translation: [0.25, 0.433, 0],
        rotation: [0, 0, -PI / 3],
      },
      "-x": {
        translation: [-0.25, 0.433, 0],
        rotation: [0, 0, PI / 3],
      },
    },
  },
  triangle: {
    nested: true,
    shapes: [
      {
        type: "Triangle",
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 0.5, 0],
        rotation: [0, 0, -PI / 4],
      },
      "-x": {
        translation: [-0.5, 0.5, 0],
        rotation: [0, 0, PI / 2],
      },
    },
  },
  connector: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.25, 0],
        height: 0.5,
        radius: 0.3,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.2, 0],
        height: 0.4,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
    ]
  },
  gearRack: {
    nested: true,
    shapes: [
      {
        type: 'Box',
        translation: [ 0, 0.09, -0.45 ],
        dimension: [ 0.95, 0.17, 0.11 ],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: 'Box',
        translation: [ 0, 0.09, -0.12 ],
        dimension: [ 0.95, 0.17, 0.11 ],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: 'Box',
        translation: [ 0, 0.09, 0.21 ],
        dimension: [ 0.95, 0.17, 0.11 ],
        color: Color.white,
        material: Material.metal,
      }
    ],
  },
  pin: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [ 0, 0.5, 0 ],
        height: 0.8,
        radius: 0.175,
        color: Color.red,
        material: Material.plastic,
      },
      {
        type: "Cylinder",
        translation: [ 0, 0.1, 0 ],
        height: 0.2,
        radius: 0.35,
        color: Color.red,
        material: Material.plastic,
      },
      {
        type: "Cylinder",
        translation: [ 0, 0.95, 0 ],
        height: 0.1,
        radius: 0.25,
        color: Color.red,
        material: Material.plastic,
      },
    ],
  },
  strip1: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ 0.5, 0.1, 0 ],
        dimension: [ 2, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      '+y1': {
        translation: [0, 0.2, 0]
      },
      '+y2': {
        translation: [1, 0.2, 0]
      },
      '-y': {
        translation: [1, 0, 0],
        rotation: [3.1415, 0, 0]
      }
    },
  },
  strip2: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ 1, 0.1, 0 ],
        dimension: [ 3, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      '+y1': {
        translation: [0, 0.2, 0]
      },
      '+y2': {
        translation: [2, 0.2, 0]
      },
      '-y': {
        translation: [2, 0, 0],
        rotation: [3.1415, 0, 0]
      }
    },
  },
  strip3: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ 1.5, 0.1, 0 ],
        dimension: [ 4, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      '+y1': {
        translation: [0, 0.2, 0]
      },
      '+y2': {
        translation: [3, 0.2, 0]
      },
      '-y': {
        translation: [3, 0, 0],
        rotation: [3.1415, 0, 0]
      }
    },
  },
  strip4: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ 2, 0.1, 0 ],
        dimension: [ 5, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      '+y1': {
        translation: [0, 0.2, 0]
      },
      '+y2': {
        translation: [4, 0.2, 0]
      },
      '-y': {
        translation: [4, 0, 0],
        rotation: [3.1415, 0, 0]
      }
    },
  },
  stripCross2: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ 0, 0.1, 0 ],
        dimension: [ 3, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      '+y1': {
        translation: [0, 0.2, 0]
      },
      '+y2': {
        translation: [1, 0.2, 0]
      },
      '-y2': {
        translation: [1, 0, 0],
        rotation: [3.1415, 0, 0]
      },
      '+y3': {
        translation: [-1, 0.2, 0]
      },
      '-y3': {
        translation: [-1, 0, 0],
        rotation: [3.1415, 0, 0]
      }
    },
  },
  stripCross3: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ -0.5, 0.1, 0 ],
        dimension: [ 2, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Box",
        translation: [
          0.25,
          0.11,
          0.433
        ],
        rotation: [0, -1.0472, 0],
        dimension: [ 2, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Box",
        translation: [
          0.25,
          0.11,
          -0.433
        ],
        rotation: [0, 1.0472, 0],
        dimension: [ 2, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      '+y1': {
        translation: [0, 0.2, 0]
      },
      '+y2': {
        translation: [-1, 0.2, 0]
      },
      '-y2': {
        translation: [-1, 0, 0],
        rotation: [3.1415, 0, 0]
      },
      '+y3': {
        translation: [0.5, 0.2, 0.866],
        rotation: [0, -1.0472, 0]
      },
      '-y3': {
        translation: [0.5, 0.0, 0.866],
        rotation: [3.1415, -1.0472, 0]
      },
      '+y4': {
        translation: [0.5, 0.2, -0.866],
        rotation: [0, 1.0472, 0]
      },
      '-y4': {
        translation: [0.5, 0.0, -0.866],
        rotation: [3.1415, 1.0472, 0]
      }
    },
  },
  stripCross4: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ 0, 0.1, 0 ],
        dimension: [ 3, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Box",
        translation: [ 0, 0.1, 0 ],
        dimension: [ 0.7, 0.2, 3],
        color: Color.white,
        material: Material.metal,
      }
    ],
    slots: {
      '+y1': {
        translation: [0, 0.2, 0]
      },
      '+y2': {
        translation: [1, 0.2, 0]
      },
      '-y2': {
        translation: [1, 0, 0],
        rotation: [3.1415, 0, 0]
      },
      '+y3': {
        translation: [-1, 0.2, 0]
      },
      '-y3': {
        translation: [-1, 0, 0],
        rotation: [3.1415, 0, 0]
      },
      '+y4': {
        translation: [0, 0.2, 1]
      },
      '-y4': {
        translation: [0, 0, 1],
        rotation: [3.1415, 0, 0]
      },
      '+y5': {
        translation: [0, 0.2, -1]
      },
      '-y5': {
        translation: [0, 0, -1],
        rotation: [3.1415, 0, 0]
      }
    },
  },
  stripL: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ 0, 0.1, 0 ],
        dimension: [ 0.7, 0.2, 0.7],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Box",
        translation: [0.4, 0.4, 0],
        dimension: [ 0.2, 0.9, 0.7],
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      '+x': {
        translation: [0.5, 0.5, 0],
        rotation: [3.1416, 0, -1.5701]
      },
      
    },
  },
  pipeBent2x2: {
    nested: true,
    shapes: [
      {
        type: "BentPipe",
        innerRadius: 0.35,
        arcRadius: 1.5,
        arcLength: PI / 4,
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      '+y': {
        translation: [0.4393, 1.0607, 0],
        rotation: [0, 0, -0.7854]
      }
    },
  },
  pipeBent3x3: {
    nested: true,
    shapes: [
      {
        type: "BentPipe",
        innerRadius: 0.35,
        arcRadius: 2.5,
        arcLength: PI / 4,
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      '+y': {
        translation: [ 0.7322330470336311, 1.7677669529663687, 0],
        rotation: [ 0, 0, -0.7854]
      }
    },
  },
  pipeBent4x4: {
    nested: true,
    shapes: [
      {
        type: "BentPipe",
        innerRadius: 0.35,
        arcRadius: 3.5,
        arcLength: PI / 4,
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      '+y': {
        translation: [ 1.0251262658470837, 2.474873734152916, 0 ],
        rotation: [ 0, 0, -0.7854 ]
      }
    },
  },
  pipeStraightFifth: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.1, 0],
        radius: 0.35,
        height: 0.2,
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      '+y': {
        translation: [0, 0.2, 0]
      }
    },
  },
  pipeStraightHalf: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.25, 0],
        radius: 0.35,
        height: 0.5,
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      '+y': {
        translation: [0, 0.5, 0]
      }
    },
  },
  pipeStraight1: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.5, 0],
        radius: 0.35,
        height: 1.0,
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      '+y': {
        translation: [0, 1.0, 0]
      }
    },
  },
  pipeT: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.25, 0],
        radius: 0.35,
        height: 0.5,
        color: Color.white,
        material: Material.plastic,
      },
      {
        type: 'Cylinder',
        translation: [0, 0.5, 0],
        rotation: [0, 0, 1.5708],
        radius: 0.35,
        height: 1,
        color: Color.white,
        material: Material.plastic,
      }
    ],
    slots: {
      '+x': {
        translation: [0.5, 0.5, 0],
        rotation: [3.1416, 0, -1.5708]
      },
      '-x': {
        translation: [-0.5, 0.5, 0],
        rotation: [0, 0, 1.5708]
      }
    },
  },
  pipeCross: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.5, 0],
        radius: 0.35,
        height: 1,
        color: Color.white,
        material: Material.plastic,
      },
      {
        type: 'Cylinder',
        translation: [0, 0.5, 0],
        rotation: [0, 0, 1.5708],
        radius: 0.35,
        height: 1,
        color: Color.white,
        material: Material.plastic,
      }
    ],
    slots: {
      '+y': {
        translation: [0, 1.0, 0]
      },
      '+x': {
        translation: [0.5, 0.5, 0],
        rotation: [3.1416, 0, -1.5708]
      },
      '-x': {
        translation: [-0.5, 0.5, 0],
        rotation: [0, 0, 1.5701]
      }
    },
  },
  pipeL: {
    nested: true,
    shapes: [
      {
        type: "BentPipe",
        innerRadius: 0.35,
        arcRadius: 0.5,
        arcLength: PI / 2,
        color: Color.white,
        material: Material.plastic,
      },
    ],
    slots: {
      '+x': {
        translation: [0.5, 0.5, 0],
        rotation: [3.1416, 0, -1.5708]
      }
    },
  },
  pipeTRotated: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.5, 0],
        radius: 0.35,
        height: 1.0,
        color: Color.white,
        material: Material.plastic,
      },
      {
        type: 'Cylinder',
        translation: [0.25, 0.5, 0],
        rotation: [0, 0, 1.5708],
        radius: 0.35,
        height: 0.5,
        color: Color.white,
        material: Material.plastic,
      }
    ],
    slots: {
      '+y': {
        translation: [0, 1.0, 0]
      },
      '+x': {
        translation: [0.5, 0.5, 0],
        rotation: [3.1416, 0, -1.5708]
      }
    },
  },
  conveyorBelt: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.1, 0],
        dimension: [1, 0.2, 1],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Box",
        translation: [0, 0.2, 0.15],
        dimension: [0.1, 0.1, 0.35],
        rotation: [0, -0.5, 0],
        color: Color.lightBlue,
        material: Material.plastic,
      },
      {
        type: "Box",
        translation: [0, 0.2, -0.15],
        dimension: [0.1, 0.1, 0.35],
        rotation: [0, 0.5, 0],
        color: Color.lightBlue,
        material: Material.plastic,
      }
    ],
  },
  decoupler: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.5, 0],
        height: 0.2,
        radius: 0.3,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.8, 0],
        height: 0.4,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0, 0.2, 0],
        height: 0.4,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
    },
  },
  piston: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.2,
        radius: 0.35,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.9, 0],
        height: 0.2,
        radius: 0.35,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.5, 0],
        height: 0.9,
        radius: 0.3,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
    },
  },
  pivot: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.75, 0],
        dimension: [1, 0.5, 0.6],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Box",
        translation: [0, 0.3, 0],
        dimension: [0.8, 0.6, 0.8],
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
      '+x': {
        translation: [0.5, 0.5, 0],
        rotation: [3.1416, 0, -1.5708]
      },
      '-x': {
        translation: [-0.5, 0.5, 0],
        rotation: [0, 0, 1.5701]
      }
    },
  },
  propeller: {
    nested: true,
    shapes: [
      {
        type: "Cone",
        bottomRadius: 0.4,
        topRadius: 0.1,
        height: 1,
        translation: [0, 0.5, 0],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Box",
        translation: [0, 0.9, 0],
        dimension: [0.2, 0.05, 0.75],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Box",
        translation: [0, 0.9, 0],
        dimension: [0.75, 0.05, 0.2],
        color: Color.white,
        material: Material.metal,
      }
    ],
  },
  roller: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.5, 0],
        dimension: [0.9, 1, 0.9],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0, 0.5, 0],
        rotation: [0, 0, PI / 2],
        height: 1,
        radius: 0.35,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
      '+x': {
        translation: [0.5, 0.5, 0],
        rotation: [3.1416, 0, -1.5708]
      },
      '-x': {
        translation: [-0.5, 0.5, 0],
        rotation: [0, 0, 1.5701]
      }
    },
  },
  twist: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.5, 0],
        height: 1,
        radius: 0.35,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.3, 0],
        height: 0.6,
        radius: 0.5,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
    },
  },
  button: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.4, 0],
        dimension: [0.9, 0.8, 0.9],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0, 0.9, 0],
        height: 0.2,
        radius: 0.4,
        color: Color.red,
        material: Material.plastic,
      },
    ],
  },
  imu: {
    nested: true,
    shapes: [
      {
        type: "Sphere",
        translation: [0, 0.6, 0],
        radius: 0.4,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.05, 0],
        height: 0.1,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
  },
  rangefinder: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.35, 0],
        dimension: [0.2, 0.5, 0.7],
        color: Color.white,
        material: Material.plastic,
      },
      {
        type: "Cylinder",
        translation: [0, 0.05, 0],
        height: 0.1,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0.1, 0.5, 0.175],
        height: 0.2,
        radius: 0.075,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0.1, 0.5, -0.175],
        height: 0.2,
        radius: 0.075,
        color: Color.white,
        material: Material.metal,
      },
    ],
  },
  scanner: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.25, 0],
        dimension: [0.8, 0.35, 0.7],
        color: Color.white,
        material: Material.plastic,
      },
      {
        type: "Cylinder",
        translation: [0, 0.05, 0],
        height: 0.1,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      }
    ],
  },
  bell: {
    nested: true,
    shapes: [
      {
        type: "Cone",
        translation: [0, 0.45, 0],
        bottomRadius: 0.1,
        topRadius: 0.4,
        height: 0.8,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Sphere",
        translation: [0, 0.95, 0],
        radius: 0.05,
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0, 0.05, 0],
        height: 0.1,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
  },
  ballast: {
    nested: true,
    shapes: [
      {
        type: 'Capsule',
        translation: [0, 0.5, 0],
        rotation: [0, 0, 1.5708],
        radius: 0.3,
        height: 1,
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: 'Box',
        translation: [0, 0.1, 0],
        dimension: [0.2, 0.2, 0.2],
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
  },
  buoy: {
    nested: true,
    shapes: [
      {
        type: 'Capsule',
        translation: [0, 0.5, 0],
        rotation: [0, 0, 1.5708],
        radius: 0.3,
        height: 1,
        color: Color.white,
        material: Material.plastic,
      },
      {
        type: 'Box',
        translation: [0, 0.1, 0],
        dimension: [0.2, 0.2, 0.2],
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
  },
  camera: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.3, 0],
        dimension: [0.5, 0.2, 0.2],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Box",
        translation: [0.4, 0.3, 0],
        dimension: [0.15, 0.15, 0.15],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0.15, 0.5, 0],
        rotation: [PI / 2, 0, 0],
        height: 0.2,
        radius: 0.15,
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [-0.15, 0.5, 0],
        rotation: [PI / 2, 0, 0],
        height: 0.2,
        radius: 0.15,
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0, 0.05, 0],
        height: 0.1,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
  },
  display: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.075, 0],
        dimension: [1, 0.15, 1],
        color: Color.white,
        material: Material.plastic,
      },
      {
        type: "Box",
        translation: [0, 0.1, 0],
        dimension: [0.9, 0.2, 0.9],
        color: Color.lightGreen,
        material: Material.plastic,
      },
    ],
  },
  funnel: {
    nested: true,
    shapes: [
      {
        type: "Cone",
        translation: [0, 0.5, 0],
        bottomRadius: 0.1,
        topRadius: 0.4,
        height: 1,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        radius: 0.4,
        height: 0.2,
        color: Color.carbon,
        material: Material.carbon,
      }
    ],
  },
  led: {
    nested: true,
    shapes: [
      {
        type: "Cone",
        translation: [0, 0.25, 0],
        bottomRadius: 0.31,
        topRadius: 0.5,
        height: 0.5,
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        radius: 0.4,
        height: 0.2,
        color: Color.white,
        material: Material.metal,
      }
    ],
  },
  speaker: {
    nested: true,
    shapes: [
      {
        type: "Cone",
        translation: [0, 0.225, 0],
        bottomRadius: 0.31,
        topRadius: 0.5,
        height: 0.45,
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [0, 0.45, 0],
        radius: 0.45,
        height: 0.1,
        color: Color.white,
        material: Material.plastic,
      },
    ],
  },
  thrower: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0.3, 0.6, 0],
        rotation: [0, 0, PI / 2],
        height: 0.6,
        radius: 0.45,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Sphere",
        translation: [0, 0.6, 0],
        radius: 0.45,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Box",
        translation: [0, 0.25, 0],
        dimension: [0.4, 0.5, 0.9],
        color: Color.carbon,
        material: Material.carbon,
      }
    ],
  },
  thruster: {
    nested: true,
    shapes: [
      {
        type: "Cone",
        translation: [0, 0.5, 0],
        height: 1,
        bottomRadius: 0.15,
        topRadius: 0.35,
        color: Color.white,
        material: Material.metal,
      }
    ],
  },
  ballAndSocket: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.2,
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.9, 0],
        height: 0.2,
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Sphere",
        translation: [0, 0.5, 0],
        radius: 0.25,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
    },
  },
  ballBearing: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.1, 0],
        radius: 0.35,
        height: 0.2,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      '+y': {
        translation: [0, 0.2, 0]
      }
    },
  },
  hinge: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.2,
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.9, 0],
        height: 0.2,
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.5, 0],
        rotation: [0, 0, PI / 2],
        height: 1,
        radius: 0.25,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
    },
  },
  spring: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.2,
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.9, 0],
        height: 0.2,
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.5, 0],
        height: 1,
        radius: 0.35,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
    },
  },
  trampoline: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [0, 0.9, 0],
        dimension: [1, 0.2, 1],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Box",
        translation: [0, 0.5, 0],
        rotation: [PI / 5, 0, 0],
        dimension: [1, 0.2, 1],
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Box",
        translation: [0, 0.5, 0],
        rotation: [-PI / 5, 0, 0],
        dimension: [1, 0.2, 1],
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        translation: [0, 1.0, 0],
      },
    },
  },
  wheelBike: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.12,
        radius: 1.5,
        color: Color.black,
        material: Material.rubber,
      },
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.15,
        radius: 1.3,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        name: "+y",
        translation: [0, 0.2, 0],
      },
    },
  },
  wheelCar: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.225, 0],
        height: 0.45,
        radius: 1,
        color: Color.black,
        material: Material.rubber,
      },
      {
        type: "Cylinder",
        translation: [0, 0.25, 0],
        height: 0.45,
        radius: 0.75,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        name: "+y",
        translation: [0, 0.5, 0],
      },
    },
  },
  wheelCaster: {
    nested: true,
    shapes: [
      {
        type: "Sphere",
        translation: [0, 0.2, 0],
        radius: 0.28,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        radius: 0.4,
        height: 0.2,
        color: Color.carbon,
        material: Material.carbon,
      }
    ],
  },
  wheelCogSmall: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.2,
        radius: 0.3,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        name: "+y",
        translation: [0, 0.2, 0],
      },
    },
  },
  wheelCogBig: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.2,
        radius: 0.5,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        name: "+y",
        translation: [0, 0.2, 0],
      },
    },
  },
  wheelLowFriction: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.5, 0],
        height: 1,
        radius: 1,
        color: Color.white,
        material: Material.plastic,
      }
    ],
    slots: {
      "+y": {
        name: "+y",
        translation: [0, 1, 0],
      },
    },
  },
  wheelTrain: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.05, 0],
        height: 0.1,
        radius: 1,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: 'Cylinder',
        translation: [0, 0.25, 0],
        height: 0.45,
        radius: 0.6,
        color: Color.white,
        material: Material.metal,
      }
    ],
    slots: {
      "+y": {
        name: "+y",
        translation: [0, 0.5, 0],
      },
    },
  },
  wheelTrainInverted: {
    nested: true,
    shapes: [
      {
        type: 'Cylinder',
        translation: [0, 0.45, 0],
        height: 0.1,
        radius: 1,
        color: Color.white,
        material: Material.metal,
      },
      {
        type: 'Cylinder',
        translation: [0, 0.25, 0],
        height: 0.45,
        radius: 0.6,
        color: Color.white,
        material: Material.metal,
      }
    ],
    slots: {
      "+y": {
        name: "+y",
        translation: [0, 0.5, 0],
      },
    },
  },
  wheelTruck: {
    nested: true,
    shapes: [
      {
        type: "Cylinder",
        translation: [0, 0.225, 0],
        height: 0.45,
        radius: 1.5,
        color: Color.black,
        material: Material.rubber,
      },
      {
        type: "Cylinder",
        translation: [0, 0.25, 0],
        height: 0.45,
        radius: 0.75,
        color: Color.white,
        material: Material.metal,
      },
    ],
    slots: {
      "+y": {
        name: "+y",
        translation: [0, 0.5, 0],
      },
    },
  },
  balloon: {
    nested: true,
    shapes: [
      {
        type: "Sphere",
        translation: [0, 0.5, 0],
        radius: 0.45,
        color: Color.white,
        material: Material.plastic,
      },
      {
        type: "Cylinder",
        translation: [0, 0.1, 0],
        height: 0.2,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
  },
  wing3: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [ 1, 0.1, 0 ],
        dimension: [ 3, 0.2, 0.7],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [ 2.5, 0.1, 0 ],
        height: 0.18,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
    slots: {
      '+y': {
        translation: [0, 0.2, 0]
      }
    },
  },
  wing5: {
    nested: true,
    shapes: [
      {
        type: "Box",
        translation: [2, 0.1, 0],
        dimension: [5, 0.2, 0.7],
        color: Color.carbon,
        material: Material.carbon,
      },
      {
        type: "Cylinder",
        translation: [ 4.5, 0.1, 0 ],
        height: 0.18,
        radius: 0.35,
        color: Color.carbon,
        material: Material.carbon,
      },
    ],
    slots: {
      '+y': {
        translation: [0, 0.2, 0]
      }
    },
  },
};
