import Storage from "./Storage";

const selectStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "#131313",
    height: 56,
    minHeight: 56,
    border: isFocused ? "#42bcb2 1px solid" : "#555 1px solid",
    boxShadow: isFocused ? "0 0 0 1px #42bcb2" : "none",
    "&:hover": {
      borderColor: isFocused ? "#42bcb2" : "#777",
    },
  }),
  menuList: (styles) => ({ ...styles, backgroundColor: "#1f1f1f" }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "#1f1f1f",
    borderRadius: "1em",
    border: "#555 1px solid",
  }),
  multiValueLabel: (styles) => ({ ...styles, color: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#62A8A2" : undefined,
      color: isSelected ? "#132726" : isFocused ? "#111E1D" : "white",
      ":active": {
        ...styles[":active"],
        backgroundColor: isSelected ? "#42bcb2" : undefined,
      },
    };
  },
};

class Tags {
  static getSuggestionsByType(type, t) {
    switch (type) {
      case Storage.TYPE.MACHINE:
        return Tags.machineTags.map((tag) => ({
          value: tag,
          label: t(tag, { ns: "tags" }),
        }));
      case Storage.TYPE.LEVEL:
        return Tags.levelTags.map((tag) => ({
          value: tag,
          label: t(tag, { ns: "tags" }),
        }));
      default:
        return [];
    }
  }

  static optionsToArray(opts) {
    return opts.map((option) => option.value);
  }

  static arrayToOptions(array, t) {
    if (array === undefined) return [];
    return array.map((tag) => ({
      value: tag,
      label: t(tag, { ns: "tags" }),
    }));
  }

  static getSelectStyles() {
    return selectStyles;
  }
}

Tags.machineTags = [
  // Movement modes
  "rolling", // Ground vehicles (cars, tanks, treads…)
  "flying", // Drones, airplanes, helicopters, airships…
  "aquatic", // Boats, submarines…
  "rocket", // Specific to rocket propulsion

  // Functions / roles
  "transportation",
  "manipulator", // Articulated arms, grappling hooks…

  // Technical characteristics
  "automation", // Programmed machine (reacts to sensors)
  "mechanism", // Focused on gears, articulations, etc. (formerly "mechanical")
  "artistic", // Emphasizes aesthetics or visual performance
];

Tags.levelTags = [
  "puzzle", // Logical puzzles, mechanisms
  "obstacleCourse", // Challenges based on movement and reflexes
  "parkour", // Jumping and traversal challenges (for the player character)
  "adventure", // Exploration with a small story element
  "exploration", // Large areas to freely roam
  "engineering", // Technical construction
  "creative", // Free sandbox
  "collection", // Collect objects/gems

  "timeTrial", // Races against the clock
  "challenge", // Races against the clock
  "tutorial", // Learn a concept or mechanic
  "story", // Narrative-driven experience
];

export default Tags;
