// generated by AI
// ! copy in cloud functions !
function slugify(text, id, minIdLength = 4, maxIdLength = 6) {
  // Normalize characters and build base slug
  let baseSlug = text
    .normalize("NFD") // Decompose accents into separate diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
    .replace(/[^a-zA-Z0-9\s-]/g, "") // Keep only alphanumeric characters, spaces, and hyphens
    .trim() // Remove leading and trailing whitespace
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .toLowerCase(); // Convert to lowercase

  // Ensure single hyphens and remove hyphens from start/end
  baseSlug = baseSlug.replace(/-+/g, "-").replace(/^[-]+|[-]+$/g, "");

  // Determine the ID length based on number of characters removed and slug length
  const numCharactersRemoved = text.length - baseSlug.length;
  let idLength = Math.min(
    Math.max(minIdLength, numCharactersRemoved),
    maxIdLength
  );

  // If baseSlug is empty or very short, use a longer trimmed ID
  if (baseSlug.length < minIdLength) {
    idLength = maxIdLength;
  }

  const trimmedId = id.slice(0, idLength);

  // Concatenate the base slug with the trimmed ID
  return baseSlug ? `${baseSlug}-${trimmedId}` : trimmedId;
}

export { slugify };
