import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AutomaticTranslation from "./AutomaticTranslation";
import Avatar from "../../misc/Avatar";
import Storage from "../../firebase/Storage";
import { useTranslation } from "react-i18next";
import PublicationStatus from "./PublicationStatus";

const DocumentSummary = ({
  type,
  slug,
  userSlug,
  userAvatarID,
  userDisplayName,
  name,
  published,
  translation,
  small = false,
}) => {
  const { t } = useTranslation("document_card");

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar
        profileLink={userSlug}
        avatarID={userAvatarID}
        displayName={userDisplayName}
        size={small ? 36 : 48}
      />
      <Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <AutomaticTranslation
            originalText={name}
            translation={translation?.name}
            to={`/${Storage.typeString(type, false, false)}/${slug}`}
            typographyProps={{
              variant: small ? "body" : "h5",
              fontWeight: "bold",
            }}
          />
          <PublicationStatus published={published}/>
        </Stack>
        <Typography
          variant={small ? "body2" : "h7"}
          component={Link}
          to={`/user/${userSlug}`}
          sx={{ textDecoration: "none", color: "inherit", userSelect: "none" }}
        >
          {t("by")}&nbsp;
          {userDisplayName}
        </Typography>
      </Stack>
    </Stack>
  );
};

DocumentSummary.propTypes = {
  type: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  userSlug: PropTypes.string.isRequired,
  userAvatarID: PropTypes.string.isRequired,
  userDisplayName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  translation: PropTypes.object,
  small: PropTypes.bool,
};

export default DocumentSummary;
