import { storage } from './config';
import { getDownloadURL, ref, uploadString } from "firebase/storage";

const TYPE = {
  MACHINE: 'machines',
  LEVEL: 'levels'
};

export default class Storage {
  static get TYPE() {
    return TYPE;
  }

  static typeString(type, startWithUppercase=false, plural=false) {
    if (type === TYPE.MACHINE)
      return (startWithUppercase ? 'M' : 'm') + 'achine' + (plural ? 's' : '');
    else
      return (startWithUppercase ? 'L' : 'l') + 'evel' + (plural ? 's' : '');
  }

  static typeToGETParameterName(type) {
    return type === TYPE.MACHINE ? 'startupMachine' : 'level';
  }

  storeScreenshot(id, imageData) {
    return new Promise((resolve, reject) => {
      const url = 'screenshots/' + id + '.jpg';
      const r = ref(storage, url);
      uploadString(r, imageData, 'data_url')
        .then((snapshot) => {
          // console.log('Uploaded a blob or file!', snapshot);
          resolve();
        })
        .catch(error => {reject(error)});
    });
  }

  // getScreenshotURL(id) {
  //   storage.ref('screenshots/' + id + '.jpg')
  //   return '';
  // }

  storeData(id, type, data) {
    return new Promise((resolve, reject) => {
      const url = type + '/' + id + '.json';
      const r = ref(storage, url);
      const str = JSON.stringify(data, null, 2);

      uploadString(r, str)
        .then((snapshot) => {
          // console.log('Uploaded a blob or file!', snapshot);
          resolve();
        })
        .catch(error => {reject(error)});
    });
  }

  getData(type, id) {
    return new Promise((resolve, reject) => {
      const url = type + '/' + id + '.json';

      getDownloadURL(ref(storage, url))
        .then((url) => {
          fetch(url)
            .then(res => res.json())
            .then(out => {
              resolve(out);
            })
            .catch(error => {reject(error)});
        })
        .catch((error) => {reject(error)});
    })
  }
}
