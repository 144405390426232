import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

const MiniMarkdownRenderer = ({ translation, children }) => {
  const { i18n } = useTranslation();
  const targetLanguage = i18n.language;

  // Sélectionner le bon texte (traduction ou original)
  const textToRender = translation?.[targetLanguage] || children;

  const [sanitizedText, setSanitizedText] = useState("");

  useEffect(() => {
    if (!textToRender) return;

    let formattedText = textToRender
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/\*(.*?)\*/g, "<em>$1</em>")
      .replace(/^# (.*$)/gm, '<h1 style="font-size: 2rem; font-weight: bold;">$1</h1>')
      .replace(/^## (.*$)/gm, '<h2 style="font-size: 1.5rem; font-weight: bold;">$1</h2>')
      .replace(/^###+ (.*$)/gm, '<h3 style="font-size: 1.2rem; font-weight: bold;">$1</h3>')
      .replace(/^\s*[-*] (.*)$/gm, "<li>$1</li>")
      .replace(/(<li>.*<\/li>)/gms, "<ul style='padding-left: 1.5rem;'>$1</ul>")
      .replace(/`(.*?)`/g, "<code>$1</code>")
      .replace(/\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer" style="color: blue;">$1</a>')
      .replace(/(?<!<\/?(h1|h2|h3|ul|li)>)\n/g, "<br />");

    setSanitizedText(DOMPurify.sanitize(formattedText));
  }, [textToRender]);

  return <span dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
};

MiniMarkdownRenderer.propTypes = {
  translation: PropTypes.object, // Format: { fr: "texte", en: "text" }
  children: PropTypes.string.isRequired,
};

export default MiniMarkdownRenderer;
